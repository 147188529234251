import { FC } from "react"
import { useController, UseFormReturn } from "react-hook-form"

import { ItemAnswerOption } from "type-alias"
import { Checkbox } from "commons"

const UPPERCASE_A_CHAR_CODE = 65

const CheckboxGroup: FC<Props> = ({ name, defaultValue, options, control, getOptionLabel }) => {
  const {
    field: { ref, value, onBlur, onChange },
  } = useController({ name, control, defaultValue })

  return (
    <div ref={ref} onBlur={onBlur} className="flex flex-col gap-y-2">
      {options.map((option, index) => {
        const label = getOptionLabel(option)
        const symbol = String.fromCharCode(UPPERCASE_A_CHAR_CODE + index)

        return (
          <Checkbox
            key={index}
            name={name}
            label={label}
            optionSymbol={symbol}
            handleChange={(event) =>
              onChange(
                event.target.checked
                  ? [...(value ?? []), option]
                  : value.filter((item: ItemAnswerOption) => !isOptionsEquals(item, option)),
              )
            }
          />
        )
      })}
    </div>
  )
}

const isOptionsEquals = (option1: ItemAnswerOption | undefined, option2: ItemAnswerOption | undefined) =>
  option1 && option2 && JSON.stringify(option1) === JSON.stringify(option2)

type Props = {
  name: string
  options: ItemAnswerOption[]
  defaultValue?: ItemAnswerOption[]
  getOptionLabel(option: ItemAnswerOption): string
} & Pick<UseFormReturn, "control">

export { CheckboxGroup }
