import { ItemAnswerOption, ResponseItem } from "type-alias"

import { FormItem } from "./types"

const CHOICE_OPTIONS_LENGTH = 7

const getFormItemName = (
  path: string,
  type: string,
  repeats?: boolean,
  valueSet?: string,
  options?: ItemAnswerOption[],
) => {
  if (type === "group" || type === "display") {
    return path
  }

  if (type === "choice") {
    return (options?.length ?? 0) >= CHOICE_OPTIONS_LENGTH || repeats || options?.[0]?.value?.Coding || valueSet
      ? `${path}.answer${!repeats ? ".0" : ""}`
      : `${path}.answer.0.value`
  }

  if (type === "string" || type === "text") {
    return `${path}.answer.0.value.string`
  }

  if (type === "url") {
    return `${path}.answer.0.value.uri`
  }

  if (type === "boolean") {
    return `${path}.answer.0.value`
  }

  if (type === "quantity") {
    return `${path}.answer.0.value.Quantity`
  }

  return `${path}.answer.0.value.${type}`
}

const formToResponse = (submitData: FormItem[], formItem: FormItem[]): ResponseItem[] => {
  return submitData.reduce<ResponseItem[]>((acc, { answer, item: submitItem, repeatingItems }, index) => {
    const hasAnswer =
      submitItem?.length ||
      answer?.some(
        ({ value } = {}) =>
          value?.boolean !== undefined ||
          !!(
            value?.Coding?.code ??
            value?.Quantity?.value ??
            value?.date ??
            value?.dateTime ??
            value?.time ??
            value?.decimal ??
            value?.integer ??
            value?.uri ??
            value?.string
          ),
      )

    const { linkId, text, item, repeatingItems: formRepeats } = formItem[index] ?? {}

    if (!hasAnswer || !linkId) {
      return acc
    }

    const repeats =
      repeatingItems?.map(({ item }, index) => ({
        linkId,
        text,
        answer,
        item: formToResponse(item ?? [], formRepeats?.[index]?.item ?? []),
      })) ?? []

    return [
      ...acc,
      {
        linkId,
        text,
        answer,
        item: submitItem?.length ? formToResponse(submitItem ?? [], item ?? []) : undefined,
      },
      ...repeats,
    ]
  }, [])
}

export { getFormItemName, formToResponse, CHOICE_OPTIONS_LENGTH }
