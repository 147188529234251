import { FC } from "react"
import { useController, UseFormReturn } from "react-hook-form"

import { ItemAnswerOption, ResponseItemAnswer } from "type-alias"
import { Radio } from "commons"

const UPPERCASE_A_CHAR_CODE = 65

const RadioGroup: FC<Props> = ({ name, options, defaultValue, control, getOptionLabel }) => {
  const {
    field: { ref, name: fieldName, value: selectedOption, onBlur, onChange },
  } = useController({ name, control, defaultValue })

  return (
    <div ref={ref} onBlur={onBlur} className="flex flex-col gap-y-2">
      {options.map((option, index) => {
        const label = getOptionLabel(option)
        const symbol = String.fromCharCode(UPPERCASE_A_CHAR_CODE + index)

        return (
          <Radio
            key={index}
            name={fieldName}
            checked={!!isOptionsEquals(selectedOption, option)}
            optionSymbol={symbol}
            label={label}
            handleChange={(event) => {
              if (event.target.checked) {
                onChange(option)
              }
            }}
          />
        )
      })}
    </div>
  )
}

const isOptionsEquals = (option1: ResponseItemAnswer | undefined, option2: ResponseItemAnswer | undefined) =>
  option1 && option2 && JSON.stringify(option1) === JSON.stringify(option2)

type Props = {
  name: string
  options: ItemAnswerOption[]
  defaultValue?: ItemAnswerOption
  getOptionLabel(option: ItemAnswerOption): string
} & Pick<UseFormReturn, "control">

export { RadioGroup }
