import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLink } from "@fortawesome/pro-light-svg-icons"
import { FallbackProps } from "react-error-boundary"
import { FC } from "react"
import { Button } from "primereact/button"

const NotFoundErrorView: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const message = error.cause?.message ?? "Not found"

  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="text-center">
        <p className="text-blue-600">404 Error</p>
        <h1 className="text-4xl py-2 font-bold">Resource not found</h1>
        <p className="text-sm text-gray-400 pt-2 pb-4">{message}</p>
        <Button onClick={resetErrorBoundary} className="text-sm text-blue-600 hover:text-blue-800">
          <span>
            Go to Home <FontAwesomeIcon icon={faExternalLink} className="pl-1" />
          </span>
        </Button>
      </div>
    </div>
  )
}

export { NotFoundErrorView }
