import { FC } from "react"

import { Label } from "commons"

const BooleanQuestionDefinition: FC<Props> = ({ text, required }) => (
  <div className="mb-5">
    <Label readonly={true} required={!!required}>
      {text}
    </Label>
    <div className="flex flex-col">
      <span className="text-lg">
        <span className="font-semibold">1 - </span> Yes
      </span>
      <span className="text-lg">
        <span className="font-semibold">2 - </span> No
      </span>
    </div>
  </div>
)

type Props = {
  text?: string
  required?: boolean
}

export { BooleanQuestionDefinition }
