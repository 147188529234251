import { FC } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"

const Message: FC<Props> = ({ children }) => (
  <p className="text-gray-500 text-xl">
    <FontAwesomeIcon icon={faInfoCircle} className="text-sky-400" /> {children}
  </p>
)

type Props = {
  children: React.ReactNode
}

export { Message }
