import { FC } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckDouble } from "@fortawesome/pro-duotone-svg-icons"

const FinishResponse: FC = () => {
  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="w-4/5  md:w-3/5 flex flex-col items-center">
        <div className="text-green-500 font-bold mb-8">
          <FontAwesomeIcon icon={faCheckDouble} size="4x" />
        </div>
        <p className="font-light">Thank you for completing your questionnaire. Your answers have been submitted.</p>
      </div>
    </div>
  )
}

export { FinishResponse }
