import { FC } from "react"
import { InputNumber, InputNumberChangeParams } from "primereact/inputnumber"
import { useController, useFormContext } from "react-hook-form"
import { Quantity } from "fhir"

import { QuestionAnswer } from "./QuestionAnswer"

const QuantityQuestion: FC<Props> = ({ name, unit, defaultValue, readOnly }) => {
  const { control } = useFormContext()
  const {
    field: { value, onChange },
  } = useController({ name, control, defaultValue })

  const handleChange = (e: InputNumberChangeParams) => {
    onChange({ value: e.value, unit })
  }

  if (readOnly) {
    const answerValue = defaultValue?.value
    const answerUnit = defaultValue?.unit

    return <QuestionAnswer answer={`${answerValue} ${answerUnit}`} />
  }

  return (
    <InputNumber
      mode="decimal"
      minFractionDigits={2}
      value={value}
      onChange={handleChange}
      suffix={unit ? ` ${unit}` : ""}
    />
  )
}

type Props = {
  name: string
  unit?: string
  defaultValue?: Quantity
  readOnly?: boolean
}

export { QuantityQuestion }
