import { FC } from "react"

import { Label, ValueSet } from "commons"
import { ItemAnswerOption } from "type-alias"

const ChoiceQuestionDefinition: FC<Props> = ({ text, required, valueSet, valueOptions }) => {
  const renderOptions = (options: ItemAnswerOption[]) => (
    <div className="mb-5">
      <Label readonly={true} required={!!required}>
        {text}
      </Label>

      {options.map((option, index) => (
        <div className="" key={index}>
          <span className="text-lg">
            <span className="font-semibold">{index} - </span> {getLabel(option)}
          </span>
        </div>
      ))}
    </div>
  )

  return valueSet ? <ValueSet url={valueSet} renderOptions={renderOptions} /> : renderOptions(valueOptions ?? [])
}

const getLabel = (option: ItemAnswerOption) => {
  const { value } = option ?? {}
  const { Coding, string, integer } = value ?? {}

  if (Coding) {
    return Coding.display ?? (Coding.code as string)
  }

  if (string) {
    return string
  }

  if (integer !== undefined) {
    return integer.toString()
  }

  return "not supported"
}

type Props = {
  text?: string
  required?: boolean
  valueSet?: string
  valueOptions?: ItemAnswerOption[]
}
export { ChoiceQuestionDefinition }
