import { createContext, FC, useCallback, useContext, useState } from "react"

const ConditionalStateContext = createContext<ConditionalState>(undefined)
const ConditionalCallbackContext = createContext<ConditionalCallback>(undefined)

const ConditionalProvider: FC<Props> = ({ children }) => {
  const [state, setState] = useState<ConditionalState>({})

  const setStateCallback = useCallback(
    (name: string, enable: boolean) => setState((prev) => ({ ...prev, [name]: enable })),
    [],
  )

  return (
    <ConditionalStateContext.Provider value={state}>
      <ConditionalCallbackContext.Provider value={setStateCallback}>{children}</ConditionalCallbackContext.Provider>
    </ConditionalStateContext.Provider>
  )
}

type ConditionalState = Record<string, boolean> | undefined

type ConditionalCallback = ((name: string, enable: boolean) => void) | undefined

const useConditionalState = () => {
  const state = useContext(ConditionalStateContext)

  if (state === undefined) {
    throw new Error("useConditionalState must be used within a ConditionalProvider")
  }

  return state
}

const useConditionalCallback = () => {
  const callback = useContext(ConditionalCallbackContext)

  if (callback === undefined) {
    throw new Error("useConditionalCallback must be used within a ConditionalProvider")
  }

  return callback
}

type Props = {
  children: React.ReactNode
}

export { ConditionalProvider, useConditionalState, useConditionalCallback }
