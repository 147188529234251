import { Patient, Practitioner } from "fhir"
import { createContext, FC, ReactNode, useContext } from "react"

import { useLinkedUserResource } from "../hooks"

const LoginContext = createContext<State | undefined>(undefined)
LoginContext.displayName = "LoginContext"

const LoginProvider: FC<Props> = ({ children }) => {
  const { userLinkedResource } = useLinkedUserResource()

  if (!userLinkedResource?.id) {
    throw new Error("Not found", { cause: { name: "404", message: "Practitioner or Patient not found" } })
  }

  return (
    <LoginContext.Provider
      value={{
        userLinkedResource,
        userLinkedResourceId: userLinkedResource.id,
      }}
    >
      {children}
    </LoginContext.Provider>
  )
}

const useLoginContext = () => {
  const context = useContext(LoginContext)

  if (context === undefined) {
    throw new Error("useLoginContext must be used within a LoginProvider")
  }

  return context
}

type State = {
  userLinkedResource: Practitioner | Patient
  userLinkedResourceId: string
}

type Props = {
  children: ReactNode
}

export { LoginProvider, useLoginContext }
