import { useMutation } from "react-query"
import { useNavigate } from "react-router"
import { format } from "date-fns"

import { QuestionnaireResponse } from "fhir"
import { useClient } from "api"
import { apm } from "logger"

import { FormData, FormItem } from "../types"
import { useConditionalState } from "../components/ConditionalProvider"
import { ResponseItem } from "type-alias"

const useResponseSubmit = (response: QuestionnaireResponse | undefined, formData: FormItem[], versionId?: string) => {
  const navigate = useNavigate()
  const { update } = useClient()

  const {
    mutate: submit,
    isLoading,
    isError,
    error,
  } = useMutation(
    (submitData: FormData) => {
      const item = formToResponse(submitData.item ?? [], formData)

      if (!response?.id) {
        apm.captureError(new Error("Invalid response"))

        throw new Error("Invalid response")
      }

      return update("QuestionnaireResponse", response.id, {
        ...response,
        meta: { versionId: versionId },
        item,
        status: "completed",
        authored: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      })
    },
    {
      onSuccess: () => {
        navigate("/finish")
      },
      onError: (error: Error, context) => {
        apm.setCustomContext(context)
        apm.captureError(error)
      },
    },
  )

  return {
    isError,
    error,
    isSubmittingResponse: isLoading,
    submit: async (data: FormData) => {
      await submit(data)
    },
  }
}

const useResponseUpdate = (formData: FormItem[], id?: string, versionId?: string, setEtag?: (etag: string) => void) => {
  const conditionalMap = useConditionalState()
  const { patch } = useClient()

  const {
    mutate: submit,
    error,
    isError,
    isLoading,
  } = useMutation(
    (submitData: { override: boolean } & Partial<FormData>) => {
      const item = formToResponse(submitData.item ?? [], formData, conditionalMap)

      if (!id) {
        apm.captureError(new Error("Invalid response id"))

        throw new Error("Invalid response id")
      }

      return patch<Partial<QuestionnaireResponse>>(
        "QuestionnaireResponse",
        id,
        { item },
        submitData.override ? undefined : versionId,
      )
    },
    {
      onSuccess: (response) => {
        if (response?.meta?.versionId) {
          setEtag?.(response.meta.versionId)
        }
      },
      onError: (error: Error, context) => {
        apm.setCustomContext(context)
        apm.captureError(error)
      },
    },
  )

  return { isLoading, isError, error, submit }
}

const formToResponse = (
  submitData: FormItem[],
  formItem: FormItem[],
  conditionalMap?: Record<string, boolean>,
  path = "item",
  isRepeatItem = false,
): ResponseItem[] =>
  submitData.reduce<ResponseItem[]>((acc, currentItem, index) => {
    if (!currentItem) {
      return acc
    }
    const { answer, item: submitItem, repeatingItems } = currentItem
    const namePath = `${path}.${index}`

    if (conditionalMap?.[namePath] === false && !isRepeatItem) {
      return acc
    }

    const hasAnswer =
      submitItem?.length ||
      answer?.some(
        ({ value } = {}) =>
          value?.boolean !== undefined ||
          !!(
            value?.Coding?.code ??
            value?.Quantity?.value ??
            value?.date ??
            value?.dateTime ??
            value?.time ??
            value?.decimal ??
            value?.integer ??
            value?.uri ??
            value?.string
          ),
      )

    const { linkId, text, item, repeatingItems: formRepeats } = formItem[index] ?? {}

    if (!hasAnswer || !linkId) {
      return acc
    }

    const repeats =
      repeatingItems
        ?.filter((_, repeatIndex) => conditionalMap?.[`${namePath}.repeatingItems.${repeatIndex}`] !== false)
        ?.map(({ item }, index) => ({
          linkId,
          text,
          answer,
          item: formToResponse(
            item ?? [],
            formRepeats?.[index]?.item ?? [],
            conditionalMap,
            `${namePath}.repeatingItems`,
            true,
          ),
        })) ?? []

    return [
      ...acc,
      {
        linkId,
        text,
        answer,
        item: submitItem?.length
          ? formToResponse(submitItem ?? [], item ?? [], conditionalMap, `${namePath}.item`)
          : undefined,
      },
      ...repeats,
    ]
  }, [])

export { useResponseSubmit, useResponseUpdate }
