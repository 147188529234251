import { Bundle, isOperationOutcome, ResourceObject } from "fhir"

import { User } from "security"

class Client {
  private logout: (isSessionExpired?: boolean) => void
  private xAudit: string
  private token: string

  public apiUrl: string

  constructor(logout: (isSessionExpired?: boolean) => void, user?: User, apiUrl?: string) {
    this.apiUrl = apiUrl ? apiUrl : window.REACT_APP_FHIR_SERVER
    this.logout = logout

    this.token = user?.token ?? ""

    this.xAudit = btoa(
      JSON.stringify({
        email: user?.email ?? "unspecified user email",
        name: user?.name ?? "unspecified user name",
        resource: user?.linkedResource,
        client: "survey",
      }),
    )
  }

  private request = async (
    endpoint: string,
    { method, body, headers: customHeaders, ...customConfig }: RequestInit,
  ) => {
    const config = {
      method,
      body,
      headers: {
        Authorization: this.token,
        ...(body ? { "Content-Type": "application/json" } : {}),
        "Cache-Control": "max-age=0, no-cache, must-revalidate, proxy-revalidate",
        "x-audit": this.xAudit,
        ...customHeaders,
      },
      ...customConfig,
    }

    const response = await fetch(`${this.apiUrl}/${endpoint}`, config)

    const data = await response.json().catch(() => {})

    if (response.ok) {
      return data
    }

    switch (response.status) {
      case 401:
        if (data?.message?.includes("JWT is expired")) {
          this.logout(true)
        }

        throw new Error("Unauthorized", { cause: { name: "401", message: "Unauthorized" } })
      case 403:
        throw new Error("Forbidden", { cause: { name: "403", message: "Forbidden" } })
      default:
        if (isOperationOutcome(data)) {
          const message =
            data.text?.div ?? data.issue?.[0].details?.text ?? data.issue[0].diagnostics ?? "Something went wrong"
          throw new Error("Internal server error", { cause: { name: "500", message } })
        }

        throw new Error("Not supported", {
          cause: { name: "not-supported", message: `Invalid response: ${response.statusText} ${response.status}` },
        })
    }
  }

  read = async <T extends ResourceObject>(
    endpoint: string,
    id?: string,
    filters?: URLSearchParams,
    operation?: string,
    signal?: AbortSignal,
  ) => {
    const data = await this.request(
      `${endpoint}${id ? `/${id}` : ""}${operation ? `/$${operation}` : ""}${filters ? `?${filters}` : ""}`,
      {
        method: "GET",
        signal,
      },
    )

    return data as T
  }

  search = async (endpoint: string, filters?: URLSearchParams, operation?: string, signal?: AbortSignal) => {
    const data = await this.request(`${endpoint}${operation ? `/$${operation}` : ""}${filters ? `?${filters}` : ""}`, {
      method: "GET",
      signal,
    })

    return data as Bundle
  }

  update = async <T extends ResourceObject>(endpoint: string, id: string, resource: T) => {
    const data = await this.request(`${endpoint}/${id}`, {
      method: "PUT",
      body: JSON.stringify(resource),
    })

    return data as T
  }

  patch = async <T extends ResourceObject>(endpoint: string, id: string, resource: Partial<T>, etag?: string) => {
    const data = await this.request(`${endpoint}/${id}`, {
      method: "PATCH",
      body: JSON.stringify(resource),
      ...(etag
        ? {
            headers: {
              ...(etag ? { "If-Match": etag } : {}),
            },
          }
        : {}),
    })

    return data as T
  }
}

export { Client }
