import {
  Patient,
  Questionnaire,
  QuestionnaireItemArrayAnswerOptionArray,
  QuestionnaireItemArrayInitialArray,
  QuestionnaireResponse,
  QuestionnaireResponseItemArrayAnswerArray,
  ResourceObject,
} from "fhir"

import { ResponseItemAnswer } from "type-alias"

export type FormData = {
  item: FormItem[]
  answers: number
  total: number
}

export type ConditionalItem = {
  name: string[]
  initialValue?: WatchMap
  applyBehavior(valueMap: WatchMap): boolean
}

export type WatchMap = Record<string, ResponseItemAnswer | ResponseItemAnswer[] | undefined>

export type RepeatItem = Omit<FormItem, "repeats" | "repeatingItems">

export type FormItem = {
  linkId: string
  type: string
  required?: boolean
  repeats?: boolean
  text?: string
  initial?: QuestionnaireItemArrayInitialArray[]
  answer?: QuestionnaireResponseItemArrayAnswerArray[]
  defaultValue?: QuestionnaireResponseItemArrayAnswerArray[]
  conditional?: ConditionalItem
  options?: QuestionnaireItemArrayAnswerOptionArray[]
  valueSet?: string
  item?: FormItem[]
  repeatingItems?: RepeatItem[]
}

export type ResponseWithQuestionnaire = {
  questionnaire: Questionnaire | undefined
  response: QuestionnaireResponse | undefined
}

export function isResourceObject(resource: unknown): resource is ResourceObject {
  return typeof resource === "object" && "resourceType" in (resource ?? {})
}

export function isQuestionnaire(resource: unknown): resource is Questionnaire {
  return isResourceObject(resource) && resource.resourceType === "Questionnaire"
}

export function isQuestionnaireResponse(resource: unknown): resource is QuestionnaireResponse {
  return isResourceObject(resource) && resource.resourceType === "QuestionnaireResponse"
}

export function isPatient(resource: unknown): resource is Patient {
  return isResourceObject(resource) && resource.resourceType === "Patient"
}
