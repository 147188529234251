import "./styles/index.css"
import "./tailwind.css"

import { AppRoutes } from "routes"

function App() {
  return <AppRoutes />
}

export default App
