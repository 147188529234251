import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSignOut } from "@fortawesome/pro-light-svg-icons"
import { FC } from "react"
import { Button } from "primereact/button"

import { CustomError } from "../types"

const AuthError: FC<Props> = ({ error, logout }) => {
  const message = error.cause?.message ?? "Internal server error"
  const code = error.name ?? "500 error"

  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="text-center">
        <p className="text-secondary">{code}</p>
        <h1 className="text-4xl py-2 font-bold">{error.message}</h1>
        <p className="text-sm text-gray-400 pt-2 pb-4">{message}</p>
        <Button onClick={logout} className="text-sm text-primary hover:text-primary-hover">
          <span className="flex items-center">
            <FontAwesomeIcon icon={faSignOut} className="mr-2" /> Logout
          </span>
        </Button>
      </div>
    </div>
  )
}

type Props = { error: CustomError; logout(): void }

export { AuthError }
