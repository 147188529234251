import { Label } from "commons"
import { FC } from "react"
import { InputText } from "primereact/inputtext"

import { QuestionnaireItem } from "type-alias"

import { BooleanQuestionDefinition } from "./BooleanQuestionDefinition"
import { ChoiceQuestionDefinition } from "./ChoiceQuestionDefinition"

const QuestionDefinition: FC<Props> = ({ item }) => {
  const renderQuestion = () => {
    if (item.item?.length) {
      return (
        <div className="mb-8">
          {item.type === "group" ? (
            <div className="mb-5">
              <span className="text-xl font-semibold">{item.text ?? "Unknown Group"}</span>
              <hr className="mt-3" />
            </div>
          ) : (
            <QuestionDefinition item={item} />
          )}

          <div className="pl-6">
            {item.item?.map((question, index) => (
              <QuestionDefinition key={question.linkId ?? index} item={question} />
            ))}
          </div>
        </div>
      )
    }

    const { type, text, required, answerValueSet, answerOption } = item

    switch (type) {
      case "string":
      case "integer":
      case "decimal":
      case "url":
      case "text":
      case "quantity":
      case "date":
      case "dateTime":
      case "time":
        return (
          <div className="flex flex-col mb-5">
            <Label className="mb-5" readonly={true} required={!!required}>
              {text}
            </Label>
            <InputText className="bg-gray-300" disabled />
          </div>
        )
      case "choice":
        return (
          <ChoiceQuestionDefinition
            text={text}
            required={required}
            valueSet={answerValueSet}
            valueOptions={answerOption}
          />
        )
      case "boolean":
        return <BooleanQuestionDefinition text={text} required={required} />
      case "display":
        return <div className="">{text}</div>

      default:
        return <p className="has-text-danger has-text-weight-bold">Question type not supported: {type}</p>
    }
  }

  return renderQuestion()
}

type Props = {
  item: QuestionnaireItem
}

export { QuestionDefinition }
