import { useConditionalState } from "response/components/ConditionalProvider"
import { FormItem } from "response/types"

const useQuestionsCount = (formItems: FormItem[]) => {
  const conditionalMap = useConditionalState()

  if (formItems === undefined) {
    return { questions: undefined, answers: undefined }
  }

  const getItemsCount = (items: FormItem[], path = "item", questions = 0, answers = 0): Count =>
    items?.reduce(
      (acc, current, index) => {
        if (!current) {
          return acc
        }
        const { item, repeatingItems, answer } = current
        const namePath = `${path}.${index}`

        if (conditionalMap[namePath] === false) {
          return acc
        }

        const { value } = answer?.[0] ?? {}

        const { Coding, Quantity, boolean, date, dateTime, decimal, integer, string, time, uri } = value ?? {}

        const hasAnswer = !!(
          Coding?.code ??
          Quantity?.value ??
          date ??
          dateTime ??
          decimal ??
          integer ??
          string ??
          time ??
          uri ??
          boolean !== undefined
        )

        const questionSum = answer !== undefined ? acc.questions + 1 : acc.questions
        const answersSum = hasAnswer ? acc.answers + 1 : acc.answers

        if (item) {
          const { questions: itemQuestions, answers: itemAnswers } = getItemsCount(
            item,
            `${namePath}.item`,
            questionSum,
            answersSum,
          )

          if (repeatingItems) {
            return getItemsCount(repeatingItems, `${namePath}.repeatingItems`, itemQuestions, itemAnswers)
          }

          return { questions: itemQuestions, answers: itemAnswers }
        }

        return { questions: questionSum, answers: answersSum }
      },
      { questions, answers },
    ) ?? {}

  return getItemsCount(formItems)
}

type Count = {
  questions: number
  answers: number
}

export { useQuestionsCount }
