import { FC } from "react"

import { LoadingView } from "commons"

import { QuestionDefinition } from "./QuestionDefinition"
import { useQuestionnaire } from "../hooks/useQuestionnaire"

const QuestionnaireDefinition: FC = () => {
  const { data, isLoading } = useQuestionnaire()

  if (isLoading) {
    return <LoadingView />
  }

  if (!data) {
    return <div>Error retrieving questionnaire: no data</div>
  }

  const { title, description, item } = data

  return (
    <div className="p-20">
      <h1 className="text-4xl font-bold mb-4">{title}</h1>
      <p>{description}</p>
      <div className="mt-8">
        {item?.map((question, index) => (
          <QuestionDefinition key={question.linkId ?? index} item={question} />
        ))}
      </div>
    </div>
  )
}

export { QuestionnaireDefinition }
