import { FC } from "react"

const QuestionAnswer: FC<Props> = ({ answer }) => {
  if (!answer) {
    return <div className="font-semibold text-lg italic text-gray-500">Unanswered question</div>
  }

  return <div className="font-bold text-lg">{answer}</div>
}

type Props = { answer?: string | number }

export { QuestionAnswer }
