import { faSignOut } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "primereact/button"

import { useAuth } from "security"

const UnauthorizedErrorView = () => {
  const { user, logout } = useAuth()
  const name = user?.name ?? "unspecified name"
  const email = user?.email ?? "unspecified email"

  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="text-center">
        <p className="text-blue-600">401 Error</p>
        <h1 className="text-4xl py-2 font-bold">Unauthorized</h1>
        <p className="text-sm text-gray-400 py-2 pb-4">
          Sorry, logged in user <span className="font-bold">{name}</span> with email{" "}
          <span className="font-bold">{email}</span> has not access
        </p>
        <Button onClick={logout} className="text-sm text-blue-600 hover:text-blue-800">
          <span className="flex items-center">
            <FontAwesomeIcon icon={faSignOut} className="mr-2" /> Logout
          </span>
        </Button>
      </div>
    </div>
  )
}

export { UnauthorizedErrorView }
