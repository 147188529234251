import { Coding } from "fhir"
import { isDate, isEqual } from "date-fns"

import { ItemEnableWhenAnswer, OperatorType, ResponseItemAnswer, ResponseItemAnswerValue } from "type-alias"

const comparator =
  (comp: (item1: ItemValue, item2: ItemValue) => boolean) =>
  (answerValues: ResponseItemAnswer[], expectedValue: ItemEnableWhenAnswer) =>
    answerValues.some(
      ({ value: currentValue }) => currentValue !== undefined && compare(currentValue, expectedValue, comp),
    )

const equal = comparator((item1: ItemValue, item2: ItemValue) => {
  if (isDate(item1) && isDate(item2)) {
    return isEqual(item1 as Date, item2 as Date)
  }

  if (isCoding(item1) && isCoding(item2)) {
    return item1.code === item2.code && item1.system === item2.system
  }

  return typeof item1 === typeof item2 && item1 === item2
})

const exists = comparator((item1: ItemValue, item2: ItemValue) => {
  return typeof item1 === typeof item2 && typeof item1 === "boolean" && item1 === item2
})

const distinct = (answerValues: ResponseItemAnswer[], expectedValue: ItemEnableWhenAnswer) =>
  !equal(answerValues, expectedValue)

const compare = (
  currentValue: ResponseItemAnswerValue,
  expectedValue: ItemEnableWhenAnswer,
  comp: (itemAnswer: ItemValue, conditionValue: ItemValue) => boolean,
) => {
  const currentNumberValue = extractNumber(currentValue)
  const expectedNumberValue = extractNumber(expectedValue)
  if (currentNumberValue !== undefined && expectedNumberValue !== undefined) {
    return comp(currentNumberValue, expectedNumberValue)
  }
  const [expectedDateValue, currentDateValue] = extractDate(expectedValue, currentValue)
  if (currentDateValue !== undefined || expectedDateValue !== undefined) {
    return (
      currentDateValue !== undefined && expectedDateValue !== undefined && comp(currentDateValue, expectedDateValue)
    )
  }
  if (expectedValue.string !== undefined || currentValue.string !== undefined) {
    return (
      expectedValue.string !== undefined &&
      currentValue.string !== undefined &&
      comp(expectedValue.string, currentValue.string)
    )
  }
  if (expectedValue.Coding !== undefined || currentValue.Coding !== undefined) {
    return (
      expectedValue.Coding !== undefined &&
      currentValue.Coding !== undefined &&
      comp(expectedValue.Coding, currentValue.Coding)
    )
  }
  return (
    expectedValue.boolean !== undefined &&
    currentValue.boolean !== undefined &&
    comp(expectedValue.boolean, currentValue.boolean)
  )
}

const operators: Operator = {
  exists: exists,
  "=": equal,
  "!=": distinct,
  ">": comparator((item1: ItemValue, item2: ItemValue) => (isCoding(item1) || isCoding(item2) ? false : item1 > item2)),
  "<": comparator((item1: ItemValue, item2: ItemValue) => (isCoding(item1) || isCoding(item2) ? false : item1 < item2)),
  ">=": comparator((item1: ItemValue, item2: ItemValue) =>
    isCoding(item1) || isCoding(item2) ? false : item1 >= item2,
  ),
  "<=": comparator((item1: ItemValue, item2: ItemValue) =>
    isCoding(item1) || isCoding(item2) ? false : item1 <= item2,
  ),
}

const extractNumber = (item: ItemType) => {
  if (item.Quantity !== undefined && item.Quantity.value !== undefined) {
    return item.Quantity.value
  }
  if (item.decimal !== undefined) {
    return item.decimal
  }
  if (item.integer !== undefined) {
    return item.integer
  }
  return undefined
}

const extractDate = (expectedValue: ItemType, currentValue: ItemType) => {
  if (expectedValue.date !== undefined) {
    return [new Date(expectedValue.date), currentValue.date ? new Date(currentValue.date) : undefined]
  }
  if (expectedValue.dateTime !== undefined) {
    return [new Date(expectedValue.dateTime), currentValue.dateTime ? new Date(currentValue.dateTime) : undefined]
  }
  if (expectedValue.time !== undefined) {
    return [new Date(expectedValue.time), currentValue.time ? new Date(currentValue.time) : undefined]
  }
  return []
}

const isCoding = (item: ItemValue): item is Coding => {
  return (item as Coding).code !== undefined || (item as Coding).system !== undefined
}

const getOperation = (operator: OperatorType) => operators[operator]

type ItemType = ItemEnableWhenAnswer | ResponseItemAnswerValue

type ItemValue = number | string | boolean | Coding | Date

type Operator = {
  [key in OperatorType]: (responseItems: ResponseItemAnswer[], enableItem: ItemEnableWhenAnswer) => boolean
}

export { getOperation }
