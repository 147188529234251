import { Questionnaire } from "fhir"
import { useParams } from "react-router"
import { useQuery } from "react-query"

import { useClient } from "api"
import { apm } from "logger"

const useQuestionnaire = () => {
  const { questionnaire: questionnaireId } = useParams<Params>()
  const { search } = useClient()

  const { data, isLoading, isError } = useQuery(
    "questionnaire",
    async () => {
      if (!questionnaireId) {
        apm.setCustomContext({ key: "questionnaire", questionnaireId })
        apm.captureError(new Error("Invalid questionnaire id"))

        throw new Error("Invalid questionnaire id")
      }
      const { entry } = await search("Questionnaire", new URLSearchParams(`url=Questionnaire/${questionnaireId}`))

      if (entry && entry.length > 0 && entry[0].resource) {
        const [{ resource }] = entry
        return resource as Questionnaire
      }
      apm.setCustomContext({ key: "questionnaire", questionnaireId })
      apm.captureError(new Error("No questionnaire found"))

      throw new Error("No questionnaire found")
    },
    { suspense: true },
  )

  if (isError) {
    apm.setCustomContext({ key: "questionnaire", questionnaireId })
    apm.captureError(new Error("Error retrieving questionnaire"))

    throw new Error("Error retrieving questionnaire")
  }

  return { data, isLoading }
}

type Params = {
  questionnaire: string | undefined
}

export { useQuestionnaire }
