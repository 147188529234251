import { FC, useState } from "react"
import { useDebounce, useTimeoutFn, useUpdateEffect } from "react-use"
import { useFormContext, useWatch } from "react-hook-form"
import { ProgressBar } from "primereact/progressbar"
import { Button } from "primereact/button"

import { useQuestionsCount, useResponseUpdate } from "response/hooks"
import { UnauthorizedError } from "lib/errors"

import { FormItem } from "../types"

const ProgressIndicator: FC<Props> = ({
  totalAnswers,
  totalQuestions,
  eTag,
  setEtag,
  responseId,
  formItems,
  isSubmittingResponse,
}) => {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext()
  const value = useWatch({
    control,
    defaultValue: { item: undefined },
  })

  const { isLoading, isError, error, submit } = useResponseUpdate(formItems, responseId, eTag, setEtag)

  const { answers: currentAnswers, questions: currentQuestions } = useQuestionsCount(value?.item as FormItem[])

  const questions = currentQuestions ?? totalQuestions
  const answers = currentAnswers ?? totalAnswers

  const progress = ((answers / questions) * 100).toFixed()

  useDebounce(
    () => {
      if (!value.item) {
        return
      }

      if (responseId && !isSubmitting && !isLoading) {
        submit({ override: false, item: value.item })
      }
    },
    3000,
    [value],
  )

  if (error instanceof UnauthorizedError) {
    throw error
  }

  return (
    <div className="flex fixed bottom-0 w-screen bg-gray-100 py-3 px-12 md:px-20">
      <div className="flex-1 flex flex-col justify-center pr-12">
        <AutoSaveText isUpdating={isLoading} isError={isError}>{`${progress}% answered`}</AutoSaveText>
        <ProgressBar className="w-full  max-w-4xl h-4" value={progress} showValue={false}></ProgressBar>
      </div>
      <div className="flex items-center justify-center w-20">
        <Button
          type="submit"
          label={isSubmitting || isSubmittingResponse || isLoading ? "" : "Finish"}
          className="w-20 min-w-[5rem] button-primary"
          loading={isSubmitting || isSubmittingResponse || isLoading}
          disabled={isSubmitting || isSubmittingResponse || isLoading}
        />
      </div>
    </div>
  )
}

const AutoSaveText: FC<TextProps> = ({ isUpdating, isError, children }) => {
  const [showSaved, setShowSaved] = useState(false)

  const [, , reset] = useTimeoutFn(() => setShowSaved(false), 3000)

  useUpdateEffect(() => {
    if (!isUpdating) {
      setShowSaved(true)
      reset()
    }
  }, [isUpdating, reset])

  return (
    <p className="font-bold text-sm mb-2">
      {isUpdating ? (
        "Saving..."
      ) : showSaved ? (
        <span className={isError ? "text-red-500 " : "text-blue-500"}>{isError ? "Fail" : "Saved!"}</span>
      ) : (
        children
      )}
    </p>
  )
}

type TextProps = {
  isUpdating: boolean
  isError: boolean
  children: React.ReactNode
}

type Props = {
  responseId?: string
  isSubmittingResponse?: boolean
  eTag?: string
  setEtag?(value: string | undefined): void
  formItems: FormItem[]
  totalAnswers: number
  totalQuestions: number
}

export { ProgressIndicator }
