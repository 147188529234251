import { Route, Routes } from "react-router-dom"

import { Home } from "home"
import { NotFound } from "commons"
import { QuestionnaireDefinition } from "questionnaire"
import { FinishResponse, SurveyFormContainer } from "response"

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/qr/:questionnaireResponse" element={<SurveyFormContainer />} />
      <Route path="/q/:questionnaire" element={<QuestionnaireDefinition />} />
      <Route path="/finish" element={<FinishResponse />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export { AppRoutes }
