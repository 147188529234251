import { FormProvider, useForm } from "react-hook-form"

import { ConditionalProvider, SurveyForm } from "response/components"

const SurveyFormContainer = () => {
  const methods = useForm()

  return (
    <FormProvider {...methods}>
      <ConditionalProvider>
        <SurveyForm />
      </ConditionalProvider>
    </FormProvider>
  )
}

export { SurveyFormContainer }
