import { useLocation, useParams } from "react-router"

const useResponseParam = () => {
  const { questionnaireResponse: id } = useParams<Params>()
  const location = useLocation()
  const readOnlyParam = new URLSearchParams(location.search).get("readonly")

  return { id, isReadOnly: readOnlyParam === "true" }
}

type Params = {
  questionnaireResponse: string | undefined
}

export { useResponseParam }
