import { ResourceObject } from "fhir"
import { useMemo } from "react"

import { useAuth } from "security"

import { Client } from "../client"

const useClient = () => {
  const { user, logout } = useAuth()

  const client = useMemo(() => new Client(logout, user), [logout, user])

  const read = <T extends ResourceObject>(
    endpoint: string,
    id?: string,
    filters?: URLSearchParams,
    operation?: string,
    signal?: AbortSignal,
  ) => client.read<T>(endpoint, id, filters, operation, signal)

  const search = (endpoint: string, filters?: URLSearchParams, operation?: string, signal?: AbortSignal) =>
    client.search(endpoint, filters, operation, signal)

  const update = <T extends ResourceObject>(endpoint: string, id: string, resource: T) =>
    client.update(endpoint, id, resource)

  const patch = <T extends ResourceObject>(endpoint: string, id: string, resource: T, etag?: string) =>
    client.patch(endpoint, id, resource, etag)

  return { read, search, update, patch }
}

export { useClient }
