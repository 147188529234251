import { ApmBase, init as initApm } from "@elastic/apm-rum"

let apm: ApmBase

const init = () => {
  if (!apm) {
    apm = initApm({
      serviceName: window.REACT_APP_APM_SERVICE_NAME,
      serviceVersion: window.REACT_APP_APM_VERSION,
      serverUrl: window.REACT_APP_APM_SERVER_URL,
      environment: process.env.NODE_ENV,
      active: process.env.NODE_ENV === "production",
    })
  }
}

export { init, apm }
