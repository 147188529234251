import { useEffect, useMemo, useState } from "react"

const MOBILE_BREAKPOINT = 768

const useMobile = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const isMobile = useMemo(() => windowWidth <= MOBILE_BREAKPOINT, [windowWidth])

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return { isMobile }
}

export { useMobile }
