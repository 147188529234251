import { FC } from "react"
import { useQuery } from "react-query"
import { Coding, ValueSet as IValueSet } from "fhir"

import { ItemAnswerOption } from "type-alias"
import { useClient } from "api"

const ValueSet: FC<IProps> = ({ url, renderOptions }) => {
  const { read } = useClient()
  const { data, isLoading, isError } = useQuery(url, async () => {
    const { expansion } = await read<IValueSet>("ValueSet", "", new URLSearchParams(`url=${url}`), "expand")

    return expansion?.contains?.map(({ code, system, display }) => {
      const mapped = { code, system, display }
      return mapped as Coding
    })
  })

  if (isLoading) {
    return <span>Loading...</span>
  }

  if (isError) {
    return <div>Error retrieving value set [{url}]</div>
  }

  return renderOptions(
    data?.map((coding) => ({
      value: {
        Coding: coding,
      },
    })) ?? [],
  )
}

type IProps = {
  url: string
  renderOptions(options: ItemAnswerOption[]): JSX.Element
}

export { ValueSet }
