import { FC } from "react"
import { InputNumber, InputNumberChangeParams } from "primereact/inputnumber"
import { useFormContext, useController } from "react-hook-form"

import { QuestionAnswer } from "./QuestionAnswer"

const NumberQuestion: FC<IProps> = ({ name, type = "integer", defaultValue, readOnly }) => {
  const { control } = useFormContext()
  const {
    field: { value, onChange },
  } = useController({ name, control, defaultValue })

  const handleChange = (e: InputNumberChangeParams) => {
    onChange(e.value)
  }

  if (readOnly) {
    return <QuestionAnswer answer={defaultValue} />
  }

  if (type === "decimal") {
    return <InputNumber value={value} onChange={handleChange} mode="decimal" minFractionDigits={2} />
  }
  return <InputNumber value={value} onChange={handleChange} />
}

type IProps = {
  name: string
  type?: "integer" | "decimal"
  defaultValue?: number
  readOnly?: boolean
}

export { NumberQuestion }
