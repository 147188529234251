import React, { Suspense } from "react"
import { createRoot } from "react-dom/client"
import { PublicClientApplication } from "@azure/msal-browser"
import { QueryClient, QueryClientProvider, QueryErrorResetBoundary } from "react-query"
import { MsalProvider } from "@azure/msal-react"
import { BrowserRouter } from "react-router-dom"
import { ErrorBoundary } from "react-error-boundary"
import { ReactQueryDevtools } from "react-query/devtools"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"

import { LoadingView } from "commons"
import { AuthProvider, LoginProvider } from "security"
import { ErrorContainer } from "errors"
import { apm, init as loggerInit } from "logger"
import { msalConfig } from "authConfig"

import "./index.css"
import "primereact/resources/themes/lara-light-blue/theme.css"
import "./prime-react-custom-theme.css"
import "react-toastify/dist/ReactToastify.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

const container = document.getElementById("root")
const root = createRoot(container as HTMLElement)

loggerInit()

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig)
const queryClient = new QueryClient()

root.render(
  <MsalProvider instance={msalInstance}>
    <React.StrictMode>
      <AuthProvider>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <QueryErrorResetBoundary>
              {({ reset }) => (
                <ErrorBoundary
                  FallbackComponent={ErrorContainer}
                  onReset={reset}
                  onError={(error) => {
                    apm.captureError(error)
                  }}
                >
                  <Suspense fallback={<LoadingView />}>
                    <LoginProvider>
                      <App />
                    </LoginProvider>
                  </Suspense>
                </ErrorBoundary>
              )}
            </QueryErrorResetBoundary>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </QueryClientProvider>
        </BrowserRouter>
      </AuthProvider>
    </React.StrictMode>
  </MsalProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
