import { FC } from "react"
import { Patient, QuestionnaireResponse } from "fhir"
import { format } from "date-fns"

import { getHumanAge, humanNameAsString } from "lib/utils"

import { useResponseParam } from "response/hooks"

const QuestionnaireHeader: FC<IProps> = ({ response: { meta, status, authored }, questions, answers, patient }) => {
  const { isReadOnly } = useResponseParam()

  if (!isReadOnly && status !== "completed") {
    return null
  }

  return (
    <div className="italic text-sm px-6 md:px-20">
      <p>
        <span className="font-bold">Patient:</span> {humanNameAsString(patient?.name?.[0])}
      </p>
      <p>
        <span className="font-bold">Age:</span> {getHumanAge(patient?.birthDate)}
      </p>
      <p>
        <span className="font-bold">DOB:</span>{" "}
        {(patient?.birthDate && format(new Date(patient.birthDate), "MMMM do, yyyy")) ?? "n/a"}
      </p>
      <p>
        <span className="font-bold">Status:</span> {status}
      </p>
      <p>
        <span className="font-bold">Total questions:</span> {questions}
      </p>
      <p>
        <span className="font-bold">Answered questions:</span> {answers}
      </p>
      <p>
        <span className="font-bold">{status === "completed" ? "Answered on:" : "Last modified"}</span>{" "}
        {status === "completed"
          ? authored
            ? format(new Date(authored), "MMMM dd, yyyy - h:mm aaa")
            : "Unknown"
          : meta?.lastUpdated
          ? format(new Date(meta.lastUpdated), "MMMM dd, yyyy - h:mm aaa")
          : "Unknown"}
      </p>
    </div>
  )
}

type IProps = {
  response: QuestionnaireResponse
  questions: number
  answers: number
  patient?: Patient
}

export { QuestionnaireHeader }
