import { useState } from "react"

import { LoadingView } from "commons"

import { useQuestionnaireResponse, useResponseSubmit } from "response/hooks"
import { ProgressIndicator, Question, QuestionnaireHeader } from "response/components"
import { useFormContext } from "react-hook-form"

const SurveyForm = () => {
  const { handleSubmit } = useFormContext()
  const { data, isReadOnly, isLoading } = useQuestionnaireResponse()

  const [eTag, setEtag] = useState(data?.response?.meta?.id)

  const { submit, isSubmittingResponse } = useResponseSubmit(data?.response, data?.formData ?? [], eTag)

  if (isLoading) {
    return <LoadingView />
  }

  if (!data || !data?.formData?.length) {
    throw new Error("Questionnaire response invalid data")
  }

  const {
    questionnaire: { title, description },
    response,
    patient,
    formData,
    answers,
    total,
  } = data

  return (
    <div className="my-8">
      <h1 className="text-2xl md:text-4xl font-bold my-12 px-6 md:px-20">{title ?? "Survey"}</h1>
      <p>{description}</p>
      <QuestionnaireHeader answers={answers} questions={total} response={response} patient={patient} />
      <form className="mt-8" onSubmit={handleSubmit(submit as any)}>
        <div className="px-6 md:px-20 pb-20">
          {formData?.map((formItem, index) => (
            <Question key={formItem.linkId ?? index} name={`item.${index}`} item={formItem} readOnly={isReadOnly} />
          ))}
        </div>
        {!isReadOnly && (
          <ProgressIndicator
            eTag={eTag}
            setEtag={setEtag}
            responseId={response.id}
            totalQuestions={total}
            totalAnswers={answers}
            formItems={formData}
            isSubmittingResponse={isSubmittingResponse}
          />
        )}
      </form>
    </div>
  )
}

export { SurveyForm }
