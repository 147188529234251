import { FC } from "react"
import { FallbackProps } from "react-error-boundary"

import { InternalServerErrorView } from "./InternalServerErrorView"
import { NotFoundErrorView } from "./NotFoundErrorView"
import { UnauthorizedErrorView } from "./UnauthorizedErrorView"

const ErrorContainer: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const code = error.cause?.name

  switch (code) {
    case "401":
      return <UnauthorizedErrorView />
    case "404":
      return <NotFoundErrorView error={error} resetErrorBoundary={resetErrorBoundary} />
    default:
      return <InternalServerErrorView error={error} />
  }
}

export { ErrorContainer }
