import { FC } from "react"
import classNames from "classnames"

const Label: FC<Props> = ({ className, name, required, children, readonly }) => (
  <label htmlFor={name} className={classNames("mb-5 text-xl inline-block", className)}>
    {children}
    {!required && !readonly ? <span className="italic font-light text-base"> (optional)</span> : null}
  </label>
)

type Props = {
  name?: string
  className?: string
  required: boolean
  children: React.ReactNode
  readonly?: boolean
}

export { Label }
