import { HumanName } from "fhir"
import { toDate, differenceInYears, differenceInMonths } from "date-fns"

const humanNameAsString = (name?: HumanName, nickname?: string): string => {
  if (!name) {
    return "n/a"
  }
  if (name.text) {
    return name.text
  }
  let buffer = ""
  if (name.prefix?.length) {
    buffer = `${name.prefix.join(" ")} `
  }
  if (name.given?.length) {
    buffer = `${buffer}${name.given.join(" ")} `
  }
  if (nickname) {
    buffer = `${buffer} "${nickname}" `
  }
  if (name.family) {
    buffer = `${buffer}${name.family} `
  }
  if (name.suffix?.length) {
    buffer = `${buffer} ${name.suffix.join(" ")}`
  }
  return buffer
}

const getHumanAge = (birthDate?: string) => {
  if (!birthDate) {
    return "n/a"
  }

  const years = differenceInYears(toDate(new Date()), toDate(new Date(birthDate)))
  const months = differenceInMonths(toDate(new Date()), toDate(new Date(birthDate)))
  return years ? `${years}yo` : `${months}mo`
}

export { humanNameAsString, getHumanAge }
