import { useController, useFormContext } from "react-hook-form"
import { FC } from "react"

import { ValueSet, Radio } from "commons"
import { ItemAnswerOption, ResponseItemAnswer } from "type-alias"

import { CheckboxGroup } from "./CheckboxGroup"
import { RadioGroup } from "./RadioGroup"
import { QuestionAnswer } from "./QuestionAnswer"

export const UPPERCASE_A_CHAR_CODE = 65

const ChoiceQuestion: FC<Props> = ({
  name,
  multiple,
  defaultValue,
  valueSet,
  options: valueOptions,
  readOnly,
  getOptionLabel,
  getOptionSymbol,
}) => {
  const { control } = useFormContext()
  const {
    field: { onChange, value: fieldValue },
  } = useController({ name, control, defaultValue: defaultValue?.[0]?.value })

  const renderOptions = (options: ResponseItemAnswer[]) => {
    if (!options.length) {
      return <div>Question of type choice without options</div>
    }

    /*if (options.length >= CHOICE_OPTIONS_LENGTH) {
      return <div>Not implemented yet.</div>
    }*/

    if (multiple || options[0]?.value?.Coding) {
      const props = {
        name,
        options,
        control,
        getOptionLabel: (option: ItemAnswerOption) => getLabel(option),
      }

      return multiple ? (
        <CheckboxGroup {...props} defaultValue={defaultValue} />
      ) : (
        <RadioGroup {...props} defaultValue={defaultValue?.[0]} />
      )
    }

    return (
      <div className="flex flex-col gap-y-2">
        {options.map((option, index) => {
          const symbol = getOptionSymbol?.(option) ?? String.fromCharCode(UPPERCASE_A_CHAR_CODE + index)
          const { string, integer, boolean: valueBoolean } = option?.value ?? {}

          return (
            <Radio
              key={index}
              name={name}
              optionSymbol={symbol}
              label={getOptionLabel?.(option) ?? getLabel(option)}
              checked={
                (!!integer && fieldValue?.integer === integer) ||
                (!!string && fieldValue?.string === string) ||
                (valueBoolean !== undefined && fieldValue?.boolean === valueBoolean)
              }
              handleChange={() => {
                onChange(option.value)
              }}
            />
          )
        })}
      </div>
    )
  }

  if (readOnly) {
    return (
      <QuestionAnswer
        answer={defaultValue?.[0] ? getOptionLabel?.(defaultValue?.[0]) ?? getLabel(defaultValue?.[0]) : undefined}
      />
    )
  }

  return valueSet ? <ValueSet url={valueSet} renderOptions={renderOptions} /> : renderOptions(valueOptions ?? [])
}

const getLabel = (option: ItemAnswerOption) => {
  const { value } = option ?? {}
  const { Coding, string, integer } = value ?? {}

  return string ?? integer?.toString() ?? Coding?.display ?? Coding?.code ?? "not supported"
}

type Props = {
  name: string
  multiple?: boolean
  defaultValue?: ResponseItemAnswer[]
  valueSet?: string
  options?: ResponseItemAnswer[]
  readOnly?: boolean
  getOptionLabel?: (option: ResponseItemAnswer) => string | undefined
  getOptionSymbol?: (option: ResponseItemAnswer) => string
}

export { ChoiceQuestion }
