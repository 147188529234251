import { FC, useEffect } from "react"
import { UseFormReturn, useWatch } from "react-hook-form"

import { ResponseItemAnswer } from "type-alias"

import { ConditionalItem, WatchMap } from "../types"
import { useConditionalCallback } from "./ConditionalProvider"

const ConditionalQuestion: FC<IProps> = ({
  path,
  name,
  conditional: { name: conditionalName, initialValue, applyBehavior },
  control,
  setValue,
  children,
}) => {
  const value = useWatch({
    control,
    name: conditionalName,
    defaultValue: initialValue,
  })

  const setConditionalState = useConditionalCallback()

  const show = applyBehavior(createWatchMap(conditionalName, value))

  useEffect(() => {
    if (!show) {
      setValue(name, undefined)
    }

    setConditionalState(path, show)
  }, [name, path, setConditionalState, setValue, show])

  if (!show) {
    return null
  }

  return <>{children}</>
}

const createWatchMap = (keys: string[], values: WatchMap | unknown[]) => {
  if (!Array.isArray(values)) {
    return values
  }

  const map: WatchMap = {}

  keys.forEach((key, index) => {
    map[key] = values?.[index] as ResponseItemAnswer | ResponseItemAnswer[] | undefined
  })

  return map
}

type IProps = {
  path: string
  name: string
  conditional: ConditionalItem
  children: React.ReactNode
} & UseFormReturn

export { ConditionalQuestion }
