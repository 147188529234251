import { EMAIL_CONTACT_POINT_SYSTEM, PHONE_CONTACT_POINT_SYSTEM } from "./constants"
import {
  Bundle,
  CodeableConcept,
  ContactPoint,
  HumanName,
  isResourceObject,
  Reference,
  ResourceObject,
  Address,
  isPatient,
  isPractitioner,
  isOrganization,
} from "./fhir"

const getResources = <T extends ResourceObject>(bundle: Bundle) =>
  bundle.entry?.reduce((acc, { resource }) => {
    if (isResourceObject(resource)) {
      return [...acc, resource as T]
    }

    return acc
  }, [] as T[]) ?? []

const getResourcesAsIndex = <T extends ResourceObject>(bundle: Bundle) =>
  bundle.entry?.reduce((acc, { resource }) => {
    if (isResourceObject(resource) && resource.id) {
      return { ...acc, [resource.id]: resource as T }
    }

    return acc
  }, {} as Record<string, T>) ?? {}

const humanNameAsString = (name: HumanName | undefined): string => {
  if (!name) {
    return "No name provided"
  }

  if (name.text) {
    return name.text.trim()
  }

  const given = name?.given ? `${name?.given.join(" ")} ` : ""

  return `${given}${name.family}`
}

const getFirstEmail = (telecom: ContactPoint[] | undefined) => {
  const email = telecom?.find(({ system }) => system === EMAIL_CONTACT_POINT_SYSTEM)

  if (!email || !email?.value) {
    return "No email provided"
  }

  return email.value
}

const getFirstPhone = (telecom: ContactPoint[] | undefined) => {
  const phone = telecom?.find(({ system }) => system === PHONE_CONTACT_POINT_SYSTEM)

  if (!phone || !phone?.value) {
    return "No phone provided"
  }

  return phone.value
}

const getAddress = (address: Address[] | undefined) => {
  if (!address?.[0]) {
    return "No address found"
  }

  const { line, city, state, country, postalCode } = address?.[0] ?? {}

  return `${line}, ${city}, ${state}, ${country}, ${postalCode}`
}

const codeableConceptAsString = (cc: CodeableConcept | undefined) => {
  if (!cc?.coding?.[0].code) {
    return "unspecified"
  }

  return cc.text ?? cc.coding?.[0].display ?? cc.coding?.[0].code
}

const asReference = (resource: ResourceObject): Reference => {
  let display

  if (isPatient(resource) || isPractitioner(resource)) {
    display = humanNameAsString(resource.name?.[0])
  }

  if (isOrganization(resource)) {
    display = resource.name
  }

  return { id: resource.id, resourceType: resource.resourceType, ...(display ? { display } : {}) }
}

export {
  getResources,
  humanNameAsString,
  getFirstEmail,
  getFirstPhone,
  getAddress,
  codeableConceptAsString,
  asReference,
  getResourcesAsIndex,
}
