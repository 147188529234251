import { FC } from "react"

import "./Checkbox.css"

const Checkbox: FC<Props> = ({ name, label, optionSymbol, handleChange }) => (
  <label className="relative uppercase flex items-center w-fit">
    <input
      className="border-none rounded-sm w-12 h-8 cursor-pointer bg-[#b3b1b1] hover:bg-[#cdc9c9] shadow-[#b3b1b1c4_1px_1px_6px_0px] mr-4 transition-all checked:hover:bg-[#128fc9] checked:focus:bg-[#128fc9]"
      type="checkbox"
      name={name}
      onChange={handleChange}
    />
    <span className="absolute flex items-center justify-center w-12 h-8 font-semibold cursor-pointer hover:bg-[#cdc9c9] rounded-sm">
      {optionSymbol}
    </span>
    <span>{label}</span>
  </label>
)

type Props = {
  name: string
  label: string
  optionSymbol: string
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export { Checkbox }
