import classNames from "classnames"
import { Label, Message } from "commons"
import { FC } from "react"
import { useFormContext } from "react-hook-form"
import { ConditionalQuestion } from "response/components/ConditionalQuestion"

import { FormItem } from "response/types"
import { getFormItemName } from "response/util"

import { ChoiceQuestion } from "./ChoiceQuestion"
import { DateTimeQuestion } from "./DateTimeQuestion"
import { NumberQuestion } from "./NumberQuestion"

import { QuantityQuestion } from "./QuantityQuestion"
import { StringQuestion } from "./StringQuestion"

const Question: FC<Props> = ({ item: questionItem, name: namePath, readOnly }) => {
  const { type, text, repeats, valueSet, options, initial, required, item, conditional, defaultValue } = questionItem
  const name = getFormItemName(namePath, type, repeats, valueSet, options)
  const methods = useFormContext()

  const renderQuestionField = () => {
    switch (type) {
      case "string":
      case "text":
      case "url":
        return (
          <StringQuestion
            name={name}
            type={type}
            defaultValue={type === "url" ? defaultValue?.[0]?.value?.uri : defaultValue?.[0]?.value?.string}
            readOnly={readOnly}
          />
        )
      case "integer":
      case "decimal":
        return (
          <NumberQuestion
            name={name}
            type={type}
            defaultValue={type === "decimal" ? defaultValue?.[0]?.value?.decimal : defaultValue?.[0]?.value?.integer}
            readOnly={readOnly}
          />
        )
      case "quantity":
        return (
          <QuantityQuestion
            name={name}
            unit={initial?.[0]?.value?.Quantity?.unit}
            defaultValue={defaultValue?.[0]?.value?.Quantity}
            readOnly={readOnly}
          />
        )
      case "boolean":
        return (
          <ChoiceQuestion
            name={name}
            options={[{ value: { boolean: true } }, { value: { boolean: false } }]}
            getOptionLabel={(option) => (option?.value ? (option.value?.boolean ? "Yes" : "No") : undefined)}
            getOptionSymbol={(option) => (option?.value?.boolean === true ? "Y" : "N")}
            defaultValue={defaultValue}
            readOnly={readOnly}
          />
        )
      case "choice":
        return (
          <ChoiceQuestion
            name={name}
            multiple={repeats}
            valueSet={valueSet}
            options={options}
            defaultValue={defaultValue}
            readOnly={readOnly}
          />
        )
      case "date":
      case "dateTime":
      case "time":
        return (
          <DateTimeQuestion
            name={name}
            type={type}
            defaultValue={defaultValue?.[0]?.value?.[type]}
            readOnly={readOnly}
          />
        )
      default:
        return <p className="text-red-300">Question type not supported: {type}</p>
    }
  }

  const renderQuestion = () => {
    if (type === "display") {
      return (
        <div className="mb-8">
          <Message>{text}</Message>
        </div>
      )
    }

    return (
      <div className="mb-8">
        {type === "group" ? (
          <div className="mb-5">
            <span className="text-xl font-semibold">{text}</span>
            <hr className="mt-3" />
          </div>
        ) : (
          <div className="flex flex-col mb-5 w-11/12 md:w-8/12">
            <Label required={!!required} readonly={readOnly}>
              {text}
            </Label>
            {renderQuestionField()}
          </div>
        )}

        {!!item?.length && (
          <div className={classNames({ "pl-6": type === "group" && !!text })}>
            {item?.map((item, index) => (
              <Question key={item.linkId ?? index} name={`${name}.item.${index}`} item={item} readOnly={readOnly} />
            ))}
          </div>
        )}
      </div>
    )
  }

  return conditional ? (
    <ConditionalQuestion name={name} path={namePath} conditional={conditional} {...methods}>
      {renderQuestion()}
    </ConditionalQuestion>
  ) : (
    renderQuestion()
  )
}

type Props = {
  name: string
  item: FormItem
  readOnly?: boolean
}

export { Question }
