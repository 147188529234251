export interface IdentityProvider {
    active?:                 boolean;
    authorize_endpoint?:     string;
    base_url?:               string;
    client?:                 IdentityProviderClient;
    extension?:              Extension[];
    id?:                     string;
    introspection_endpoint?: string;
    jwks_uri?:               string;
    meta?:                   Meta;
    modifierExtension?:      Extension[];
    organizations?:          string[];
    registration_endpoint?:  string;
    resourceType?:           "IdentityProvider";
    revocation_endpoint?:    string;
    scopes?:                 string[];
    system?:                 string;
    token_endpoint?:         string;
    toScim?:                 IdentityProviderToScim;
    type?:                   IdentityProviderType;
    userinfo_endpoint?:      string;
}

export interface IdentityProviderClient {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    redirect_uri?:      string;
    secret?:            string;
}

export interface Extension {
    extension?:                      Extension[];
    id?:                             string;
    modifierExtension?:              Extension[];
    url?:                            string;
    valueAddress?:                   any;
    valueAge?:                       any;
    valueAnnotation?:                any;
    valueAttachment?:                any;
    valueBase64Binary?:              string;
    valueBoolean?:                   boolean;
    valueCanonical?:                 string;
    valueCode?:                      string;
    valueCodeableConcept?:           any;
    valueCoding?:                    any;
    valueContactDetail?:             any;
    valueContactPoint?:              any;
    valueContributor?:               any;
    valueCount?:                     any;
    valueDataRequirement?:           any;
    valueDate?:                      string;
    valueDateTime?:                  string;
    valueDecimal?:                   number;
    valueDistance?:                  any;
    valueDosage?:                    any;
    valueDuration?:                  any;
    valueElementDefinition?:         any;
    valueEmail?:                     string;
    valueExpression?:                any;
    valueHumanName?:                 any;
    valueId?:                        string;
    valueIdentifier?:                any;
    valueInstant?:                   Date;
    valueInteger?:                   number;
    valueKeyword?:                   string;
    valueMap?:                       any;
    valueMarkdown?:                  string;
    valueMarketingStatus?:           any;
    valueMeta?:                      any;
    valueMoney?:                     any;
    valueMoneyQuantity?:             any;
    valueNarrative?:                 any;
    valueNumber?:                    number;
    valueOid?:                       string;
    valueParameterDefinition?:       any;
    valuePassword?:                  string;
    valuePeriod?:                    any;
    valuePopulation?:                any;
    valuePositiveInt?:               number;
    valueProdCharacteristic?:        any;
    valueProductShelfLife?:          any;
    valueQuantity?:                  any;
    valueRange?:                     any;
    valueRatio?:                     any;
    valueReference?:                 any;
    valueRelatedArtifact?:           any;
    valueSampledData?:               any;
    valueSearchParameterExpression?: any;
    valueSignature?:                 any;
    valueSimpleQuantity?:            any;
    valueString?:                    string;
    valueSubstanceAmount?:           any;
    valueTime?:                      string;
    valueTiming?:                    any;
    valueTriggerDefinition?:         any;
    valueUnsignedInt?:               number;
    valueUri?:                       string;
    valueUrl?:                       string;
    valueUsageContext?:              any;
    valueUuid?:                      string;
    valueXhtml?:                     string;
}

export interface Coding {
    code?:              string;
    display?:           string;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    system?:            string;
    userSelected?:      boolean;
    version?:           string;
}

export interface Meta {
    createdAt?:         Date;
    extension?:         Extension[];
    id?:                string;
    lastUpdated?:       Date;
    meta?:              Meta;
    modifierExtension?: Extension[];
    profile?:           string[];
    security?:          Coding[];
    source?:            string;
    sourceId?:          string;
    tag?:               Coding[];
    versionId?:         string;
}

export interface IdentityProviderToScim {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export enum IdentityProviderType {
    Aidbox = "aidbox",
    AzDev = "az-dev",
    Github = "github",
    Google = "google",
    OAuth = "OAuth",
    Oidc = "OIDC",
}

export interface DeviceRequest {
    authoredOn?:            string;
    basedOn?:               Reference[];
    code?:                  DeviceRequestCode;
    contained?:             Array<any[] | boolean | number | number | null | ResourceObject | string>;
    encounter?:             Reference;
    extension?:             Extension[];
    groupIdentifier?:       Identifier;
    id?:                    string;
    identifier?:            Identifier[];
    implicitRules?:         string;
    instantiatesCanonical?: string[];
    instantiatesUri?:       string[];
    insurance?:             Reference[];
    intent:                 string;
    language?:              string;
    meta?:                  Meta;
    modifierExtension?:     Extension[];
    note?:                  Annotation[];
    occurrence?:            DeviceRequestOccurrence;
    parameter?:             DeviceRequestParameterArray[];
    performer?:             Reference;
    performerType?:         CodeableConcept;
    priority?:              string;
    priorRequest?:          Reference[];
    reasonCode?:            CodeableConcept[];
    reasonReference?:       Reference[];
    relevantHistory?:       Reference[];
    requester?:             Reference;
    resourceType?:          "DeviceRequest";
    status?:                string;
    subject:                Reference;
    supportingInfo?:        Reference[];
    text?:                  Narrative;
}

export interface Identifier {
    assigner?:          Reference;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    period?:            Period;
    system?:            string;
    type?:              CodeableConcept;
    use?:               string;
    value?:             string;
}

export interface Reference {
    display?:           string;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier;
    localRef?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    resource?:          any[] | boolean | number | number | null | ResourceObject | string;
    resourceType?:      string;
    type?:              string;
    uri?:               string;
}

export interface Period {
    end?:               string;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    start?:             string;
}

export interface CodeableConcept {
    coding?:            Coding[];
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    text?:              string;
}

export interface ResourceObject {
    id?:            string;
    implicitRules?: string;
    language?:      string;
    meta?:          Meta;
    resourceType?:  string;
}

export const isResourceObject = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is ResourceObject =>
    !!(resource && typeof (resource as ResourceObject).resourceType === "string")

export interface DeviceRequestCode {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface Annotation {
    author?:            AnnotationAuthor;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    text:               string;
    time?:              string;
}

export interface AnnotationAuthor {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
    string?:            string;
}

export interface DeviceRequestOccurrence {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
    Timing?:            Timing;
}

export interface Timing {
    code?:              CodeableConcept;
    event?:             string[];
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    repeat?:            TimingRepeat;
}

export interface TimingRepeat {
    bounds?:            TimingRepeatBounds;
    count?:             number;
    countMax?:          number;
    dayOfWeek?:         string[];
    duration?:          number;
    durationMax?:       number;
    durationUnit?:      string;
    extension?:         Extension[];
    frequency?:         number;
    frequencyMax?:      number;
    id?:                string;
    modifierExtension?: Extension[];
    offset?:            number;
    period?:            number;
    periodMax?:         number;
    periodUnit?:        string;
    timeOfDay?:         string[];
    when?:              string[];
}

export interface TimingRepeatBounds {
    Duration?:          Duration;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
    Range?:             Range;
}

export interface Duration {
    code?:              string;
    comparator?:        string;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    system?:            string;
    unit?:              string;
    value?:             number;
}

export interface Range {
    extension?:         Extension[];
    high?:              Quantity;
    id?:                string;
    low?:               Quantity;
    meta?:              Meta;
    modifierExtension?: Extension[];
}

export interface Quantity {
    code?:              string;
    comparator?:        string;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    system?:            string;
    unit?:              string;
    value?:             number;
}

export interface DeviceRequestParameterArray {
    code?:              CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    value?:             DeviceRequestParameterArrayValue;
}

export interface DeviceRequestParameterArrayValue {
    boolean?:           boolean;
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    Range?:             Range;
}

export interface Narrative {
    div:                string;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    status:             string;
}

export interface ServiceRequest {
    asNeeded?:              ServiceRequestAsNeeded;
    authoredOn?:            string;
    basedOn?:               Reference[];
    bodySite?:              CodeableConcept[];
    category?:              CodeableConcept[];
    code?:                  CodeableConcept;
    contained?:             Array<any[] | boolean | number | number | null | ResourceObject | string>;
    doNotPerform?:          boolean;
    encounter?:             Reference;
    extension?:             Extension[];
    id?:                    string;
    identifier?:            Identifier[];
    implicitRules?:         string;
    instantiatesCanonical?: string[];
    instantiatesUri?:       string[];
    insurance?:             Reference[];
    intent:                 string;
    language?:              string;
    locationCode?:          CodeableConcept[];
    locationReference?:     Reference[];
    meta?:                  Meta;
    modifierExtension?:     Extension[];
    note?:                  Annotation[];
    occurrence?:            ServiceRequestOccurrence;
    orderDetail?:           CodeableConcept[];
    patientInstruction?:    string;
    performer?:             Reference[];
    performerType?:         CodeableConcept;
    priority?:              string;
    quantity?:              ServiceRequestQuantity;
    reasonCode?:            CodeableConcept[];
    reasonReference?:       Reference[];
    relevantHistory?:       Reference[];
    replaces?:              Reference[];
    requester?:             Reference;
    requisition?:           Identifier;
    resourceType?:          "ServiceRequest";
    specimen?:              Reference[];
    status:                 string;
    subject:                Reference;
    supportingInfo?:        Reference[];
    text?:                  Narrative;
}

export const isServiceRequest = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is ServiceRequest =>
    isResourceObject(resource) && resource.resourceType ==="ServiceRequest"

export interface ServiceRequestAsNeeded {
    boolean?:           boolean;
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface ServiceRequestOccurrence {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
    Timing?:            Timing;
}

export interface ServiceRequestQuantity {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    Range?:             Range;
    Ratio?:             Ratio;
}

export interface Ratio {
    denominator?:       Quantity;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    numerator?:         Quantity;
}

export interface DeviceMetric {
    calibration?:       DeviceMetricCalibrationArray[];
    category:           string;
    color?:             string;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    measurementPeriod?: Timing;
    meta?:              Meta;
    modifierExtension?: Extension[];
    operationalStatus?: string;
    parent?:            Reference;
    source?:            Reference;
    text?:              Narrative;
    type:               CodeableConcept;
    unit?:              CodeableConcept;
}

export interface DeviceMetricCalibrationArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    state?:             string;
    time?:              Date;
    type?:              string;
}

export interface CarePlan {
    activity?:              CarePlanActivityArray[];
    addresses?:             Reference[];
    author?:                Reference;
    basedOn?:               Reference[];
    careTeam?:              Reference[];
    category?:              CodeableConcept[];
    contained?:             Array<any[] | boolean | number | number | null | ResourceObject | string>;
    contributor?:           Reference[];
    created?:               string;
    description?:           string;
    encounter?:             Reference;
    extension?:             Extension[];
    goal?:                  Reference[];
    id?:                    string;
    identifier?:            Identifier[];
    implicitRules?:         string;
    instantiatesCanonical?: string[];
    instantiatesUri?:       string[];
    intent:                 string;
    language?:              string;
    meta?:                  Meta;
    modifierExtension?:     Extension[];
    note?:                  Annotation[];
    partOf?:                Reference[];
    period?:                Period;
    replaces?:              Reference[];
    resourceType?:          "CarePlan";
    status:                 string;
    subject:                Reference;
    supportingInfo?:        Reference[];
    text?:                  Narrative;
    title?:                 string;
}

export const isCarePlan = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is CarePlan =>
    isResourceObject(resource) && resource.resourceType ==="CarePlan"

export interface CarePlanActivityArray {
    detail?:                 CarePlanActivityArrayDetail;
    extension?:              Extension[];
    id?:                     string;
    modifierExtension?:      Extension[];
    outcomeCodeableConcept?: CodeableConcept[];
    outcomeReference?:       Reference[];
    progress?:               Annotation[];
    reference?:              Reference;
}

export interface CarePlanActivityArrayDetail {
    code?:                  CodeableConcept;
    dailyAmount?:           Quantity;
    description?:           string;
    doNotPerform?:          boolean;
    extension?:             Extension[];
    goal?:                  Reference[];
    id?:                    string;
    instantiatesCanonical?: string[];
    instantiatesUri?:       string[];
    kind?:                  string;
    location?:              Reference;
    modifierExtension?:     Extension[];
    performer?:             Reference[];
    product?:               CarePlanActivityArrayDetailProduct;
    quantity?:              Quantity;
    reasonCode?:            CodeableConcept[];
    reasonReference?:       Reference[];
    scheduled?:             CarePlanActivityArrayDetailScheduled;
    status:                 string;
    statusReason?:          CodeableConcept;
}

export interface CarePlanActivityArrayDetailProduct {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface CarePlanActivityArrayDetailScheduled {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
    string?:            string;
    Timing?:            Timing;
}

export interface Observation {
    basedOn?:           Reference[];
    bodySite?:          CodeableConcept;
    category?:          CodeableConcept[];
    code:               CodeableConcept;
    component?:         ObservationComponentArray[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    dataAbsentReason?:  CodeableConcept;
    derivedFrom?:       Reference[];
    device?:            Reference;
    effective?:         ObservationEffective;
    encounter?:         Reference;
    extension?:         Extension[];
    focus?:             Reference[];
    hasMember?:         Reference[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    interpretation?:    CodeableConcept[];
    issued?:            Date;
    language?:          string;
    meta?:              Meta;
    method?:            CodeableConcept;
    modifierExtension?: Extension[];
    note?:              Annotation[];
    partOf?:            Reference[];
    performer?:         Reference[];
    referenceRange?:    ObservationReferenceRangeArray[];
    resourceType?:      "Observation";
    specimen?:          Reference;
    status:             string;
    subject?:           Reference;
    text?:              Narrative;
    value?:             ObservationValue;
}

export const isObservation = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is Observation =>
    isResourceObject(resource) && resource.resourceType ==="Observation"

export interface ObservationComponentArray {
    code:               CodeableConcept;
    dataAbsentReason?:  CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    interpretation?:    CodeableConcept[];
    modifierExtension?: Extension[];
    referenceRange?:    ObservationReferenceRangeArray[];
    value?:             ObservationComponentArrayValue;
}

export interface ObservationReferenceRangeArray {
    age?:               Range;
    appliesTo?:         CodeableConcept[];
    extension?:         Extension[];
    high?:              Quantity;
    id?:                string;
    low?:               Quantity;
    modifierExtension?: Extension[];
    text?:              string;
    type?:              CodeableConcept;
}

export interface ObservationComponentArrayValue {
    boolean?:           boolean;
    CodeableConcept?:   CodeableConcept;
    dateTime?:          string;
    extension?:         Extension[];
    integer?:           number;
    modifierExtension?: Extension[];
    Period?:            Period;
    Quantity?:          Quantity;
    Range?:             Range;
    Ratio?:             Ratio;
    SampledData?:       SampledData;
    string?:            string;
    time?:              string;
}

export interface SampledData {
    data?:              string;
    dimensions:         number;
    extension?:         Extension[];
    factor?:            number;
    id?:                string;
    lowerLimit?:        number;
    meta?:              Meta;
    modifierExtension?: Extension[];
    origin:             Quantity;
    period:             number;
    upperLimit?:        number;
}

export interface ObservationEffective {
    dateTime?:          string;
    extension?:         Extension[];
    instant?:           Date;
    modifierExtension?: Extension[];
    Period?:            Period;
    Timing?:            Timing;
}

export interface ObservationValue {
    boolean?:           boolean;
    CodeableConcept?:   CodeableConcept;
    dateTime?:          string;
    extension?:         Extension[];
    integer?:           number;
    modifierExtension?: Extension[];
    Period?:            Period;
    Quantity?:          Quantity;
    Range?:             Range;
    Ratio?:             Ratio;
    SampledData?:       SampledData;
    string?:            string;
    time?:              string;
}

export interface MoneyQuantity {
    code?:              string;
    comparator?:        string;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    system?:            string;
    unit?:              string;
    value?:             number;
}

export interface Function {
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    resourceType?:      "Function";
}

export interface EnrollmentRequest {
    candidate?:         Reference;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    coverage?:          Reference;
    created?:           string;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    insurer?:           Reference;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    provider?:          Reference;
    resourceType?:      "EnrollmentRequest";
    status?:            string;
    text?:              Narrative;
}

export interface Group {
    active?:            boolean;
    actual:             boolean;
    characteristic?:    GroupCharacteristicArray[];
    code?:              CodeableConcept;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    managingEntity?:    Reference;
    member?:            GroupMemberArray[];
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    quantity?:          number;
    resourceType?:      "Group";
    text?:              Narrative;
    type:               string;
}

export interface GroupCharacteristicArray {
    code:               CodeableConcept;
    exclude:            boolean;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    period?:            Period;
    value?:             GroupCharacteristicArrayValue;
}

export interface GroupCharacteristicArrayValue {
    boolean?:           boolean;
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    Range?:             Range;
    Reference?:         Reference;
}

export interface GroupMemberArray {
    entity:             Reference;
    extension?:         Extension[];
    id?:                string;
    inactive?:          boolean;
    modifierExtension?: Extension[];
    period?:            Period;
}

export interface MessageDefinition {
    allowedResponse?:   MessageDefinitionAllowedResponseArray[];
    base?:              string;
    category?:          string;
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    copyright?:         string;
    date:               string;
    description?:       string;
    event?:             MessageDefinitionEvent;
    experimental?:      boolean;
    extension?:         Extension[];
    focus?:             MessageDefinitionFocusArray[];
    graph?:             string[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    jurisdiction?:      CodeableConcept[];
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    parent?:            string[];
    publisher?:         string;
    purpose?:           string;
    replaces?:          string[];
    resourceType?:      "MessageDefinition";
    responseRequired?:  string;
    status:             string;
    text?:              Narrative;
    title?:             string;
    url?:               string;
    useContext?:        UsageContext[];
    version?:           string;
}

export interface MessageDefinitionAllowedResponseArray {
    extension?:         Extension[];
    id?:                string;
    message:            string;
    modifierExtension?: Extension[];
    situation?:         string;
}

export interface ContactDetail {
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    telecom?:           ContactPoint[];
}

export interface ContactPoint {
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    period?:            Period;
    rank?:              number;
    system?:            string;
    use?:               string;
    value?:             string;
}

export interface MessageDefinitionEvent {
    Coding?:            Coding;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    uri?:               string;
}

export interface MessageDefinitionFocusArray {
    code:               string;
    extension?:         Extension[];
    id?:                string;
    max?:               string;
    min:                number;
    modifierExtension?: Extension[];
    profile?:           string;
}

export interface UsageContext {
    code:               Coding;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    value?:             UsageContextValue;
}

export interface UsageContextValue {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    Range?:             Range;
    Reference?:         Reference;
}

export interface Appointment {
    BETA_Body?:             AppointmentBetaBody;
    BETA_ExtraProperty?:    Coding[];
    appointmentType?:       CodeableConcept;
    basedOn?:               Reference[];
    calendar?:              Reference;
    cancelationReason?:     CodeableConcept;
    comment?:               string;
    contained?:             Array<any[] | boolean | number | number | null | ResourceObject | string>;
    created?:               string;
    description?:           string;
    end?:                   Date;
    extension?:             Extension[];
    id?:                    string;
    identifier?:            Identifier[];
    implicitRules?:         string;
    language?:              string;
    meta?:                  Meta;
    minutesDuration?:       number;
    modifierExtension?:     Extension[];
    participant:            AppointmentParticipantArray[];
    patientInstruction?:    string;
    priority?:              number;
    reasonCode?:            CodeableConcept[];
    reasonReference?:       Reference[];
    requestedPeriod?:       Period[];
    resourceType?:          "Appointment";
    serviceCategory?:       CodeableConcept[];
    serviceType?:           CodeableConcept[];
    slot?:                  Reference[];
    specialty?:             CodeableConcept[];
    start?:                 Date;
    status:                 string;
    supportingInformation?: Reference[];
    text?:                  Narrative;
}

export const isAppointment = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is Appointment =>
    isResourceObject(resource) && resource.resourceType === "Appointment"

export interface AppointmentBetaBody {
    content?:           string
    contentType?:       string
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface AppointmentParticipantArray {
    actor?:             Reference;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    period?:            Period;
    required?:          string;
    status:             string;
    type?:              CodeableConcept[];
}

export interface BiologicallyDerivedProduct {
    collection?:        BiologicallyDerivedProductCollection;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    manipulation?:      BiologicallyDerivedProductManipulation;
    meta?:              Meta;
    modifierExtension?: Extension[];
    parent?:            Reference[];
    processing?:        BiologicallyDerivedProductProcessingArray[];
    productCategory?:   string;
    productCode?:       CodeableConcept;
    quantity?:          number;
    request?:           Reference[];
    resourceType?:      "BiologicallyDerivedProduct";
    status?:            string;
    storage?:           BiologicallyDerivedProductStorageArray[];
    text?:              Narrative;
}

export interface BiologicallyDerivedProductCollection {
    collected?:         BiologicallyDerivedProductCollectionCollected;
    collector?:         Reference;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    source?:            Reference;
}

export interface BiologicallyDerivedProductCollectionCollected {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface BiologicallyDerivedProductManipulation {
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    time?:              BiologicallyDerivedProductManipulationTime;
}

export interface BiologicallyDerivedProductManipulationTime {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface BiologicallyDerivedProductProcessingArray {
    additive?:          Reference;
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    procedure?:         CodeableConcept;
    time?:              BiologicallyDerivedProductProcessingArrayTime;
}

export interface BiologicallyDerivedProductProcessingArrayTime {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface BiologicallyDerivedProductStorageArray {
    description?:       string;
    duration?:          Period;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    scale?:             string;
    temperature?:       number;
}

export interface QuestionnaireResponse {
    author?:            Reference;
    authored?:          string;
    basedOn?:           Reference[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    encounter?:         Reference;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier;
    implicitRules?:     string;
    item?:              QuestionnaireResponseItemArrayItemArray[];
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    partOf?:            Reference[];
    questionnaire?:     string;
    resourceType?:      "QuestionnaireResponse";
    source?:            Reference;
    status:             string;
    subject?:           Reference;
    text?:              Narrative;
}

export interface QuestionnaireResponseItemArrayAnswerArray {
    extension?:         Extension[];
    id?:                string;
    item?:              QuestionnaireResponseItemArrayItemArray[];
    modifierExtension?: Extension[];
    value?:             QuestionnaireResponseItemArrayAnswerArrayValue;
}

export interface QuestionnaireResponseItemArrayItemArray {
    answer?:            QuestionnaireResponseItemArrayAnswerArray[];
    definition?:        string;
    extension?:         Extension[];
    id?:                string;
    item?:              QuestionnaireResponseItemArrayItemArray[];
    linkId:             string;
    modifierExtension?: Extension[];
    text?:              string;
}

export interface QuestionnaireResponseItemArrayAnswerArrayValue {
    Attachment?:        Attachment;
    boolean?:           boolean;
    Coding?:            Coding;
    date?:              string;
    dateTime?:          string;
    decimal?:           number;
    extension?:         Extension[];
    integer?:           number;
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    Reference?:         Reference;
    string?:            string;
    time?:              string;
    uri?:               string;
}

export interface Attachment {
    contentType?:       string;
    creation?:          string;
    data?:              string;
    extension?:         Extension[];
    hash?:              string;
    id?:                string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    size?:              number;
    title?:             string;
    url?:               string;
}

export interface EffectEvidenceSynthesis {
    approvalDate?:       string;
    author?:             ContactDetail[];
    certainty?:          EffectEvidenceSynthesisCertaintyArray[];
    contact?:            ContactDetail[];
    contained?:          Array<any[] | boolean | number | number | null | ResourceObject | string>;
    copyright?:          string;
    date?:               string;
    description?:        string;
    editor?:             ContactDetail[];
    effectEstimate?:     EffectEvidenceSynthesisEffectEstimateArray[];
    effectivePeriod?:    Period;
    endorser?:           ContactDetail[];
    exposure:            Reference;
    exposureAlternative: Reference;
    extension?:          Extension[];
    id?:                 string;
    identifier?:         Identifier[];
    implicitRules?:      string;
    jurisdiction?:       CodeableConcept[];
    language?:           string;
    lastReviewDate?:     string;
    meta?:               Meta;
    modifierExtension?:  Extension[];
    name?:               string;
    note?:               Annotation[];
    outcome:             Reference;
    population:          Reference;
    publisher?:          string;
    relatedArtifact?:    RelatedArtifact[];
    resourceType?:       "EffectEvidenceSynthesis";
    resultsByExposure?:  EffectEvidenceSynthesisResultsByExposureArray[];
    reviewer?:           ContactDetail[];
    sampleSize?:         EffectEvidenceSynthesisSampleSize;
    status:              string;
    studyType?:          CodeableConcept;
    synthesisType?:      CodeableConcept;
    text?:               Narrative;
    title?:              string;
    topic?:              CodeableConcept[];
    url?:                string;
    useContext?:         UsageContext[];
    version?:            string;
}

export interface EffectEvidenceSynthesisCertaintyArray {
    certaintySubcomponent?: EffectEvidenceSynthesisCertaintyArrayCertaintySubcomponentArray[];
    extension?:             Extension[];
    id?:                    string;
    modifierExtension?:     Extension[];
    note?:                  Annotation[];
    rating?:                CodeableConcept[];
}

export interface EffectEvidenceSynthesisCertaintyArrayCertaintySubcomponentArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    note?:              Annotation[];
    rating?:            CodeableConcept[];
    type?:              CodeableConcept;
}

export interface EffectEvidenceSynthesisEffectEstimateArray {
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    precisionEstimate?: EffectEvidenceSynthesisEffectEstimateArrayPrecisionEstimateArray[];
    type?:              CodeableConcept;
    unitOfMeasure?:     CodeableConcept;
    value?:             number;
    variantState?:      CodeableConcept;
}

export interface EffectEvidenceSynthesisEffectEstimateArrayPrecisionEstimateArray {
    extension?:         Extension[];
    from?:              number;
    id?:                string;
    level?:             number;
    modifierExtension?: Extension[];
    to?:                number;
    type?:              CodeableConcept;
}

export interface RelatedArtifact {
    citation?:          string;
    display?:           string;
    document?:          Attachment;
    extension?:         Extension[];
    id?:                string;
    label?:             string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    resource?:          string;
    type:               string;
    url?:               string;
}

export interface EffectEvidenceSynthesisResultsByExposureArray {
    description?:          string;
    exposureState?:        string;
    extension?:            Extension[];
    id?:                   string;
    modifierExtension?:    Extension[];
    riskEvidenceSynthesis: Reference;
    variantState?:         CodeableConcept;
}

export interface EffectEvidenceSynthesisSampleSize {
    description?:          string;
    extension?:            Extension[];
    id?:                   string;
    modifierExtension?:    Extension[];
    numberOfParticipants?: number;
    numberOfStudies?:      number;
}

export interface MedicinalProductContraindication {
    comorbidity?:           CodeableConcept[];
    contained?:             Array<any[] | boolean | number | number | null | ResourceObject | string>;
    disease?:               CodeableConcept;
    diseaseStatus?:         CodeableConcept;
    extension?:             Extension[];
    id?:                    string;
    implicitRules?:         string;
    language?:              string;
    meta?:                  Meta;
    modifierExtension?:     Extension[];
    otherTherapy?:          MedicinalProductContraindicationOtherTherapyArray[];
    population?:            Population[];
    resourceType?:          "MedicinalProductContraindication";
    subject?:               Reference[];
    text?:                  Narrative;
    therapeuticIndication?: Reference[];
}

export interface MedicinalProductContraindicationOtherTherapyArray {
    extension?:              Extension[];
    id?:                     string;
    medication?:             MedicinalProductContraindicationOtherTherapyArrayMedication;
    modifierExtension?:      Extension[];
    therapyRelationshipType: CodeableConcept;
}

export interface MedicinalProductContraindicationOtherTherapyArrayMedication {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface Population {
    age?:                    PopulationAge;
    extension?:              Extension[];
    gender?:                 CodeableConcept;
    id?:                     string;
    meta?:                   Meta;
    modifierExtension?:      Extension[];
    physiologicalCondition?: CodeableConcept;
    race?:                   CodeableConcept;
}

export interface PopulationAge {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Range?:             Range;
}

export interface EpisodeOfCare {
    account?:              Reference[];
    careManager?:          Reference;
    contained?:            Array<any[] | boolean | number | number | null | ResourceObject | string>;
    diagnosis?:            EpisodeOfCareDiagnosisArray[];
    extension?:            Extension[];
    id?:                   string;
    identifier?:           Identifier[];
    implicitRules?:        string;
    language?:             string;
    managingOrganization?: Reference;
    meta?:                 Meta;
    modifierExtension?:    Extension[];
    patient:               Reference;
    period?:               Period;
    referralRequest?:      Reference[];
    resourceType?:         "EpisodeOfCare";
    status:                string;
    statusHistory?:        EpisodeOfCareStatusHistoryArray[];
    team?:                 Reference[];
    text?:                 Narrative;
    type?:                 CodeableConcept[];
}

export interface EpisodeOfCareDiagnosisArray {
    condition:          Reference;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    rank?:              number;
    role?:              CodeableConcept;
}

export interface EpisodeOfCareStatusHistoryArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    period:             Period;
    status:             string;
}

export interface Evidence {
    approvalDate?:      string;
    author?:            ContactDetail[];
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    copyright?:         string;
    date?:              string;
    description?:       string;
    editor?:            ContactDetail[];
    effectivePeriod?:   Period;
    endorser?:          ContactDetail[];
    exposureBackground: Reference;
    exposureVariant?:   Reference[];
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    jurisdiction?:      CodeableConcept[];
    language?:          string;
    lastReviewDate?:    string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    note?:              Annotation[];
    outcome?:           Reference[];
    publisher?:         string;
    relatedArtifact?:   RelatedArtifact[];
    resourceType?:      "Evidence";
    reviewer?:          ContactDetail[];
    shortTitle?:        string;
    status:             string;
    subtitle?:          string;
    text?:              Narrative;
    title?:             string;
    topic?:             CodeableConcept[];
    url?:               string;
    useContext?:        UsageContext[];
    version?:           string;
}

export interface SubstancePolymer {
    class?:                 CodeableConcept;
    contained?:             Array<any[] | boolean | number | number | null | ResourceObject | string>;
    copolymerConnectivity?: CodeableConcept[];
    extension?:             Extension[];
    geometry?:              CodeableConcept;
    id?:                    string;
    implicitRules?:         string;
    language?:              string;
    meta?:                  Meta;
    modification?:          string[];
    modifierExtension?:     Extension[];
    monomerSet?:            SubstancePolymerMonomerSetArray[];
    repeat?:                SubstancePolymerRepeatArray[];
    resourceType?:          "SubstancePolymer";
    text?:                  Narrative;
}

export interface SubstancePolymerMonomerSetArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    ratioType?:         CodeableConcept;
    startingMaterial?:  SubstancePolymerMonomerSetArrayStartingMaterialArray[];
}

export interface SubstancePolymerMonomerSetArrayStartingMaterialArray {
    amount?:            SubstanceAmount;
    extension?:         Extension[];
    id?:                string;
    isDefining?:        boolean;
    material?:          CodeableConcept;
    modifierExtension?: Extension[];
    type?:              CodeableConcept;
}

export interface SubstanceAmount {
    amount?:            SubstanceAmountAmount;
    amountText?:        string;
    amountType?:        CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    referenceRange?:    SubstanceAmountReferenceRange;
}

export interface SubstanceAmountAmount {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    Range?:             Range;
    string?:            string;
}

export interface SubstanceAmountReferenceRange {
    extension?:         Extension[];
    highLimit?:         Quantity;
    id?:                string;
    lowLimit?:          Quantity;
    modifierExtension?: Extension[];
}

export interface SubstancePolymerRepeatArray {
    averageMolecularFormula?: string;
    extension?:               Extension[];
    id?:                      string;
    modifierExtension?:       Extension[];
    numberOfUnits?:           number;
    repeatUnit?:              SubstancePolymerRepeatArrayRepeatUnitArray[];
    repeatUnitAmountType?:    CodeableConcept;
}

export interface SubstancePolymerRepeatArrayRepeatUnitArray {
    amount?:                      SubstanceAmount;
    degreeOfPolymerisation?:      SubstancePolymerRepeatArrayRepeatUnitArrayDegreeOfPolymerisationArray[];
    extension?:                   Extension[];
    id?:                          string;
    modifierExtension?:           Extension[];
    orientationOfPolymerisation?: CodeableConcept;
    repeatUnit?:                  string;
    structuralRepresentation?:    SubstancePolymerRepeatArrayRepeatUnitArrayStructuralRepresentationArray[];
}

export interface SubstancePolymerRepeatArrayRepeatUnitArrayDegreeOfPolymerisationArray {
    amount?:            SubstanceAmount;
    degree?:            CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface SubstancePolymerRepeatArrayRepeatUnitArrayStructuralRepresentationArray {
    attachment?:        Attachment;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    representation?:    string;
    type?:              CodeableConcept;
}

export interface BackboneElement {
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    resourceType?:      "BackboneElement";
}

export interface SupplyDelivery {
    basedOn?:           Reference[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    destination?:       Reference;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    occurrence?:        SupplyDeliveryOccurrence;
    partOf?:            Reference[];
    patient?:           Reference;
    receiver?:          Reference[];
    resourceType?:      "SupplyDelivery";
    status?:            string;
    suppliedItem?:      SupplyDeliverySuppliedItem;
    supplier?:          Reference;
    text?:              Narrative;
    type?:              CodeableConcept;
}

export interface SupplyDeliveryOccurrence {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
    Timing?:            Timing;
}

export interface SupplyDeliverySuppliedItem {
    extension?:         Extension[];
    id?:                string;
    item?:              SupplyDeliverySuppliedItemItem;
    modifierExtension?: Extension[];
    quantity?:          Quantity;
}

export interface SupplyDeliverySuppliedItemItem {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface SubstanceNucleicAcid {
    areaOfHybridisation?: string;
    contained?:           Array<any[] | boolean | number | number | null | ResourceObject | string>;
    extension?:           Extension[];
    id?:                  string;
    implicitRules?:       string;
    language?:            string;
    meta?:                Meta;
    modifierExtension?:   Extension[];
    numberOfSubunits?:    number;
    oligoNucleotideType?: CodeableConcept;
    resourceType?:        "SubstanceNucleicAcid";
    sequenceType?:        CodeableConcept;
    subunit?:             SubstanceNucleicAcidSubunitArray[];
    text?:                Narrative;
}

export interface SubstanceNucleicAcidSubunitArray {
    extension?:          Extension[];
    fivePrime?:          CodeableConcept;
    id?:                 string;
    length?:             number;
    linkage?:            SubstanceNucleicAcidSubunitArrayLinkageArray[];
    modifierExtension?:  Extension[];
    sequence?:           string;
    sequenceAttachment?: Attachment;
    subunit?:            number;
    sugar?:              SubstanceNucleicAcidSubunitArraySugarArray[];
    threePrime?:         CodeableConcept;
}

export interface SubstanceNucleicAcidSubunitArrayLinkageArray {
    connectivity?:      string;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier;
    modifierExtension?: Extension[];
    name?:              string;
    residueSite?:       string;
}

export interface SubstanceNucleicAcidSubunitArraySugarArray {
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier;
    modifierExtension?: Extension[];
    name?:              string;
    residueSite?:       string;
}

export interface AdverseEvent {
    actuality:              string;
    category?:              CodeableConcept[];
    contained?:             Array<any[] | boolean | number | number | null | ResourceObject | string>;
    contributor?:           Reference[];
    date?:                  string;
    detected?:              string;
    encounter?:             Reference;
    event?:                 CodeableConcept;
    extension?:             Extension[];
    id?:                    string;
    identifier?:            Identifier;
    implicitRules?:         string;
    language?:              string;
    location?:              Reference;
    meta?:                  Meta;
    modifierExtension?:     Extension[];
    outcome?:               CodeableConcept;
    recordedDate?:          string;
    recorder?:              Reference;
    referenceDocument?:     Reference[];
    resourceType?:          "AdverseEvent";
    resultingCondition?:    Reference[];
    seriousness?:           CodeableConcept;
    severity?:              CodeableConcept;
    study?:                 Reference[];
    subject:                Reference;
    subjectMedicalHistory?: Reference[];
    suspectEntity?:         AdverseEventSuspectEntityArray[];
    text?:                  Narrative;
}

export interface AdverseEventSuspectEntityArray {
    causality?:         AdverseEventSuspectEntityArrayCausalityArray[];
    extension?:         Extension[];
    id?:                string;
    instance:           Reference;
    modifierExtension?: Extension[];
}

export interface AdverseEventSuspectEntityArrayCausalityArray {
    assessment?:         CodeableConcept;
    author?:             Reference;
    extension?:          Extension[];
    id?:                 string;
    method?:             CodeableConcept;
    modifierExtension?:  Extension[];
    productRelatedness?: string;
}

export interface AidboxQuery {
    "count-query"?:     string;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    params?:            { [key: string]: AidboxQueryParam };
    query:              string;
    resourceType?:      "AidboxQuery";
}

export interface AidboxQueryParam {
    /**
     * Anything
     */
    default?:           any;
    extension?:         Extension[];
    format?:            string;
    isRequired?:        boolean;
    modifierExtension?: Extension[];
    type?:              PurpleType;
}

export enum PurpleType {
    Boolean = "boolean",
    Integer = "integer",
    Number = "number",
    Object = "object",
    String = "string",
}

export interface Endpoint {
    address:               string;
    connectionType:        Coding;
    contact?:              ContactPoint[];
    contained?:            Array<any[] | boolean | number | number | null | ResourceObject | string>;
    extension?:            Extension[];
    header?:               string[];
    id?:                   string;
    identifier?:           Identifier[];
    implicitRules?:        string;
    language?:             string;
    managingOrganization?: Reference;
    meta?:                 Meta;
    modifierExtension?:    Extension[];
    name?:                 string;
    payloadMimeType?:      string[];
    payloadType:           CodeableConcept[];
    period?:               Period;
    resourceType?:         "Endpoint";
    status:                string;
    text?:                 Narrative;
}

export interface Operation {
    action?:            any[] | { [key: string]: any } | string;
    app?:               Reference;
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    module?:            string;
    public?:            boolean;
    request?:           Array<RequestObject | string>;
    resourceType?:      "Operation";
}

export interface RequestObject {
    name: string;
}

export interface SubstanceReferenceInformation {
    classification?:    SubstanceReferenceInformationClassificationArray[];
    comment?:           string;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    extension?:         Extension[];
    gene?:              SubstanceReferenceInformationGeneArray[];
    geneElement?:       SubstanceReferenceInformationGeneElementArray[];
    id?:                string;
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    resourceType?:      "SubstanceReferenceInformation";
    target?:            SubstanceReferenceInformationTargetArray[];
    text?:              Narrative;
}

export interface SubstanceReferenceInformationClassificationArray {
    classification?:    CodeableConcept;
    domain?:            CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    source?:            Reference[];
    subtype?:           CodeableConcept[];
}

export interface SubstanceReferenceInformationGeneArray {
    extension?:          Extension[];
    gene?:               CodeableConcept;
    geneSequenceOrigin?: CodeableConcept;
    id?:                 string;
    modifierExtension?:  Extension[];
    source?:             Reference[];
}

export interface SubstanceReferenceInformationGeneElementArray {
    element?:           Identifier;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    source?:            Reference[];
    type?:              CodeableConcept;
}

export interface SubstanceReferenceInformationTargetArray {
    amount?:            SubstanceReferenceInformationTargetArrayAmount;
    amountType?:        CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    interaction?:       CodeableConcept;
    modifierExtension?: Extension[];
    organism?:          CodeableConcept;
    organismType?:      CodeableConcept;
    source?:            Reference[];
    target?:            Identifier;
    type?:              CodeableConcept;
}

export interface SubstanceReferenceInformationTargetArrayAmount {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    Range?:             Range;
    string?:            string;
}

export interface PGSequence {
    cycle?:             boolean;
    data_type?:         DataType;
    extension?:         Extension[];
    id?:                string;
    increment?:         number;
    maxvalue?:          number;
    meta?:              Meta;
    minvalue?:          number;
    modifierExtension?: Extension[];
    resourceType?:      "PGSequence";
    start?:             number;
}

export enum DataType {
    Bigint = "bigint",
    Integer = "integer",
    Smallint = "smallint",
}

export interface SubstanceSourceMaterial {
    contained?:            Array<any[] | boolean | number | number | null | ResourceObject | string>;
    countryOfOrigin?:      CodeableConcept[];
    developmentStage?:     CodeableConcept;
    extension?:            Extension[];
    fractionDescription?:  SubstanceSourceMaterialFractionDescriptionArray[];
    geographicalLocation?: string[];
    id?:                   string;
    implicitRules?:        string;
    language?:             string;
    meta?:                 Meta;
    modifierExtension?:    Extension[];
    organism?:             SubstanceSourceMaterialOrganism;
    organismId?:           Identifier;
    organismName?:         string;
    parentSubstanceId?:    Identifier[];
    parentSubstanceName?:  string[];
    partDescription?:      SubstanceSourceMaterialPartDescriptionArray[];
    resourceType?:         "SubstanceSourceMaterial";
    sourceMaterialClass?:  CodeableConcept;
    sourceMaterialState?:  CodeableConcept;
    sourceMaterialType?:   CodeableConcept;
    text?:                 Narrative;
}

export interface SubstanceSourceMaterialFractionDescriptionArray {
    extension?:         Extension[];
    fraction?:          string;
    id?:                string;
    materialType?:      CodeableConcept;
    modifierExtension?: Extension[];
}

export interface SubstanceSourceMaterialOrganism {
    author?:                   SubstanceSourceMaterialOrganismAuthorArray[];
    extension?:                Extension[];
    family?:                   CodeableConcept;
    genus?:                    CodeableConcept;
    hybrid?:                   SubstanceSourceMaterialOrganismHybrid;
    id?:                       string;
    intraspecificDescription?: string;
    intraspecificType?:        CodeableConcept;
    modifierExtension?:        Extension[];
    organismGeneral?:          SubstanceSourceMaterialOrganismOrganismGeneral;
    species?:                  CodeableConcept;
}

export interface SubstanceSourceMaterialOrganismAuthorArray {
    authorDescription?: string;
    authorType?:        CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface SubstanceSourceMaterialOrganismHybrid {
    extension?:            Extension[];
    hybridType?:           CodeableConcept;
    id?:                   string;
    maternalOrganismId?:   string;
    maternalOrganismName?: string;
    modifierExtension?:    Extension[];
    paternalOrganismId?:   string;
    paternalOrganismName?: string;
}

export interface SubstanceSourceMaterialOrganismOrganismGeneral {
    class?:             CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    kingdom?:           CodeableConcept;
    modifierExtension?: Extension[];
    order?:             CodeableConcept;
    phylum?:            CodeableConcept;
}

export interface SubstanceSourceMaterialPartDescriptionArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    part?:              CodeableConcept;
    partLocation?:      CodeableConcept;
}

export interface CompartmentDefinition {
    code:               string;
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    date?:              string;
    description?:       string;
    experimental?:      boolean;
    extension?:         Extension[];
    id?:                string;
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name:               string;
    publisher?:         string;
    purpose?:           string;
    resource?:          CompartmentDefinitionResourceArray[];
    resourceType?:      "CompartmentDefinition";
    search:             boolean;
    status:             string;
    text?:              Narrative;
    url:                string;
    useContext?:        UsageContext[];
    version?:           string;
}

export interface CompartmentDefinitionResourceArray {
    code:               string;
    documentation?:     string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    param?:             string[];
}

export interface DetectedIssue {
    author?:            Reference;
    code?:              CodeableConcept;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    detail?:            string;
    evidence?:          DetectedIssueEvidenceArray[];
    extension?:         Extension[];
    id?:                string;
    identified?:        DetectedIssueIdentified;
    identifier?:        Identifier[];
    implicated?:        Reference[];
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    mitigation?:        DetectedIssueMitigationArray[];
    modifierExtension?: Extension[];
    patient?:           Reference;
    reference?:         string;
    resourceType?:      "DetectedIssue";
    severity?:          string;
    status:             string;
    text?:              Narrative;
}

export interface DetectedIssueEvidenceArray {
    code?:              CodeableConcept[];
    detail?:            Reference[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface DetectedIssueIdentified {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface DetectedIssueMitigationArray {
    action:             CodeableConcept;
    author?:            Reference;
    date?:              string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface MedicationAdministration {
    category?:              CodeableConcept;
    contained?:             Array<any[] | boolean | number | number | null | ResourceObject | string>;
    context?:               Reference;
    device?:                Reference[];
    dosage?:                MedicationAdministrationDosage;
    effective?:             MedicationAdministrationEffective;
    eventHistory?:          Reference[];
    extension?:             Extension[];
    id?:                    string;
    identifier?:            Identifier[];
    implicitRules?:         string;
    instantiates?:          string[];
    language?:              string;
    medication?:            MedicationAdministrationMedication;
    meta?:                  Meta;
    modifierExtension?:     Extension[];
    note?:                  Annotation[];
    partOf?:                Reference[];
    performer?:             MedicationAdministrationPerformerArray[];
    reasonCode?:            CodeableConcept[];
    reasonReference?:       Reference[];
    request?:               Reference;
    resourceType?:          "MedicationAdministration";
    status:                 string;
    statusReason?:          CodeableConcept[];
    subject:                Reference;
    supportingInformation?: Reference[];
    text?:                  Narrative;
}

export interface MedicationAdministrationDosage {
    dose?:              Quantity;
    extension?:         Extension[];
    id?:                string;
    method?:            CodeableConcept;
    modifierExtension?: Extension[];
    rate?:              MedicationAdministrationDosageRate;
    route?:             CodeableConcept;
    site?:              CodeableConcept;
    text?:              string;
}

export interface MedicationAdministrationDosageRate {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    Ratio?:             Ratio;
}

export interface MedicationAdministrationEffective {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface MedicationAdministrationMedication {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface MedicationAdministrationPerformerArray {
    actor:              Reference;
    extension?:         Extension[];
    function?:          CodeableConcept;
    id?:                string;
    modifierExtension?: Extension[];
}

export interface EvidenceVariable {
    approvalDate?:      string;
    author?:            ContactDetail[];
    characteristic:     EvidenceVariableCharacteristicArray[];
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    copyright?:         string;
    date?:              string;
    description?:       string;
    editor?:            ContactDetail[];
    effectivePeriod?:   Period;
    endorser?:          ContactDetail[];
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    jurisdiction?:      CodeableConcept[];
    language?:          string;
    lastReviewDate?:    string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    note?:              Annotation[];
    publisher?:         string;
    relatedArtifact?:   RelatedArtifact[];
    resourceType?:      "EvidenceVariable";
    reviewer?:          ContactDetail[];
    shortTitle?:        string;
    status:             string;
    subtitle?:          string;
    text?:              Narrative;
    title?:             string;
    topic?:             CodeableConcept[];
    type?:              string;
    url?:               string;
    useContext?:        UsageContext[];
    version?:           string;
}

export interface EvidenceVariableCharacteristicArray {
    definition?:           EvidenceVariableCharacteristicArrayDefinition;
    description?:          string;
    exclude?:              boolean;
    extension?:            Extension[];
    groupMeasure?:         string;
    id?:                   string;
    modifierExtension?:    Extension[];
    participantEffective?: EvidenceVariableCharacteristicArrayParticipantEffective;
    timeFromStart?:        Duration;
    usageContext?:         UsageContext[];
}

export interface EvidenceVariableCharacteristicArrayDefinition {
    canonical?:         string;
    CodeableConcept?:   CodeableConcept;
    DataRequirement?:   DataRequirement;
    Expression?:        Expression;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
    TriggerDefinition?: TriggerDefinition;
}

export interface DataRequirement {
    codeFilter?:        DataRequirementCodeFilterArray[];
    dateFilter?:        DataRequirementDateFilterArray[];
    extension?:         Extension[];
    id?:                string;
    limit?:             number;
    meta?:              Meta;
    modifierExtension?: Extension[];
    mustSupport?:       string[];
    profile?:           string[];
    sort?:              DataRequirementSortArray[];
    subject?:           DataRequirementSubject;
    type:               string;
}

export interface DataRequirementCodeFilterArray {
    code?:              Coding[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    path?:              string;
    searchParam?:       string;
    valueSet?:          string;
}

export interface DataRequirementDateFilterArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    path?:              string;
    searchParam?:       string;
    value?:             DataRequirementDateFilterArrayValue;
}

export interface DataRequirementDateFilterArrayValue {
    dateTime?:          string;
    Duration?:          Duration;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface DataRequirementSortArray {
    direction:          string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    path:               string;
}

export interface DataRequirementSubject {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface Expression {
    description?:       string;
    expression?:        string;
    extension?:         Extension[];
    id?:                string;
    language:           string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    reference?:         string;
}

export interface TriggerDefinition {
    condition?:         Expression;
    data?:              DataRequirement[];
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    timing?:            TriggerDefinitionTiming;
    type:               string;
}

export interface TriggerDefinitionTiming {
    date?:              string;
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
    Timing?:            Timing;
}

export interface EvidenceVariableCharacteristicArrayParticipantEffective {
    dateTime?:          string;
    Duration?:          Duration;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
    Timing?:            Timing;
}

export interface ImplementationGuide {
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    copyright?:         string;
    date?:              string;
    definition?:        ImplementationGuideDefinition;
    dependsOn?:         ImplementationGuideDependsOnArray[];
    description?:       string;
    experimental?:      boolean;
    extension?:         Extension[];
    fhirVersion:        string[];
    global?:            ImplementationGuideGlobalArray[];
    id?:                string;
    implicitRules?:     string;
    jurisdiction?:      CodeableConcept[];
    language?:          string;
    license?:           string;
    manifest?:          ImplementationGuideManifest;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name:               string;
    packageId:          string;
    publisher?:         string;
    resourceType?:      "ImplementationGuide";
    status:             string;
    text?:              Narrative;
    title?:             string;
    url:                string;
    useContext?:        UsageContext[];
    version?:           string;
}

export interface ImplementationGuideDefinition {
    extension?:         Extension[];
    grouping?:          ImplementationGuideDefinitionGroupingArray[];
    id?:                string;
    modifierExtension?: Extension[];
    page?:              ImplementationGuideDefinitionPage;
    parameter?:         ImplementationGuideDefinitionParameterArray[];
    resource:           ImplementationGuideDefinitionResourceArray[];
    template?:          ImplementationGuideDefinitionTemplateArray[];
}

export interface ImplementationGuideDefinitionGroupingArray {
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name:               string;
}

export interface ImplementationGuideDefinitionPage {
    extension?:         Extension[];
    generation:         string;
    id?:                string;
    modifierExtension?: Extension[];
    name?:              ImplementationGuideDefinitionPageName;
    page?:              ImplementationGuideDefinitionPage[];
    title:              string;
}

export interface ImplementationGuideDefinitionPageName {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
    url?:               string;
}

export interface ImplementationGuideDefinitionParameterArray {
    code:               string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    value:              string;
}

export interface ImplementationGuideDefinitionResourceArray {
    description?:       string;
    example?:           ImplementationGuideDefinitionResourceArrayExample;
    extension?:         Extension[];
    fhirVersion?:       string[];
    groupingId?:        string;
    id?:                string;
    modifierExtension?: Extension[];
    name?:              string;
    reference:          Reference;
}

export interface ImplementationGuideDefinitionResourceArrayExample {
    boolean?:           boolean;
    canonical?:         string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface ImplementationGuideDefinitionTemplateArray {
    code:               string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    scope?:             string;
    source:             string;
}

export interface ImplementationGuideDependsOnArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    packageId?:         string;
    uri:                string;
    version?:           string;
}

export interface ImplementationGuideGlobalArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    profile:            string;
    type:               string;
}

export interface ImplementationGuideManifest {
    extension?:         Extension[];
    id?:                string;
    image?:             string[];
    modifierExtension?: Extension[];
    other?:             string[];
    page?:              ImplementationGuideManifestPageArray[];
    rendering?:         string;
    resource:           ImplementationGuideManifestResourceArray[];
}

export interface ImplementationGuideManifestPageArray {
    anchor?:            string[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name:               string;
    title?:             string;
}

export interface ImplementationGuideManifestResourceArray {
    example?:           ImplementationGuideManifestResourceArrayExample;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    reference:          Reference;
    relativePath?:      string;
}

export interface ImplementationGuideManifestResourceArrayExample {
    boolean?:           boolean;
    canonical?:         string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface Goal {
    achievementStatus?: CodeableConcept;
    addresses?:         Reference[];
    category?:          CodeableConcept[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    description:        CodeableConcept;
    expressedBy?:       Reference;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    lifecycleStatus:    string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    note?:              Annotation[];
    outcomeCode?:       CodeableConcept[];
    outcomeReference?:  Reference[];
    priority?:          CodeableConcept;
    resourceType?:      "Goal";
    start?:             GoalStart;
    statusDate?:        string;
    statusReason?:      string;
    subject:            Reference;
    target?:            GoalTargetArray[];
    text?:              Narrative;
}

export interface GoalStart {
    CodeableConcept?:   CodeableConcept;
    date?:              string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface GoalTargetArray {
    detail?:            GoalTargetArrayDetail;
    due?:               GoalTargetArrayDue;
    extension?:         Extension[];
    id?:                string;
    measure?:           CodeableConcept;
    modifierExtension?: Extension[];
}

export interface GoalTargetArrayDetail {
    boolean?:           boolean;
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    integer?:           number;
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    Range?:             Range;
    Ratio?:             Ratio;
    string?:            string;
}

export interface GoalTargetArrayDue {
    date?:              string;
    Duration?:          Duration;
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface Communication {
    about?:                 Reference[];
    basedOn?:               Reference[];
    category?:              CodeableConcept[];
    contained?:             Array<any[] | boolean | number | number | null | ResourceObject | string>;
    encounter?:             Reference;
    extension?:             Extension[];
    id?:                    string;
    identifier?:            Identifier[];
    implicitRules?:         string;
    inResponseTo?:          Reference[];
    instantiatesCanonical?: string[];
    instantiatesUri?:       string[];
    language?:              string;
    medium?:                CodeableConcept[];
    meta?:                  Meta;
    modifierExtension?:     Extension[];
    note?:                  Annotation[];
    partOf?:                Reference[];
    payload?:               CommunicationPayloadArray[];
    priority?:              string;
    reasonCode?:            CodeableConcept[];
    reasonReference?:       Reference[];
    received?:              string;
    recipient?:             Reference[];
    resourceType?:          "Communication";
    sender?:                Reference;
    sent?:                  string;
    status:                 string;
    statusReason?:          CodeableConcept;
    subject?:               Reference;
    text?:                  Narrative;
    topic?:                 CodeableConcept;
}

export const isCommunication = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is Communication =>
    isResourceObject(resource) && resource.resourceType ==="Communication"

export interface CommunicationPayloadArray {
    content?:           CommunicationPayloadArrayContent;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface CommunicationPayloadArrayContent {
    Attachment?:        Attachment;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
    string?:            string;
}

export interface AlphaSDC {
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    resourceType?:      "AlphaSDC";
    template?:          AlphaSDCTemplate;
}

export interface AlphaSDCTemplate {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface Schedule {
    active?:            boolean;
    actor:              Reference[];
    comment?:           string;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    planningHorizon?:   Period;
    resourceType?:      "Schedule";
    serviceCategory?:   CodeableConcept[];
    serviceType?:       CodeableConcept[];
    specialty?:         CodeableConcept[];
    text?:              Narrative;
}

export const isSchedule = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is Schedule =>
    isResourceObject(resource) && resource.resourceType === "Schedule"

export interface Notification {
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    provider?:          string;
    providerData?:      NotificationProviderData;
    resourceType?:      "Notification";
    status?:            NotificationStatus;
}

export interface NotificationProviderData {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export enum NotificationStatus {
    Delivered = "delivered",
    Error = "error",
}

export interface DocumentReference {
    authenticator?:     Reference;
    author?:            Reference[];
    category?:          CodeableConcept[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    content:            DocumentReferenceContentArray[];
    context?:           DocumentReferenceContext;
    custodian?:         Reference;
    date?:              Date;
    description?:       string;
    docStatus?:         string;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    masterIdentifier?:  Identifier;
    meta?:              Meta;
    modifierExtension?: Extension[];
    relatesTo?:         DocumentReferenceRelatesToArray[];
    resourceType?:      "DocumentReference";
    securityLabel?:     CodeableConcept[];
    status:             string;
    subject?:           Reference;
    text?:              Narrative;
    type?:              CodeableConcept;
}

export const isDocumentReference = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is DocumentReference =>
    isResourceObject(resource) && resource.resourceType === "DocumentReference"

export interface DocumentReferenceContentArray {
    attachment:         Attachment;
    extension?:         Extension[];
    format?:            Coding;
    id?:                string;
    modifierExtension?: Extension[];
}

export interface DocumentReferenceContext {
    encounter?:         Reference[];
    event?:             CodeableConcept[];
    extension?:         Extension[];
    facilityType?:      CodeableConcept;
    id?:                string;
    modifierExtension?: Extension[];
    period?:            Period;
    practiceSetting?:   CodeableConcept;
    related?:           Reference[];
    sourcePatientInfo?: Reference;
}

export interface DocumentReferenceRelatesToArray {
    code:               string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    target:             Reference;
}

export interface AidboxJobStatus {
    error?:             AidboxJobStatusError;
    extension?:         Extension[];
    id?:                string;
    job?:               Reference;
    locked?:            boolean;
    meta?:              Meta;
    modifierExtension?: Extension[];
    module?:            string;
    nextStart?:         string;
    resourceType?:      "AidboxJobStatus";
    result?:            AidboxJobStatusResult;
    start?:             string;
    status?:            string;
    stop?:              string;
    text?:              string;
}

export interface AidboxJobStatusError {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface AidboxJobStatusResult {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface OrganizationAffiliation {
    active?:                    boolean;
    code?:                      CodeableConcept[];
    contained?:                 Array<any[] | boolean | number | number | null | ResourceObject | string>;
    endpoint?:                  Reference[];
    extension?:                 Extension[];
    healthcareService?:         Reference[];
    id?:                        string;
    identifier?:                Identifier[];
    implicitRules?:             string;
    language?:                  string;
    location?:                  Reference[];
    meta?:                      Meta;
    modifierExtension?:         Extension[];
    network?:                   Reference[];
    organization?:              Reference;
    participatingOrganization?: Reference;
    period?:                    Period;
    resourceType?:              "OrganizationAffiliation";
    specialty?:                 CodeableConcept[];
    telecom?:                   ContactPoint[];
    text?:                      Narrative;
}

export interface Registration {
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    params?:            RegistrationParams;
    resource?:          RegistrationResource;
    resourceType?:      "Registration";
    status?:            RegistrationStatus;
}

export interface RegistrationParams {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface RegistrationResource {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export enum RegistrationStatus {
    Activated = "activated",
    Active = "active",
}

export interface DeviceDefinition {
    capability?:              DeviceDefinitionCapabilityArray[];
    contact?:                 ContactPoint[];
    contained?:               Array<any[] | boolean | number | number | null | ResourceObject | string>;
    deviceName?:              DeviceDefinitionDeviceNameArray[];
    extension?:               Extension[];
    id?:                      string;
    identifier?:              Identifier[];
    implicitRules?:           string;
    language?:                string;
    languageCode?:            CodeableConcept[];
    manufacturer?:            DeviceDefinitionManufacturer;
    material?:                DeviceDefinitionMaterialArray[];
    meta?:                    Meta;
    modelNumber?:             string;
    modifierExtension?:       Extension[];
    note?:                    Annotation[];
    onlineInformation?:       string;
    owner?:                   Reference;
    parentDevice?:            Reference;
    physicalCharacteristics?: ProdCharacteristic;
    property?:                DeviceDefinitionPropertyArray[];
    quantity?:                Quantity;
    resourceType?:            "DeviceDefinition";
    safety?:                  CodeableConcept[];
    shelfLifeStorage?:        ProductShelfLife[];
    specialization?:          DeviceDefinitionSpecializationArray[];
    text?:                    Narrative;
    type?:                    CodeableConcept;
    udiDeviceIdentifier?:     DeviceDefinitionUdiDeviceIdentifierArray[];
    url?:                     string;
    version?:                 string[];
}

export interface DeviceDefinitionCapabilityArray {
    description?:       CodeableConcept[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    type:               CodeableConcept;
}

export interface DeviceDefinitionDeviceNameArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name:               string;
    type:               string;
}

export interface DeviceDefinitionManufacturer {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
    string?:            string;
}

export interface DeviceDefinitionMaterialArray {
    allergenicIndicator?: boolean;
    alternate?:           boolean;
    extension?:           Extension[];
    id?:                  string;
    modifierExtension?:   Extension[];
    substance:            CodeableConcept;
}

export interface ProdCharacteristic {
    color?:             string[];
    depth?:             Quantity;
    extension?:         Extension[];
    externalDiameter?:  Quantity;
    height?:            Quantity;
    id?:                string;
    image?:             Attachment[];
    imprint?:           string[];
    meta?:              Meta;
    modifierExtension?: Extension[];
    nominalVolume?:     Quantity;
    scoring?:           CodeableConcept;
    shape?:             string;
    weight?:            Quantity;
    width?:             Quantity;
}

export interface DeviceDefinitionPropertyArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    type:               CodeableConcept;
    valueCode?:         CodeableConcept[];
    valueQuantity?:     Quantity[];
}

export interface ProductShelfLife {
    extension?:                    Extension[];
    id?:                           string;
    identifier?:                   Identifier;
    meta?:                         Meta;
    modifierExtension?:            Extension[];
    period:                        Quantity;
    specialPrecautionsForStorage?: CodeableConcept[];
    type:                          CodeableConcept;
}

export interface DeviceDefinitionSpecializationArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    systemType:         string;
    version?:           string;
}

export interface DeviceDefinitionUdiDeviceIdentifierArray {
    deviceIdentifier:   string;
    extension?:         Extension[];
    id?:                string;
    issuer:             string;
    jurisdiction:       string;
    modifierExtension?: Extension[];
}

export interface SettingDefinition {
    accessPolicy?:      CodeableConcept;
    category?:          CodeableConcept;
    code:               Coding;
    date?:              string;
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    item?:              SettingDefinitionItemArray[];
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    resourceType?:      "SettingDefinition";
    scope?:             CodeableConcept;
    status:             string;
}

export const isSettingDefinition = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is SettingDefinition =>
    isResourceObject(resource) && resource.resourceType ==="SettingDefinition"

export interface SettingDefinitionItemArray {
    code?:              Coding;
    default?:           SettingDefinitionItemArrayDefault;
    description?:       string;
    extension?:         Extension[];
    kind:               Coding;
    label?:             string;
    modifierExtension?: Extension[];
    required?:          boolean;
    secure?:            boolean;
    type:               string;
}

export interface SettingDefinitionItemArrayDefault {
    Attachment?:        Attachment;
    boolean?:           boolean;
    Coding?:            Coding;
    decimal?:           number;
    extension?:         Extension[];
    integer?:           number;
    modifierExtension?: Extension[];
    Reference?:         Reference;
    string?:            string;
    time?:              string;
    uri?:               string;
}

export interface Map {
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
}

export interface Coverage {
    beneficiary:        Reference;
    class?:             CoverageClassArray[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    contract?:          Reference[];
    costToBeneficiary?: CoverageCostToBeneficiaryArray[];
    dependent?:         string;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    network?:           string;
    order?:             number;
    payor:              Reference[];
    period?:            Period;
    policyHolder?:      Reference;
    relationship?:      CodeableConcept;
    resourceType?:      "Coverage";
    status:             string;
    subrogation?:       boolean;
    subscriber?:        Reference;
    subscriberId?:      string;
    text?:              Narrative;
    type?:              CodeableConcept;
}

export const isCoverage = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is Coverage =>
    isResourceObject(resource) && resource.resourceType === "Coverage"

export interface CoverageClassArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name?:              string;
    type:               CodeableConcept;
    value:              string;
}

export interface CoverageCostToBeneficiaryArray {
    exception?:         CoverageCostToBeneficiaryArrayExceptionArray[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    type?:              CodeableConcept;
    value?:             CoverageCostToBeneficiaryArrayValue;
}

export interface CoverageCostToBeneficiaryArrayExceptionArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    period?:            Period;
    type:               CodeableConcept;
}

export interface CoverageCostToBeneficiaryArrayValue {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Money?:             Money;
    Quantity?:          Quantity;
}

export interface Money {
    currency?:          string;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    value?:             number;
}

export interface AuditEvent {
    action?:            string;
    agent:              AuditEventAgentArray[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    entity?:            AuditEventEntityArray[];
    extension?:         Extension[];
    id?:                string;
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    outcome?:           string;
    outcomeDesc?:       string;
    period?:            Period;
    purposeOfEvent?:    CodeableConcept[];
    recorded:           Date;
    resourceType?:      "AuditEvent";
    source:             AuditEventSource;
    subtype?:           Coding[];
    text?:              Narrative;
    type:               Coding;
}

export const isAuditEvent = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is AuditEvent =>
    isResourceObject(resource) && resource.resourceType === "AuditEvent"

export interface AuditEventAgentArray {
    altId?:             string;
    extension?:         Extension[];
    id?:                string;
    location?:          Reference;
    media?:             Coding;
    modifierExtension?: Extension[];
    name?:              string;
    network?:           AuditEventAgentArrayNetwork;
    policy?:            string[];
    purposeOfUse?:      CodeableConcept[];
    requestor:          boolean;
    role?:              CodeableConcept[];
    type?:              CodeableConcept;
    who?:               Reference;
}

export interface AuditEventAgentArrayNetwork {
    address?:           string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    type?:              string;
}

export interface AuditEventEntityArray {
    description?:       string;
    detail?:            AuditEventEntityArrayDetailArray[];
    extension?:         Extension[];
    id?:                string;
    lifecycle?:         Coding;
    modifierExtension?: Extension[];
    name?:              string;
    query?:             string;
    role?:              Coding;
    securityLabel?:     Coding[];
    type?:              Coding;
    what?:              Reference;
}

export interface AuditEventEntityArrayDetailArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    type:               string;
    value?:             AuditEventEntityArrayDetailArrayValue;
}

export interface AuditEventEntityArrayDetailArrayValue {
    base64Binary?:      string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    string?:            string;
}

export interface AuditEventSource {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    observer:           Reference;
    site?:              string;
    type?:              Coding[];
}

export interface MessageHeader {
    author?:            Reference;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    definition?:        string;
    destination?:       MessageHeaderDestinationArray[];
    enterer?:           Reference;
    event?:             MessageHeaderEvent;
    extension?:         Extension[];
    focus?:             Reference[];
    id?:                string;
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    reason?:            CodeableConcept;
    resourceType?:      "MessageHeader";
    response?:          MessageHeaderResponse;
    responsible?:       Reference;
    sender?:            Reference;
    source:             MessageHeaderSource;
    text?:              Narrative;
}

export interface MessageHeaderDestinationArray {
    endpoint:           string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name?:              string;
    receiver?:          Reference;
    target?:            Reference;
}

export interface MessageHeaderEvent {
    Coding?:            Coding;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    uri?:               string;
}

export interface MessageHeaderResponse {
    code:               string;
    details?:           Reference;
    extension?:         Extension[];
    id?:                string;
    identifier:         string;
    modifierExtension?: Extension[];
}

export interface MessageHeaderSource {
    contact?:           ContactPoint;
    endpoint:           string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name?:              string;
    software?:          string;
    version?:           string;
}

export interface SimpleQuantity {
    code?:              string;
    comparator?:        string;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    system?:            string;
    unit?:              string;
    value?:             number;
}

export interface Contract {
    alias?:                 string[];
    applies?:               Period;
    author?:                Reference;
    authority?:             Reference[];
    contained?:             Array<any[] | boolean | number | number | null | ResourceObject | string>;
    contentDefinition?:     ContractContentDefinition;
    contentDerivative?:     CodeableConcept;
    domain?:                Reference[];
    expirationType?:        CodeableConcept;
    extension?:             Extension[];
    friendly?:              ContractFriendlyArray[];
    id?:                    string;
    identifier?:            Identifier[];
    implicitRules?:         string;
    instantiatesCanonical?: Reference;
    instantiatesUri?:       string;
    issued?:                string;
    language?:              string;
    legal?:                 ContractLegalArray[];
    legallyBinding?:        ContractLegallyBinding;
    legalState?:            CodeableConcept;
    meta?:                  Meta;
    modifierExtension?:     Extension[];
    name?:                  string;
    relevantHistory?:       Reference[];
    resourceType?:          "Contract";
    rule?:                  ContractRuleArray[];
    scope?:                 CodeableConcept;
    signer?:                ContractSignerArray[];
    site?:                  Reference[];
    status?:                string;
    subject?:               Reference[];
    subtitle?:              string;
    subType?:               CodeableConcept[];
    supportingInfo?:        Reference[];
    term?:                  ContractTermArrayArray[];
    text?:                  Narrative;
    title?:                 string;
    topic?:                 ContractTopic;
    type?:                  CodeableConcept;
    url?:                   string;
    version?:               string;
}

export interface ContractContentDefinition {
    copyright?:         string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    publicationDate?:   string;
    publicationStatus:  string;
    publisher?:         Reference;
    subType?:           CodeableConcept;
    type:               CodeableConcept;
}

export interface ContractFriendlyArray {
    content?:           ContractFriendlyArrayContent;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface ContractFriendlyArrayContent {
    Attachment?:        Attachment;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface ContractLegalArray {
    content?:           ContractLegalArrayContent;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface ContractLegalArrayContent {
    Attachment?:        Attachment;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface ContractLegallyBinding {
    Attachment?:        Attachment;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface ContractRuleArray {
    content?:           ContractRuleArrayContent;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface ContractRuleArrayContent {
    Attachment?:        Attachment;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface ContractSignerArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    party:              Reference;
    signature:          Signature[];
    type:               Coding;
}

export interface Signature {
    data?:              string;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    onBehalfOf?:        Reference;
    sigFormat?:         string;
    targetFormat?:      string;
    type:               Coding[];
    when:               Date;
    who:                Reference;
}

export interface ContractTermArrayArray {
    action?:            ContractTermArrayActionArray[];
    applies?:           Period;
    asset?:             ContractTermArrayAssetArray[];
    extension?:         Extension[];
    group?:             ContractTermArrayArray[];
    id?:                string;
    identifier?:        Identifier;
    issued?:            string;
    modifierExtension?: Extension[];
    offer:              ContractTermArrayOffer;
    securityLabel?:     ContractTermArraySecurityLabelArray[];
    subType?:           CodeableConcept;
    text?:              string;
    topic?:             ContractTermArrayTopic;
    type?:              CodeableConcept;
}

export interface ContractTermArrayActionArray {
    context?:             Reference;
    contextLinkId?:       string[];
    doNotPerform?:        boolean;
    extension?:           Extension[];
    id?:                  string;
    intent:               CodeableConcept;
    linkId?:              string[];
    modifierExtension?:   Extension[];
    note?:                Annotation[];
    occurrence?:          ContractTermArrayActionArrayOccurrence;
    performer?:           Reference;
    performerLinkId?:     string[];
    performerRole?:       CodeableConcept;
    performerType?:       CodeableConcept[];
    reason?:              string[];
    reasonCode?:          CodeableConcept[];
    reasonLinkId?:        string[];
    reasonReference?:     Reference[];
    requester?:           Reference[];
    requesterLinkId?:     string[];
    securityLabelNumber?: number[];
    status:               CodeableConcept;
    subject?:             ContractTermArrayActionArraySubjectArray[];
    type:                 CodeableConcept;
}

export interface ContractTermArrayActionArrayOccurrence {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
    Timing?:            Timing;
}

export interface ContractTermArrayActionArraySubjectArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    reference:          Reference[];
    role?:              CodeableConcept;
}

export interface ContractTermArrayAssetArray {
    answer?:              ContractTermArrayAnswerArray[];
    condition?:           string;
    context?:             ContractTermArrayAssetArrayContextArray[];
    extension?:           Extension[];
    id?:                  string;
    linkId?:              string[];
    modifierExtension?:   Extension[];
    period?:              Period[];
    periodType?:          CodeableConcept[];
    relationship?:        Coding;
    scope?:               CodeableConcept;
    securityLabelNumber?: number[];
    subtype?:             CodeableConcept[];
    text?:                string;
    type?:                CodeableConcept[];
    typeReference?:       Reference[];
    usePeriod?:           Period[];
    valuedItem?:          ContractTermArrayAssetArrayValuedItemArray[];
}

export interface ContractTermArrayAnswerArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    value?:             ContractTermArrayOfferAnswerArrayValue;
}

export interface ContractTermArrayOfferAnswerArrayValue {
    Attachment?:        Attachment;
    boolean?:           boolean;
    Coding?:            Coding;
    date?:              string;
    dateTime?:          string;
    decimal?:           number;
    extension?:         Extension[];
    integer?:           number;
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    Reference?:         Reference;
    string?:            string;
    time?:              string;
    uri?:               string;
}

export interface ContractTermArrayAssetArrayContextArray {
    code?:              CodeableConcept[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    reference?:         Reference;
    text?:              string;
}

export interface ContractTermArrayAssetArrayValuedItemArray {
    effectiveTime?:       string;
    entity?:              ContractTermArrayAssetArrayValuedItemArrayEntity;
    extension?:           Extension[];
    factor?:              number;
    id?:                  string;
    identifier?:          Identifier;
    linkId?:              string[];
    modifierExtension?:   Extension[];
    net?:                 Money;
    payment?:             string;
    paymentDate?:         string;
    points?:              number;
    quantity?:            Quantity;
    recipient?:           Reference;
    responsible?:         Reference;
    securityLabelNumber?: number[];
    unitPrice?:           Money;
}

export interface ContractTermArrayAssetArrayValuedItemArrayEntity {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface ContractTermArrayOffer {
    answer?:              ContractTermArrayAnswerArray[];
    decision?:            CodeableConcept;
    decisionMode?:        CodeableConcept[];
    extension?:           Extension[];
    id?:                  string;
    identifier?:          Identifier[];
    linkId?:              string[];
    modifierExtension?:   Extension[];
    party?:               ContractTermArrayOfferPartyArray[];
    securityLabelNumber?: number[];
    text?:                string;
    topic?:               Reference;
    type?:                CodeableConcept;
}

export interface ContractTermArrayOfferPartyArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    reference:          Reference[];
    role:               CodeableConcept;
}

export interface ContractTermArraySecurityLabelArray {
    category?:          Coding[];
    classification:     Coding;
    control?:           Coding[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    number?:            number[];
}

export interface ContractTermArrayTopic {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface ContractTopic {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface CodeSystem {
    caseSensitive?:     boolean;
    compositional?:     boolean;
    concept?:           CodeSystemConceptArrayConceptArray[];
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    content:            string;
    copyright?:         string;
    count?:             number;
    date?:              string;
    description?:       string;
    experimental?:      boolean;
    extension?:         Extension[];
    filter?:            CodeSystemFilterArray[];
    hierarchyMeaning?:  string;
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    jurisdiction?:      CodeableConcept[];
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    property?:          CodeSystemPropertyArray[];
    publisher?:         string;
    purpose?:           string;
    resourceType?:      "CodeSystem";
    status:             string;
    supplements?:       string;
    text?:              Narrative;
    title?:             string;
    url?:               string;
    useContext?:        UsageContext[];
    valueSet?:          string;
    version?:           string;
    versionNeeded?:     boolean;
}

export interface CodeSystemConceptArrayConceptArray {
    code:               string;
    concept?:           CodeSystemConceptArrayConceptArray[];
    definition?:        string;
    designation?:       CodeSystemConceptArrayDesignationArray[];
    display?:           string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    property?:          CodeSystemConceptArrayPropertyArray[];
}

export interface CodeSystemConceptArrayDesignationArray {
    extension?:         Extension[];
    id?:                string;
    language?:          string;
    modifierExtension?: Extension[];
    use?:               Coding;
    value:              string;
}

export interface CodeSystemConceptArrayPropertyArray {
    code:               string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    value?:             CodeSystemConceptArrayPropertyArrayValue;
}

export interface CodeSystemConceptArrayPropertyArrayValue {
    boolean?:           boolean;
    code?:              string;
    Coding?:            Coding;
    dateTime?:          string;
    decimal?:           number;
    extension?:         Extension[];
    integer?:           number;
    modifierExtension?: Extension[];
    string?:            string;
}

export interface CodeSystemFilterArray {
    code:               string;
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    operator:           string[];
    value:              string;
}

export interface CodeSystemPropertyArray {
    code:               string;
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    type:               string;
    uri?:               string;
}

export interface Element {
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    resourceType?:      "Element";
}

export interface PlanDefinition {
    action?:            PlanDefinitionActionArrayActionArray[];
    approvalDate?:      string;
    author?:            ContactDetail[];
    catalogHeader?:     Reference[];
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    copyright?:         string;
    date?:              string;
    description?:       string;
    editor?:            ContactDetail[];
    effectivePeriod?:   Period;
    endorser?:          ContactDetail[];
    experimental?:      boolean;
    extension?:         Extension[];
    goal?:              PlanDefinitionGoalArray[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    jurisdiction?:      CodeableConcept[];
    language?:          string;
    lastReviewDate?:    string;
    library?:           string[];
    logo?:              Attachment[];
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    publisher?:         string;
    purpose?:           string;
    relatedArtifact?:   RelatedArtifact[];
    resourceType?:      "PlanDefinition";
    reviewer?:          ContactDetail[];
    status:             string;
    subject?:           PlanDefinitionSubject;
    subtitle?:          string;
    text?:              Narrative;
    title?:             string;
    topic?:             CodeableConcept[];
    type?:              CodeableConcept;
    url?:               string;
    usage?:             string;
    useContext?:        UsageContext[];
    version?:           string;
}

export const isPlanDefinition = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is PlanDefinition =>
    isResourceObject(resource) && resource.resourceType ==="PlanDefinition"

export interface PlanDefinitionActionArrayActionArray {
    action?:              PlanDefinitionActionArrayActionArray[];
    cardinalityBehavior?: string;
    code?:                CodeableConcept[];
    condition?:           PlanDefinitionActionArrayConditionArray[];
    definition?:          PlanDefinitionActionArrayDefinition;
    description?:         string;
    documentation?:       RelatedArtifact[];
    dynamicValue?:        PlanDefinitionActionArrayDynamicValueArray[];
    extension?:           Extension[];
    goalId?:              string[];
    groupingBehavior?:    string;
    id?:                  string;
    input?:               DataRequirement[];
    modifierExtension?:   Extension[];
    output?:              DataRequirement[];
    participant?:         PlanDefinitionActionArrayParticipantArray[];
    precheckBehavior?:    string;
    prefix?:              string;
    priority?:            string;
    reason?:              CodeableConcept[];
    relatedAction?:       PlanDefinitionActionArrayRelatedActionArray[];
    requiredBehavior?:    string;
    selectionBehavior?:   string;
    subject?:             PlanDefinitionActionArraySubject;
    textEquivalent?:      string;
    timing?:              PlanDefinitionActionArrayTiming;
    title?:               string;
    transform?:           string;
    trigger?:             TriggerDefinition[];
    type?:                CodeableConcept;
}

export interface PlanDefinitionActionArrayConditionArray {
    expression?:        Expression;
    extension?:         Extension[];
    id?:                string;
    kind:               string;
    modifierExtension?: Extension[];
}

export interface PlanDefinitionActionArrayDefinition {
    canonical?:         string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    uri?:               string;
}

export interface PlanDefinitionActionArrayDynamicValueArray {
    expression?:        Expression;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    path?:              string;
}

export interface PlanDefinitionActionArrayParticipantArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    role?:              CodeableConcept;
    type:               string;
}

export interface PlanDefinitionActionArrayRelatedActionArray {
    actionId:           string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    offset?:            PlanDefinitionActionArrayRelatedActionArrayOffset;
    relationship:       string;
}

export interface PlanDefinitionActionArrayRelatedActionArrayOffset {
    Duration?:          Duration;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Range?:             Range;
}

export interface PlanDefinitionActionArraySubject {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface PlanDefinitionActionArrayTiming {
    Age?:               Age;
    dateTime?:          string;
    Duration?:          Duration;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
    Range?:             Range;
    Timing?:            Timing;
}

export interface Age {
    code?:              string;
    comparator?:        string;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    system?:            string;
    unit?:              string;
    value?:             number;
}

export interface PlanDefinitionGoalArray {
    addresses?:         CodeableConcept[];
    category?:          CodeableConcept;
    description:        CodeableConcept;
    documentation?:     RelatedArtifact[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    priority?:          CodeableConcept;
    start?:             CodeableConcept;
    target?:            PlanDefinitionGoalArrayTargetArray[];
}

export interface PlanDefinitionGoalArrayTargetArray {
    detail?:            PlanDefinitionGoalArrayTargetArrayDetail;
    due?:               Duration;
    extension?:         Extension[];
    id?:                string;
    measure?:           CodeableConcept;
    modifierExtension?: Extension[];
}

export interface PlanDefinitionGoalArrayTargetArrayDetail {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    Range?:             Range;
}

export interface PlanDefinitionSubject {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface Invoice {
    account?:             Reference;
    cancelledReason?:     string;
    contained?:           Array<any[] | boolean | number | number | null | ResourceObject | string>;
    date?:                string;
    extension?:           Extension[];
    id?:                  string;
    identifier?:          Identifier[];
    implicitRules?:       string;
    issuer?:              Reference;
    language?:            string;
    lineItem?:            InvoiceLineItemArray[];
    meta?:                Meta;
    modifierExtension?:   Extension[];
    note?:                Annotation[];
    participant?:         InvoiceParticipantArray[];
    paymentTerms?:        string;
    recipient?:           Reference;
    resourceType?:        "Invoice";
    status:               string;
    subject?:             Reference;
    text?:                Narrative;
    totalGross?:          Money;
    totalNet?:            Money;
    totalPriceComponent?: InvoicePriceComponentArray[];
    type?:                CodeableConcept;
}

export const isInvoice = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is Invoice =>
    isResourceObject(resource) && resource.resourceType ==="Invoice"

export interface InvoiceLineItemArray {
    chargeItem?:        InvoiceLineItemArrayChargeItem;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    priceComponent?:    InvoicePriceComponentArray[];
    sequence?:          number;
}

export interface InvoiceLineItemArrayChargeItem {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface InvoicePriceComponentArray {
    amount?:            Money;
    code?:              CodeableConcept;
    extension?:         Extension[];
    factor?:            number;
    id?:                string;
    modifierExtension?: Extension[];
    type:               string;
}

export interface InvoiceParticipantArray {
    actor:              Reference;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    role?:              CodeableConcept;
}

export interface AidboxProfile {
    bind:               Reference;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    resourceType?:      "AidboxProfile";
    schema:             { [key: string]: any };
}

export interface ClaimResponse {
    addItem?:              ClaimResponseAddItemArray[];
    adjudication?:         ClaimResponseAdjudicationArray[];
    communicationRequest?: Reference[];
    contained?:            Array<any[] | boolean | number | number | null | ResourceObject | string>;
    created:               string;
    disposition?:          string;
    error?:                ClaimResponseErrorArray[];
    extension?:            Extension[];
    form?:                 Attachment;
    formCode?:             CodeableConcept;
    fundsReserve?:         CodeableConcept;
    id?:                   string;
    identifier?:           Identifier[];
    implicitRules?:        string;
    insurance?:            ClaimResponseInsuranceArray[];
    insurer:               Reference;
    item?:                 ClaimResponseItemArray[];
    language?:             string;
    meta?:                 Meta;
    modifierExtension?:    Extension[];
    outcome:               string;
    patient:               Reference;
    payeeType?:            CodeableConcept;
    payment?:              ClaimResponsePayment;
    preAuthPeriod?:        Period;
    preAuthRef?:           string;
    processNote?:          ClaimResponseProcessNoteArray[];
    request?:              Reference;
    requestor?:            Reference;
    resourceType?:         "ClaimResponse";
    status:                string;
    subType?:              CodeableConcept;
    text?:                 Narrative;
    total?:                ClaimResponseTotalArray[];
    type:                  CodeableConcept;
    use:                   string;
}

export interface ClaimResponseAddItemArray {
    adjudication:       ClaimResponseAdjudicationArray[];
    bodySite?:          CodeableConcept;
    detail?:            ClaimResponseAddItemArrayDetailArray[];
    detailSequence?:    number[];
    extension?:         Extension[];
    factor?:            number;
    id?:                string;
    itemSequence?:      number[];
    location?:          ClaimResponseAddItemArrayLocation;
    modifier?:          CodeableConcept[];
    modifierExtension?: Extension[];
    net?:               Money;
    noteNumber?:        number[];
    productOrService:   CodeableConcept;
    programCode?:       CodeableConcept[];
    provider?:          Reference[];
    quantity?:          Quantity;
    serviced?:          ClaimResponseAddItemArrayServiced;
    subdetailSequence?: number[];
    subSite?:           CodeableConcept[];
    unitPrice?:         Money;
}

export interface ClaimResponseAdjudicationArray {
    amount?:            Money;
    category:           CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    reason?:            CodeableConcept;
    value?:             number;
}

export interface ClaimResponseAddItemArrayDetailArray {
    adjudication:       ClaimResponseAdjudicationArray[];
    extension?:         Extension[];
    factor?:            number;
    id?:                string;
    modifier?:          CodeableConcept[];
    modifierExtension?: Extension[];
    net?:               Money;
    noteNumber?:        number[];
    productOrService:   CodeableConcept;
    quantity?:          Quantity;
    subDetail?:         ClaimResponseAddItemArrayDetailArraySubDetailArray[];
    unitPrice?:         Money;
}

export interface ClaimResponseAddItemArrayDetailArraySubDetailArray {
    adjudication:       ClaimResponseAdjudicationArray[];
    extension?:         Extension[];
    factor?:            number;
    id?:                string;
    modifier?:          CodeableConcept[];
    modifierExtension?: Extension[];
    net?:               Money;
    noteNumber?:        number[];
    productOrService:   CodeableConcept;
    quantity?:          Quantity;
    unitPrice?:         Money;
}

export interface ClaimResponseAddItemArrayLocation {
    Address?:           Address;
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface Address {
    city?:              string;
    country?:           string;
    district?:          string;
    extension?:         Extension[];
    id?:                string;
    line?:              string[];
    meta?:              Meta;
    modifierExtension?: Extension[];
    period?:            Period;
    postalCode?:        string;
    state?:             string;
    text?:              string;
    type?:              string;
    use?:               string;
}

export interface ClaimResponseAddItemArrayServiced {
    date?:              string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface ClaimResponseErrorArray {
    code:               CodeableConcept;
    detailSequence?:    number;
    extension?:         Extension[];
    id?:                string;
    itemSequence?:      number;
    modifierExtension?: Extension[];
    subDetailSequence?: number;
}

export interface ClaimResponseInsuranceArray {
    businessArrangement?: string;
    claimResponse?:       Reference;
    coverage:             Reference;
    extension?:           Extension[];
    focal:                boolean;
    id?:                  string;
    modifierExtension?:   Extension[];
    sequence:             number;
}

export interface ClaimResponseItemArray {
    adjudication:       ClaimResponseAdjudicationArray[];
    detail?:            ClaimResponseItemArrayDetailArray[];
    extension?:         Extension[];
    id?:                string;
    itemSequence:       number;
    modifierExtension?: Extension[];
    noteNumber?:        number[];
}

export interface ClaimResponseItemArrayDetailArray {
    adjudication:       ClaimResponseAdjudicationArray[];
    detailSequence:     number;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    noteNumber?:        number[];
    subDetail?:         ClaimResponseItemArrayDetailArraySubDetailArray[];
}

export interface ClaimResponseItemArrayDetailArraySubDetailArray {
    adjudication?:      ClaimResponseAdjudicationArray[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    noteNumber?:        number[];
    subDetailSequence:  number;
}

export interface ClaimResponsePayment {
    adjustment?:        Money;
    adjustmentReason?:  CodeableConcept;
    amount:             Money;
    date?:              string;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier;
    modifierExtension?: Extension[];
    type:               CodeableConcept;
}

export interface ClaimResponseProcessNoteArray {
    extension?:         Extension[];
    id?:                string;
    language?:          CodeableConcept;
    modifierExtension?: Extension[];
    number?:            number;
    text:               string;
    type?:              string;
}

export interface ClaimResponseTotalArray {
    amount:             Money;
    category:           CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface ChargeItem {
    "R5_service"?:           Reference[];
    account?:                Reference[];
    bodysite?:               CodeableConcept[];
    code:                    CodeableConcept;
    contained?:              Array<any[] | boolean | number | number | null | ResourceObject | string>;
    context?:                Reference;
    costCenter?:             Reference;
    definitionCanonical?:    string[];
    definitionUri?:          string[];
    enteredDate?:            string;
    enterer?:                Reference;
    extension?:              Extension[];
    factorOverride?:         number;
    id?:                     string;
    identifier?:             Identifier[];
    implicitRules?:          string;
    language?:               string;
    meta?:                   Meta;
    modifierExtension?:      Extension[];
    note?:                   Annotation[];
    occurrence?:             ChargeItemOccurrence;
    overrideReason?:         string;
    partOf?:                 Reference[];
    performer?:              ChargeItemPerformerArray[];
    performingOrganization?: Reference;
    priceOverride?:          Money;
    product?:                ChargeItemProduct;
    quantity?:               Quantity;
    reason?:                 CodeableConcept[];
    requestingOrganization?: Reference;
    resourceType?:           "ChargeItem";
    service?:                Reference[];
    status:                  string;
    subject:                 Reference;
    supportingInformation?:  Reference[];
    text?:                   Narrative;
}

export const isChargeItem = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is ChargeItem =>
    isResourceObject(resource) && resource.resourceType ==="ChargeItem"

export interface ChargeItemOccurrence {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
    Timing?:            Timing;
}

export interface ChargeItemPerformerArray {
    actor:              Reference;
    extension?:         Extension[];
    function?:          CodeableConcept;
    id?:                string;
    modifierExtension?: Extension[];
}

export interface ChargeItemProduct {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface CoverageEligibilityResponse {
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    created:            string;
    disposition?:       string;
    error?:             CoverageEligibilityResponseErrorArray[];
    extension?:         Extension[];
    form?:              CodeableConcept;
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    insurance?:         CoverageEligibilityResponseInsuranceArray[];
    insurer:            Reference;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    outcome:            string;
    patient:            Reference;
    preAuthRef?:        string;
    purpose:            string[];
    request:            Reference;
    requestor?:         Reference;
    resourceType?:      "CoverageEligibilityResponse";
    serviced?:          CoverageEligibilityResponseServiced;
    status:             string;
    text?:              Narrative;
}

export interface CoverageEligibilityResponseErrorArray {
    code:               CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface CoverageEligibilityResponseInsuranceArray {
    benefitPeriod?:     Period;
    coverage:           Reference;
    extension?:         Extension[];
    id?:                string;
    inforce?:           boolean;
    item?:              CoverageEligibilityResponseInsuranceArrayItemArray[];
    modifierExtension?: Extension[];
}

export interface CoverageEligibilityResponseInsuranceArrayItemArray {
    authorizationRequired?:   boolean;
    authorizationSupporting?: CodeableConcept[];
    authorizationUrl?:        string;
    benefit?:                 CoverageEligibilityResponseInsuranceArrayItemArrayBenefitArray[];
    category?:                CodeableConcept;
    description?:             string;
    excluded?:                boolean;
    extension?:               Extension[];
    id?:                      string;
    modifier?:                CodeableConcept[];
    modifierExtension?:       Extension[];
    name?:                    string;
    network?:                 CodeableConcept;
    productOrService?:        CodeableConcept;
    provider?:                Reference;
    term?:                    CodeableConcept;
    unit?:                    CodeableConcept;
}

export interface CoverageEligibilityResponseInsuranceArrayItemArrayBenefitArray {
    allowed?:           CoverageEligibilityResponseInsuranceArrayItemArrayBenefitArrayAllowed;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    type:               CodeableConcept;
    used?:              CoverageEligibilityResponseInsuranceArrayItemArrayBenefitArrayUsed;
}

export interface CoverageEligibilityResponseInsuranceArrayItemArrayBenefitArrayAllowed {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Money?:             Money;
    string?:            string;
    unsignedInt?:       number;
}

export interface CoverageEligibilityResponseInsuranceArrayItemArrayBenefitArrayUsed {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Money?:             Money;
    string?:            string;
    unsignedInt?:       number;
}

export interface CoverageEligibilityResponseServiced {
    date?:              string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface BodyStructure {
    active?:            boolean;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    image?:             Attachment[];
    implicitRules?:     string;
    language?:          string;
    location?:          CodeableConcept;
    locationQualifier?: CodeableConcept[];
    meta?:              Meta;
    modifierExtension?: Extension[];
    morphology?:        CodeableConcept;
    patient:            Reference;
    resourceType?:      "BodyStructure";
    text?:              Narrative;
}

export interface Parameters {
    extension?:         Extension[];
    id?:                string;
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    parameter?:         ParametersParameterArrayArray[];
    resourceType?:      "Parameters";
}

export const isParameters = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is Parameters =>
    isResourceObject(resource) && resource.resourceType ==="Parameters"

export interface ParametersParameterArrayArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name:               string;
    part?:              ParametersParameterArrayArray[];
    resource?:          any[] | boolean | number | number | null | ResourceObject | string;
    value?:             ParametersParameterArrayValue;
}

export interface ParametersParameterArrayValue {
    Address?:             Address;
    Age?:                 Age;
    Annotation?:          Annotation;
    Attachment?:          Attachment;
    base64Binary?:        string;
    boolean?:             boolean;
    canonical?:           string;
    code?:                string;
    CodeableConcept?:     CodeableConcept;
    Coding?:              Coding;
    ContactDetail?:       ContactDetail;
    ContactPoint?:        ContactPoint;
    Contributor?:         Contributor;
    Count?:               Count;
    DataRequirement?:     DataRequirement;
    date?:                string;
    dateTime?:            string;
    decimal?:             number;
    Distance?:            Distance;
    Dosage?:              Dosage;
    Duration?:            Duration;
    Expression?:          Expression;
    extension?:           Extension[];
    HumanName?:           HumanName;
    id?:                  string;
    Identifier?:          Identifier;
    instant?:             Date;
    integer?:             number;
    markdown?:            string;
    modifierExtension?:   Extension[];
    Money?:               Money;
    oid?:                 string;
    ParameterDefinition?: ParameterDefinition;
    Period?:              Period;
    positiveInt?:         number;
    Quantity?:            Quantity;
    Range?:               Range;
    Ratio?:               Ratio;
    Reference?:           Reference;
    RelatedArtifact?:     RelatedArtifact;
    SampledData?:         SampledData;
    Signature?:           Signature;
    string?:              string;
    time?:                string;
    Timing?:              Timing;
    TriggerDefinition?:   TriggerDefinition;
    unsignedInt?:         number;
    uri?:                 string;
    url?:                 string;
    UsageContext?:        UsageContext;
    uuid?:                string;
}

export interface Contributor {
    contact?:           ContactDetail[];
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name:               string;
    type:               string;
}

export interface Count {
    code?:              string;
    comparator?:        string;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    system?:            string;
    unit?:              string;
    value?:             number;
}

export interface Distance {
    code?:              string;
    comparator?:        string;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    system?:            string;
    unit?:              string;
    value?:             number;
}

export interface Dosage {
    additionalInstruction?:    CodeableConcept[];
    asNeeded?:                 DosageAsNeeded;
    doseAndRate?:              DosageDoseAndRateArray[];
    extension?:                Extension[];
    id?:                       string;
    maxDosePerAdministration?: Quantity;
    maxDosePerLifetime?:       Quantity;
    maxDosePerPeriod?:         Ratio;
    meta?:                     Meta;
    method?:                   CodeableConcept;
    modifierExtension?:        Extension[];
    patientInstruction?:       string;
    route?:                    CodeableConcept;
    sequence?:                 number;
    site?:                     CodeableConcept;
    text?:                     string;
    timing?:                   Timing;
}

export interface DosageAsNeeded {
    boolean?:           boolean;
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface DosageDoseAndRateArray {
    dose?:              DosageDoseAndRateArrayDose;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    rate?:              DosageDoseAndRateArrayRate;
    type?:              CodeableConcept;
}

export interface DosageDoseAndRateArrayDose {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    Range?:             Range;
}

export interface DosageDoseAndRateArrayRate {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    Range?:             Range;
    Ratio?:             Ratio;
}

export interface HumanName {
    extension?:         Extension[];
    family?:            string;
    given?:             string[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    period?:            Period;
    prefix?:            string[];
    suffix?:            string[];
    text?:              string;
    use?:               string;
}

export interface ParameterDefinition {
    documentation?:     string;
    extension?:         Extension[];
    id?:                string;
    max?:               string;
    meta?:              Meta;
    min?:               number;
    modifierExtension?: Extension[];
    name?:              string;
    profile?:           string;
    type:               string;
    use:                string;
}

export interface ClinicalImpression {
    assessor?:                 Reference;
    code?:                     CodeableConcept;
    contained?:                Array<any[] | boolean | number | number | null | ResourceObject | string>;
    date?:                     string;
    description?:              string;
    effective?:                ClinicalImpressionEffective;
    encounter?:                Reference;
    extension?:                Extension[];
    finding?:                  ClinicalImpressionFindingArray[];
    id?:                       string;
    identifier?:               Identifier[];
    implicitRules?:            string;
    investigation?:            ClinicalImpressionInvestigationArray[];
    language?:                 string;
    meta?:                     Meta;
    modifierExtension?:        Extension[];
    note?:                     Annotation[];
    previous?:                 Reference;
    problem?:                  Reference[];
    prognosisCodeableConcept?: CodeableConcept[];
    prognosisReference?:       Reference[];
    protocol?:                 string[];
    resourceType?:             "ClinicalImpression";
    status:                    string;
    statusReason?:             CodeableConcept;
    subject:                   Reference;
    summary?:                  string;
    supportingInfo?:           Reference[];
    text?:                     Narrative;
}

export interface ClinicalImpressionEffective {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface ClinicalImpressionFindingArray {
    basis?:               string;
    extension?:           Extension[];
    id?:                  string;
    itemCodeableConcept?: CodeableConcept;
    itemReference?:       Reference;
    modifierExtension?:   Extension[];
}

export interface ClinicalImpressionInvestigationArray {
    code:               CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    item?:              Reference[];
    modifierExtension?: Extension[];
}

export interface FamilyMemberHistory {
    age?:                   FamilyMemberHistoryAge;
    born?:                  FamilyMemberHistoryBorn;
    condition?:             FamilyMemberHistoryConditionArray[];
    contained?:             Array<any[] | boolean | number | number | null | ResourceObject | string>;
    dataAbsentReason?:      CodeableConcept;
    date?:                  string;
    deceased?:              FamilyMemberHistoryDeceased;
    estimatedAge?:          boolean;
    extension?:             Extension[];
    id?:                    string;
    identifier?:            Identifier[];
    implicitRules?:         string;
    instantiatesCanonical?: string[];
    instantiatesUri?:       string[];
    language?:              string;
    meta?:                  Meta;
    modifierExtension?:     Extension[];
    name?:                  string;
    note?:                  Annotation[];
    patient:                Reference;
    reasonCode?:            CodeableConcept[];
    reasonReference?:       Reference[];
    relationship:           CodeableConcept;
    resourceType?:          "FamilyMemberHistory";
    sex?:                   CodeableConcept;
    status:                 string;
    text?:                  Narrative;
}

export const isFamilyMemberHistory = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is FamilyMemberHistory =>
    isResourceObject(resource) && resource.resourceType ==="FamilyMemberHistory"

export interface FamilyMemberHistoryAge {
    Age?:               Age;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Range?:             Range;
    string?:            string;
}

export interface FamilyMemberHistoryBorn {
    date?:              string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
    string?:            string;
}

export interface FamilyMemberHistoryConditionArray {
    code:                CodeableConcept;
    contributedToDeath?: boolean;
    extension?:          Extension[];
    id?:                 string;
    modifierExtension?:  Extension[];
    note?:               Annotation[];
    onset?:              FamilyMemberHistoryConditionArrayOnset;
    outcome?:            CodeableConcept;
}

export interface FamilyMemberHistoryConditionArrayOnset {
    Age?:               Age;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
    Range?:             Range;
    string?:            string;
}

export interface FamilyMemberHistoryDeceased {
    Age?:               Age;
    boolean?:           boolean;
    date?:              string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Range?:             Range;
    string?:            string;
}

export interface MedicinalProductAuthorization {
    contained?:                   Array<any[] | boolean | number | number | null | ResourceObject | string>;
    country?:                     CodeableConcept[];
    dataExclusivityPeriod?:       Period;
    dateOfFirstAuthorization?:    string;
    extension?:                   Extension[];
    holder?:                      Reference;
    id?:                          string;
    identifier?:                  Identifier[];
    implicitRules?:               string;
    internationalBirthDate?:      string;
    jurisdiction?:                CodeableConcept[];
    jurisdictionalAuthorization?: MedicinalProductAuthorizationJurisdictionalAuthorizationArray[];
    language?:                    string;
    legalBasis?:                  CodeableConcept;
    meta?:                        Meta;
    modifierExtension?:           Extension[];
    procedure?:                   MedicinalProductAuthorizationProcedure;
    regulator?:                   Reference;
    resourceType?:                "MedicinalProductAuthorization";
    restoreDate?:                 string;
    status?:                      CodeableConcept;
    statusDate?:                  string;
    subject?:                     Reference;
    text?:                        Narrative;
    validityPeriod?:              Period;
}

export interface MedicinalProductAuthorizationJurisdictionalAuthorizationArray {
    country?:             CodeableConcept;
    extension?:           Extension[];
    id?:                  string;
    identifier?:          Identifier[];
    jurisdiction?:        CodeableConcept[];
    legalStatusOfSupply?: CodeableConcept;
    modifierExtension?:   Extension[];
    validityPeriod?:      Period;
}

export interface MedicinalProductAuthorizationProcedure {
    application?:       MedicinalProductAuthorizationProcedure[];
    date?:              MedicinalProductAuthorizationProcedureDate;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier;
    modifierExtension?: Extension[];
    type:               CodeableConcept;
}

export interface MedicinalProductAuthorizationProcedureDate {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface Concept {
    code:               string;
    definition?:        string;
    deprecated?:        boolean;
    designation?:       ConceptDesignation;
    display?:           string;
    extension?:         Extension[];
    hierarchy?:         string[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    property?:          { [key: string]: any };
    resourceType?:      "Concept";
    system:             string;
    valueset?:          string[];
}

export interface ConceptDesignation {
    definition?:        { [key: string]: any };
    display?:           { [key: string]: any };
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface Manifest {
    bootstrap?:        ManifestBootstrap;
    cache?:            { [key: string]: any };
    config?:           ManifestConfig;
    id:                string;
    import?:           ManifestImport;
    migrations?:       ManifestMigrationsArray[];
    "pre-processors"?: { [key: string]: any };
    resources?:        { [key: string]: any };
    resourceType?:     any;
    version?:          number;
}

export interface ManifestBootstrap {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface ManifestConfig {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface ManifestImport {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface ManifestMigrationsArray {
    id?: string;
}

export interface AidboxConfig {
    auth?:              AidboxConfigAuth;
    box?:               AidboxConfigBox;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    resourceType?:      "AidboxConfig";
}

export interface AidboxConfigAuth {
    baseUrl?:           string;
    extension?:         Extension[];
    keys?:              AidboxConfigAuthKeys;
    modifierExtension?: Extension[];
}

export interface AidboxConfigAuthKeys {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    private?:           string;
    public?:            string;
    secret?:            string;
}

export interface AidboxConfigBox {
    extension?:         Extension[];
    metadata?:          AidboxConfigBoxMetadata;
    modifierExtension?: Extension[];
}

export interface AidboxConfigBoxMetadata {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    name?:              string;
    security?:          AidboxConfigBoxMetadataSecurity;
    service?:           AidboxConfigBoxMetadataService;
    title?:             string;
}

export interface AidboxConfigBoxMetadataSecurity {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface AidboxConfigBoxMetadataService {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface Binary {
    contentType:        string;
    data?:              string;
    extension?:         Extension[];
    id?:                string;
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    resourceType?:      "Binary";
    securityContext?:   Reference;
}

export interface Composition {
    attester?:          CompositionAttesterArray[];
    author:             Reference[];
    BETA_Retired?:      boolean;
    category?:          CodeableConcept[];
    confidentiality?:   string;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    custodian?:         Reference;
    date:               string;
    encounter?:         Reference;
    event?:             CompositionEventArray[];
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier;
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    relatesTo?:         CompositionRelatesToArray[];
    resourceType?:      "Composition";
    section?:           CompositionSectionArraySectionArray[];
    status:             string;
    subject?:           Reference;
    text?:              Narrative;
    title:              string;
    type:               CodeableConcept;
}

export const isComposition = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is Composition =>
    isResourceObject(resource) && resource.resourceType ==="Composition"

export interface CompositionAttesterArray {
    extension?:         Extension[];
    id?:                string;
    mode:               string;
    modifierExtension?: Extension[];
    party?:             Reference;
    time?:              string;
}

export interface CompositionEventArray {
    code?:              CodeableConcept[];
    detail?:            Reference[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    period?:            Period;
}

export interface CompositionRelatesToArray {
    code:               string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    target?:            CompositionRelatesToArrayTarget;
}

export interface CompositionRelatesToArrayTarget {
    extension?:         Extension[];
    Identifier?:        Identifier;
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface CompositionSectionArraySectionArray {
    author?:            Reference[];
    code?:              CodeableConcept;
    emptyReason?:       CodeableConcept;
    entry?:             Reference[];
    extension?:         Extension[];
    focus?:             Reference;
    id?:                string;
    mode?:              string;
    modifierExtension?: Extension[];
    orderedBy?:         CodeableConcept;
    section?:           CompositionSectionArraySectionArray[];
    text?:              Narrative;
    title?:             string;
}

export interface Grant {
    client?:            Reference;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    resourceType?:      "Grant";
    scope?:             string;
    start?:             string;
    user?:              Reference;
}

export interface PractitionerRole {
    active?:                 boolean;
    availabilityExceptions?: string;
    availableTime?:          PractitionerRoleAvailableTimeArray[];
    code?:                   CodeableConcept[];
    contained?:              Array<any[] | boolean | number | number | null | ResourceObject | string>;
    endpoint?:               Reference[];
    extension?:              Extension[];
    healthcareService?:      Reference[];
    id?:                     string;
    identifier?:             Identifier[];
    implicitRules?:          string;
    language?:               string;
    location?:               Reference[];
    meta?:                   Meta;
    modifierExtension?:      Extension[];
    notAvailable?:           PractitionerRoleNotAvailableArray[];
    organization?:           Reference;
    period?:                 Period;
    practitioner?:           Reference;
    resourceType?:           "PractitionerRole";
    specialty?:              CodeableConcept[];
    telecom?:                ContactPoint[];
    text?:                   Narrative;
}

export const isPractitionerRole = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is PractitionerRole =>
    isResourceObject(resource) && resource.resourceType ==="PractitionerRole"

export interface PractitionerRoleAvailableTimeArray {
    allDay?:             boolean;
    availableEndTime?:   string;
    availableStartTime?: string;
    daysOfWeek?:         string[];
    extension?:          Extension[];
    id?:                 string;
    modifierExtension?:  Extension[];
}

export interface PractitionerRoleNotAvailableArray {
    description:        string;
    during?:            Period;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface Module {
    extension?:         Extension[];
    id?:                string;
    meta?:              ModuleMeta;
    modifierExtension?: Extension[];
    module?:            string;
    resourceType?:      "Module";
    version?:           number;
}

export interface ModuleMeta {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    "post-sql"?:        string;
    "pre-sql"?:         string;
}

export interface HealthcareService {
    active?:                 boolean;
    appointmentRequired?:    boolean;
    availabilityExceptions?: string;
    availableTime?:          HealthcareServiceAvailableTimeArray[];
    category?:               CodeableConcept[];
    characteristic?:         CodeableConcept[];
    comment?:                string;
    communication?:          CodeableConcept[];
    contained?:              Array<any[] | boolean | number | number | null | ResourceObject | string>;
    coverageArea?:           Reference[];
    eligibility?:            HealthcareServiceEligibilityArray[];
    endpoint?:               Reference[];
    extension?:              Extension[];
    extraDetails?:           string;
    id?:                     string;
    identifier?:             Identifier[];
    implicitRules?:          string;
    language?:               string;
    location?:               Reference[];
    meta?:                   Meta;
    modifierExtension?:      Extension[];
    name?:                   string;
    notAvailable?:           HealthcareServiceNotAvailableArray[];
    photo?:                  Attachment;
    program?:                CodeableConcept[];
    providedBy?:             Reference;
    referralMethod?:         CodeableConcept[];
    resourceType?:           "HealthcareService";
    serviceProvisionCode?:   CodeableConcept[];
    specialty?:              CodeableConcept[];
    telecom?:                ContactPoint[];
    text?:                   Narrative;
    type?:                   CodeableConcept[];
}

export interface HealthcareServiceAvailableTimeArray {
    allDay?:             boolean;
    availableEndTime?:   string;
    availableStartTime?: string;
    daysOfWeek?:         string[];
    extension?:          Extension[];
    id?:                 string;
    modifierExtension?:  Extension[];
}

export interface HealthcareServiceEligibilityArray {
    code?:              CodeableConcept;
    comment?:           string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface HealthcareServiceNotAvailableArray {
    description:        string;
    during?:            Period;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface Patient {
    active?:               boolean;
    address?:              Address[];
    birthDate?:            string;
    communication?:        PatientCommunicationArray[];
    contact?:              PatientContactArray[];
    contained?:            Array<any[] | boolean | number | number | null | ResourceObject | string>;
    deceased?:             PatientDeceased;
    extension?:            Extension[];
    gender?:               string;
    generalPractitioner?:  Reference[];
    id?:                   string;
    identifier?:           Identifier[];
    implicitRules?:        string;
    language?:             string;
    link?:                 PatientLinkArray[];
    managingOrganization?: Reference;
    maritalStatus?:        CodeableConcept;
    meta?:                 Meta;
    modifierExtension?:    Extension[];
    multipleBirth?:        PatientMultipleBirth;
    name?:                 HumanName[];
    photo?:                Attachment[];
    resourceType?:         "Patient";
    telecom?:              ContactPoint[];
    text?:                 Narrative;
}

export const isPatient = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is Patient =>
    isResourceObject(resource) && resource.resourceType ==="Patient"

export interface PatientCommunicationArray {
    extension?:         Extension[];
    id?:                string;
    language:           CodeableConcept;
    modifierExtension?: Extension[];
    preferred?:         boolean;
}

export interface PatientContactArray {
    address?:           Address;
    extension?:         Extension[];
    gender?:            string;
    id?:                string;
    modifierExtension?: Extension[];
    name?:              HumanName;
    organization?:      Reference;
    period?:            Period;
    relationship?:      CodeableConcept[];
    telecom?:           ContactPoint[];
}

export interface PatientDeceased {
    boolean?:           boolean;
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface PatientLinkArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    other:              Reference;
    type:               string;
}

export interface PatientMultipleBirth {
    boolean?:           boolean;
    extension?:         Extension[];
    integer?:           number;
    modifierExtension?: Extension[];
}

export interface MedicationDispense {
    authorizingPrescription?: Reference[];
    category?:                CodeableConcept;
    contained?:               Array<any[] | boolean | number | number | null | ResourceObject | string>;
    context?:                 Reference;
    daysSupply?:              Quantity;
    destination?:             Reference;
    detectedIssue?:           Reference[];
    dosageInstruction?:       Dosage[];
    eventHistory?:            Reference[];
    extension?:               Extension[];
    id?:                      string;
    identifier?:              Identifier[];
    implicitRules?:           string;
    language?:                string;
    location?:                Reference;
    medication?:              MedicationDispenseMedication;
    meta?:                    Meta;
    modifierExtension?:       Extension[];
    note?:                    Annotation[];
    partOf?:                  Reference[];
    performer?:               MedicationDispensePerformerArray[];
    quantity?:                Quantity;
    receiver?:                Reference[];
    resourceType?:            "MedicationDispense";
    status:                   string;
    statusReason?:            MedicationDispenseStatusReason;
    subject?:                 Reference;
    substitution?:            MedicationDispenseSubstitution;
    supportingInformation?:   Reference[];
    text?:                    Narrative;
    type?:                    CodeableConcept;
    whenHandedOver?:          string;
    whenPrepared?:            string;
}

export const isMedicationDispense = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is MedicationDispense =>
    isResourceObject(resource) && resource.resourceType === "MedicationDispense"

export interface MedicationDispenseMedication {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface MedicationDispensePerformerArray {
    actor:              Reference;
    extension?:         Extension[];
    function?:          CodeableConcept;
    id?:                string;
    modifierExtension?: Extension[];
}

export interface MedicationDispenseStatusReason {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface MedicationDispenseSubstitution {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    reason?:            CodeableConcept[];
    responsibleParty?:  Reference[];
    type?:              CodeableConcept;
    wasSubstituted:     boolean;
}

export interface DeviceUseStatement {
    basedOn?:           Reference[];
    bodySite?:          CodeableConcept;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    derivedFrom?:       Reference[];
    device:             Reference;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    note?:              Annotation[];
    reasonCode?:        CodeableConcept[];
    reasonReference?:   Reference[];
    recordedOn?:        string;
    resourceType?:      "DeviceUseStatement";
    source?:            Reference;
    status:             string;
    subject:            Reference;
    text?:              Narrative;
    timing?:            DeviceUseStatementTiming;
}

export interface DeviceUseStatementTiming {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
    Timing?:            Timing;
}

export interface AidboxJob {
    action?:            any[] | { [key: string]: any } | string;
    at?:                string;
    every?:             number;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    module?:            string;
    resourceType?:      "AidboxJob";
    status?:            StatusObject;
    text?:              string;
    type?:              AidboxJobType;
}

export interface StatusObject {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export enum AidboxJobType {
    EachDay = "each-day",
    Periodic = "periodic",
}

export interface StructureMap {
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    copyright?:         string;
    date?:              string;
    description?:       string;
    experimental?:      boolean;
    extension?:         Extension[];
    group:              StructureMapGroupArray[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    import?:            string[];
    jurisdiction?:      CodeableConcept[];
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name:               string;
    publisher?:         string;
    purpose?:           string;
    resourceType?:      "StructureMap";
    status:             string;
    structure?:         StructureMapStructureArray[];
    text?:              Narrative;
    title?:             string;
    url:                string;
    useContext?:        UsageContext[];
    version?:           string;
}

export interface StructureMapGroupArray {
    documentation?:     string;
    extends?:           string;
    extension?:         Extension[];
    id?:                string;
    input:              StructureMapGroupArrayInputArray[];
    modifierExtension?: Extension[];
    name:               string;
    rule:               StructureMapGroupArrayRuleArrayArrayRuleArray[];
    typeMode:           string;
}

export interface StructureMapGroupArrayInputArray {
    documentation?:     string;
    extension?:         Extension[];
    id?:                string;
    mode:               string;
    modifierExtension?: Extension[];
    name:               string;
    type?:              string;
}

export interface StructureMapGroupArrayRuleArrayArrayRuleArray {
    dependent?:         StructureMapGroupArrayRuleArrayDependentArray[];
    documentation?:     string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name:               string;
    rule?:              StructureMapGroupArrayRuleArrayArrayRuleArray[];
    source:             StructureMapGroupArrayRuleArraySourceArray[];
    target?:            StructureMapGroupArrayRuleArrayTargetArray[];
}

export interface StructureMapGroupArrayRuleArrayDependentArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name:               string;
    variable:           string[];
}

export interface StructureMapGroupArrayRuleArraySourceArray {
    check?:             string;
    condition?:         string;
    context:            string;
    defaultValue?:      StructureMapGroupArrayRuleArraySourceArrayDefaultValue;
    element?:           string;
    extension?:         Extension[];
    id?:                string;
    listMode?:          string;
    logMessage?:        string;
    max?:               string;
    min?:               number;
    modifierExtension?: Extension[];
    type?:              string;
    variable?:          string;
}

export interface StructureMapGroupArrayRuleArraySourceArrayDefaultValue {
    Address?:             Address;
    Age?:                 Age;
    Annotation?:          Annotation;
    Attachment?:          Attachment;
    base64Binary?:        string;
    boolean?:             boolean;
    canonical?:           string;
    code?:                string;
    CodeableConcept?:     CodeableConcept;
    Coding?:              Coding;
    ContactDetail?:       ContactDetail;
    ContactPoint?:        ContactPoint;
    Contributor?:         Contributor;
    Count?:               Count;
    DataRequirement?:     DataRequirement;
    date?:                string;
    dateTime?:            string;
    decimal?:             number;
    Distance?:            Distance;
    Dosage?:              Dosage;
    Duration?:            Duration;
    Expression?:          Expression;
    extension?:           Extension[];
    HumanName?:           HumanName;
    id?:                  string;
    Identifier?:          Identifier;
    instant?:             Date;
    integer?:             number;
    markdown?:            string;
    modifierExtension?:   Extension[];
    Money?:               Money;
    oid?:                 string;
    ParameterDefinition?: ParameterDefinition;
    Period?:              Period;
    positiveInt?:         number;
    Quantity?:            Quantity;
    Range?:               Range;
    Ratio?:               Ratio;
    Reference?:           Reference;
    RelatedArtifact?:     RelatedArtifact;
    SampledData?:         SampledData;
    Signature?:           Signature;
    string?:              string;
    time?:                string;
    Timing?:              Timing;
    TriggerDefinition?:   TriggerDefinition;
    unsignedInt?:         number;
    uri?:                 string;
    url?:                 string;
    UsageContext?:        UsageContext;
    uuid?:                string;
}

export interface StructureMapGroupArrayRuleArrayTargetArray {
    context?:           string;
    contextType?:       string;
    element?:           string;
    extension?:         Extension[];
    id?:                string;
    listMode?:          string[];
    listRuleId?:        string;
    modifierExtension?: Extension[];
    parameter?:         StructureMapGroupArrayRuleArrayTargetArrayParameterArray[];
    transform?:         string;
    variable?:          string;
}

export interface StructureMapGroupArrayRuleArrayTargetArrayParameterArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    value?:             StructureMapGroupArrayRuleArrayTargetArrayParameterArrayValue;
}

export interface StructureMapGroupArrayRuleArrayTargetArrayParameterArrayValue {
    boolean?:           boolean;
    decimal?:           number;
    extension?:         Extension[];
    id?:                string;
    integer?:           number;
    modifierExtension?: Extension[];
    string?:            string;
}

export interface StructureMapStructureArray {
    alias?:             string;
    documentation?:     string;
    extension?:         Extension[];
    id?:                string;
    mode:               string;
    modifierExtension?: Extension[];
    url:                string;
}

export interface ImmunizationEvaluation {
    authority?:         Reference;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    date?:              string;
    description?:       string;
    doseNumber?:        ImmunizationEvaluationDoseNumber;
    doseStatus:         CodeableConcept;
    doseStatusReason?:  CodeableConcept[];
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    immunizationEvent:  Reference;
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    patient:            Reference;
    resourceType?:      "ImmunizationEvaluation";
    series?:            string;
    seriesDoses?:       ImmunizationEvaluationSeriesDoses;
    status:             string;
    targetDisease:      CodeableConcept;
    text?:              Narrative;
}

export interface ImmunizationEvaluationDoseNumber {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    positiveInt?:       number;
    string?:            string;
}

export interface ImmunizationEvaluationSeriesDoses {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    positiveInt?:       number;
    string?:            string;
}

export interface Library {
    approvalDate?:      string;
    author?:            ContactDetail[];
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    content?:           Attachment[];
    copyright?:         string;
    dataRequirement?:   DataRequirement[];
    date?:              string;
    description?:       string;
    editor?:            ContactDetail[];
    effectivePeriod?:   Period;
    endorser?:          ContactDetail[];
    experimental?:      boolean;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    jurisdiction?:      CodeableConcept[];
    language?:          string;
    lastReviewDate?:    string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    parameter?:         ParameterDefinition[];
    publisher?:         string;
    purpose?:           string;
    relatedArtifact?:   RelatedArtifact[];
    resourceType?:      "Library";
    reviewer?:          ContactDetail[];
    status:             string;
    subject?:           LibrarySubject;
    subtitle?:          string;
    text?:              Narrative;
    title?:             string;
    topic?:             CodeableConcept[];
    type:               CodeableConcept;
    url?:               string;
    usage?:             string;
    useContext?:        UsageContext[];
    version?:           string;
}

export interface LibrarySubject {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface Basic {
    author?:            Reference;
    code:               CodeableConcept;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    created?:           string;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    resourceType?:      "Basic";
    subject?:           Reference;
    text?:              Narrative;
}

export interface Slot {
    appointmentType?:   CodeableConcept;
    comment?:           string;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    end:                Date;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    overbooked?:        boolean;
    resourceType?:      "Slot";
    schedule:           Reference;
    serviceCategory?:   CodeableConcept[];
    serviceType?:       CodeableConcept[];
    specialty?:         CodeableConcept[];
    start:              Date;
    status:             string;
    text?:              Narrative;
}

export interface ActivityDefinition {
    approvalDate?:                 string;
    author?:                       ContactDetail[];
    bodySite?:                     CodeableConcept[];
    code?:                         CodeableConcept;
    contact?:                      ContactDetail[];
    contained?:                    Array<any[] | boolean | number | number | null | ResourceObject | string>;
    copyright?:                    string;
    date?:                         string;
    description?:                  string;
    doNotPerform?:                 boolean;
    dosage?:                       Dosage[];
    dynamicValue?:                 ActivityDefinitionDynamicValueArray[];
    editor?:                       ContactDetail[];
    effectivePeriod?:              Period;
    endorser?:                     ContactDetail[];
    experimental?:                 boolean;
    extension?:                    Extension[];
    id?:                           string;
    identifier?:                   Identifier[];
    implicitRules?:                string;
    intent?:                       string;
    jurisdiction?:                 CodeableConcept[];
    kind?:                         string;
    language?:                     string;
    lastReviewDate?:               string;
    library?:                      string[];
    location?:                     Reference;
    meta?:                         Meta;
    modifierExtension?:            Extension[];
    name?:                         string;
    observationRequirement?:       Reference[];
    observationResultRequirement?: Reference[];
    participant?:                  ActivityDefinitionParticipantArray[];
    priority?:                     string;
    product?:                      ActivityDefinitionProduct;
    profile?:                      string;
    publisher?:                    string;
    purpose?:                      string;
    quantity?:                     Quantity;
    relatedArtifact?:              RelatedArtifact[];
    resourceType?:                 "ActivityDefinition";
    reviewer?:                     ContactDetail[];
    specimenRequirement?:          Reference[];
    status:                        string;
    subject?:                      ActivityDefinitionSubject;
    subtitle?:                     string;
    text?:                         Narrative;
    timing?:                       ActivityDefinitionTiming;
    title?:                        string;
    topic?:                        CodeableConcept[];
    transform?:                    string;
    url?:                          string;
    usage?:                        string;
    useContext?:                   UsageContext[];
    version?:                      string;
}

export const isActivityDefinition = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is ActivityDefinition =>
    isResourceObject(resource) && resource.resourceType ==="ActivityDefinition"

export interface ActivityDefinitionDynamicValueArray {
    expression:         Expression;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    path:               string;
}

export interface ActivityDefinitionParticipantArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    role?:              CodeableConcept;
    type:               string;
}

export interface ActivityDefinitionProduct {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface ActivityDefinitionSubject {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface ActivityDefinitionTiming {
    Age?:               Age;
    dateTime?:          string;
    Duration?:          Duration;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
    Range?:             Range;
    Timing?:            Timing;
}

export interface UIHistory {
    command?:           string;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    type?:              UIHistoryType;
    user?:              Reference;
}

export enum UIHistoryType {
    HTTP = "http",
    SQL = "sql",
}

export interface Bundle {
    entry?:             BundleEntryArray[];
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier;
    implicitRules?:     string;
    language?:          string;
    link?:              BundleLinkArray[];
    meta?:              Meta;
    modifierExtension?: Extension[];
    resourceType?:      "Bundle";
    signature?:         Signature;
    timestamp?:         Date;
    total?:             number;
    type:               string;
}

export const isBundle = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is Bundle =>
    isResourceObject(resource) && resource.resourceType ==="Bundle"

export interface BundleEntryArray {
    extension?:         Extension[];
    fullUrl?:           string;
    id?:                string;
    link?:              BundleLinkArray[];
    modifierExtension?: Extension[];
    request?:           BundleEntryArrayRequest;
    resource?:          any[] | boolean | number | number | null | ResourceObject | string;
    response?:          BundleEntryArrayResponse;
    search?:            BundleEntryArraySearch;
}

export interface BundleLinkArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    relation:           string;
    url:                string;
}

export interface BundleEntryArrayRequest {
    extension?:         Extension[];
    id?:                string;
    ifMatch?:           string;
    ifModifiedSince?:   Date;
    ifNoneExist?:       string;
    ifNoneMatch?:       string;
    method:             string;
    modifierExtension?: Extension[];
    url:                string;
}

export interface BundleEntryArrayResponse {
    etag?:              string;
    extension?:         Extension[];
    id?:                string;
    lastModified?:      Date;
    location?:          string;
    modifierExtension?: Extension[];
    outcome?:           any[] | boolean | number | number | null | ResourceObject | string;
    status:             string;
}

export interface BundleEntryArraySearch {
    extension?:         Extension[];
    id?:                string;
    mode?:              string;
    modifierExtension?: Extension[];
    score?:             number;
}

export interface MedicinalProductInteraction {
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    description?:       string;
    effect?:            CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    implicitRules?:     string;
    incidence?:         CodeableConcept;
    interactant?:       MedicinalProductInteractionInteractantArray[];
    language?:          string;
    management?:        CodeableConcept;
    meta?:              Meta;
    modifierExtension?: Extension[];
    resourceType?:      "MedicinalProductInteraction";
    subject?:           Reference[];
    text?:              Narrative;
    type?:              CodeableConcept;
}

export interface MedicinalProductInteractionInteractantArray {
    extension?:         Extension[];
    id?:                string;
    item?:              MedicinalProductInteractionInteractantArrayItem;
    modifierExtension?: Extension[];
}

export interface MedicinalProductInteractionInteractantArrayItem {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface MolecularSequence {
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    coordinateSystem:   number;
    device?:            Reference;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    observedSeq?:       string;
    patient?:           Reference;
    performer?:         Reference;
    pointer?:           Reference[];
    quality?:           MolecularSequenceQualityArray[];
    quantity?:          Quantity;
    readCoverage?:      number;
    referenceSeq?:      MolecularSequenceReferenceSeq;
    repository?:        MolecularSequenceRepositoryArray[];
    resourceType?:      "MolecularSequence";
    specimen?:          Reference;
    structureVariant?:  MolecularSequenceStructureVariantArray[];
    text?:              Narrative;
    type?:              string;
    variant?:           MolecularSequenceVariantArray[];
}

export interface MolecularSequenceQualityArray {
    end?:               number;
    extension?:         Extension[];
    fScore?:            number;
    gtFP?:              number;
    id?:                string;
    method?:            CodeableConcept;
    modifierExtension?: Extension[];
    precision?:         number;
    queryFP?:           number;
    queryTP?:           number;
    recall?:            number;
    roc?:               MolecularSequenceQualityArrayRoc;
    score?:             Quantity;
    standardSequence?:  CodeableConcept;
    start?:             number;
    truthFN?:           number;
    truthTP?:           number;
    type:               string;
}

export interface MolecularSequenceQualityArrayRoc {
    extension?:         Extension[];
    fMeasure?:          number[];
    id?:                string;
    modifierExtension?: Extension[];
    numFN?:             number[];
    numFP?:             number[];
    numTP?:             number[];
    precision?:         number[];
    score?:             number[];
    sensitivity?:       number[];
}

export interface MolecularSequenceReferenceSeq {
    chromosome?:          CodeableConcept;
    extension?:           Extension[];
    genomeBuild?:         string;
    id?:                  string;
    modifierExtension?:   Extension[];
    orientation?:         string;
    referenceSeqId?:      CodeableConcept;
    referenceSeqPointer?: Reference;
    referenceSeqString?:  string;
    strand?:              string;
    windowEnd?:           number;
    windowStart?:         number;
}

export interface MolecularSequenceRepositoryArray {
    datasetId?:         string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name?:              string;
    readsetId?:         string;
    type:               string;
    url?:               string;
    variantsetId?:      string;
}

export interface MolecularSequenceStructureVariantArray {
    exact?:             boolean;
    extension?:         Extension[];
    id?:                string;
    inner?:             MolecularSequenceStructureVariantArrayInner;
    length?:            number;
    modifierExtension?: Extension[];
    outer?:             MolecularSequenceStructureVariantArrayOuter;
    variantType?:       CodeableConcept;
}

export interface MolecularSequenceStructureVariantArrayInner {
    end?:               number;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    start?:             number;
}

export interface MolecularSequenceStructureVariantArrayOuter {
    end?:               number;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    start?:             number;
}

export interface MolecularSequenceVariantArray {
    cigar?:             string;
    end?:               number;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    observedAllele?:    string;
    referenceAllele?:   string;
    start?:             number;
    variantPointer?:    Reference;
}

export interface Specimen {
    accessionIdentifier?: Identifier;
    collection?:          SpecimenCollection;
    condition?:           CodeableConcept[];
    contained?:           Array<any[] | boolean | number | number | null | ResourceObject | string>;
    container?:           SpecimenContainerArray[];
    extension?:           Extension[];
    id?:                  string;
    identifier?:          Identifier[];
    implicitRules?:       string;
    language?:            string;
    meta?:                Meta;
    modifierExtension?:   Extension[];
    note?:                Annotation[];
    parent?:              Reference[];
    processing?:          SpecimenProcessingArray[];
    receivedTime?:        string;
    request?:             Reference[];
    resourceType?:        "Specimen";
    status?:              string;
    subject?:             Reference;
    text?:                Narrative;
    type?:                CodeableConcept;
}

export interface SpecimenCollection {
    bodySite?:          CodeableConcept;
    collected?:         SpecimenCollectionCollected;
    collector?:         Reference;
    duration?:          Duration;
    extension?:         Extension[];
    fastingStatus?:     SpecimenCollectionFastingStatus;
    id?:                string;
    method?:            CodeableConcept;
    modifierExtension?: Extension[];
    quantity?:          Quantity;
}

export interface SpecimenCollectionCollected {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface SpecimenCollectionFastingStatus {
    CodeableConcept?:   CodeableConcept;
    Duration?:          Duration;
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface SpecimenContainerArray {
    additive?:          SpecimenContainerArrayAdditive;
    capacity?:          Quantity;
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    modifierExtension?: Extension[];
    specimenQuantity?:  Quantity;
    type?:              CodeableConcept;
}

export interface SpecimenContainerArrayAdditive {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface SpecimenProcessingArray {
    additive?:          Reference[];
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    procedure?:         CodeableConcept;
    time?:              SpecimenProcessingArrayTime;
}

export interface SpecimenProcessingArrayTime {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface AidboxMigration {
    action?:            any[] | { [key: string]: any } | string;
    dateTime?:          string;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    module?:            string;
    resourceType?:      "AidboxMigration";
    result?:            AidboxMigrationResult;
    status?:            AidboxMigrationStatus;
    text?:              string;
}

export interface AidboxMigrationResult {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export enum AidboxMigrationStatus {
    Done = "done",
    Error = "error",
    Pending = "pending",
}

export interface DiagnosticReport {
    basedOn?:            Reference[];
    category?:           CodeableConcept[];
    code:                CodeableConcept;
    conclusion?:         string;
    conclusionCode?:     CodeableConcept[];
    contained?:          Array<any[] | boolean | number | number | null | ResourceObject | string>;
    effective?:          DiagnosticReportEffective;
    encounter?:          Reference;
    extension?:          Extension[];
    id?:                 string;
    identifier?:         Identifier[];
    imagingStudy?:       Reference[];
    implicitRules?:      string;
    issued?:             Date;
    language?:           string;
    media?:              DiagnosticReportMediaArray[];
    meta?:               Meta;
    modifierExtension?:  Extension[];
    performer?:          Reference[];
    presentedForm?:      Attachment[];
    resourceType?:       "DiagnosticReport";
    result?:             Reference[];
    resultsInterpreter?: Reference[];
    specimen?:           Reference[];
    status:              string;
    subject?:            Reference;
    text?:               Narrative;
}

export const isDiagnosticReport = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is DiagnosticReport =>
    isResourceObject(resource) && resource.resourceType ==="DiagnosticReport"

export interface DiagnosticReportEffective {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface DiagnosticReportMediaArray {
    comment?:           string;
    extension?:         Extension[];
    id?:                string;
    link:               Reference;
    modifierExtension?: Extension[];
}

export interface Subscription {
    channel:            SubscriptionChannel;
    contact?:           ContactPoint[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    criteria:           string;
    end?:               Date;
    error?:             string;
    extension?:         Extension[];
    id?:                string;
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    reason:             string;
    status:             string;
    text?:              Narrative;
}

export interface SubscriptionChannel {
    endpoint?:          string;
    extension?:         Extension[];
    header?:            string[];
    id?:                string;
    modifierExtension?: Extension[];
    payload?:           string;
    type:               string;
}

export interface RequestGroup {
    action?:                RequestGroupActionArrayActionArray[];
    author?:                Reference;
    authoredOn?:            string;
    basedOn?:               Reference[];
    code?:                  CodeableConcept;
    contained?:             Array<any[] | boolean | number | number | null | ResourceObject | string>;
    encounter?:             Reference;
    extension?:             Extension[];
    groupIdentifier?:       Identifier;
    id?:                    string;
    identifier?:            Identifier[];
    implicitRules?:         string;
    instantiatesCanonical?: string[];
    instantiatesUri?:       string[];
    intent:                 string;
    language?:              string;
    meta?:                  Meta;
    modifierExtension?:     Extension[];
    note?:                  Annotation[];
    priority?:              string;
    reasonCode?:            CodeableConcept[];
    reasonReference?:       Reference[];
    replaces?:              Reference[];
    resourceType?:          "RequestGroup";
    status:                 string;
    subject?:               Reference;
    text?:                  Narrative;
}

export interface RequestGroupActionArrayActionArray {
    action?:              RequestGroupActionArrayActionArray[];
    cardinalityBehavior?: string;
    code?:                CodeableConcept[];
    condition?:           RequestGroupActionArrayConditionArray[];
    description?:         string;
    documentation?:       RelatedArtifact[];
    extension?:           Extension[];
    groupingBehavior?:    string;
    id?:                  string;
    modifierExtension?:   Extension[];
    participant?:         Reference[];
    precheckBehavior?:    string;
    prefix?:              string;
    priority?:            string;
    relatedAction?:       RequestGroupActionArrayRelatedActionArray[];
    requiredBehavior?:    string;
    resource?:            Reference;
    selectionBehavior?:   string;
    textEquivalent?:      string;
    timing?:              RequestGroupActionArrayTiming;
    title?:               string;
    type?:                CodeableConcept;
}

export interface RequestGroupActionArrayConditionArray {
    expression?:        Expression;
    extension?:         Extension[];
    id?:                string;
    kind:               string;
    modifierExtension?: Extension[];
}

export interface RequestGroupActionArrayRelatedActionArray {
    actionId:           string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    offset?:            RequestGroupActionArrayRelatedActionArrayOffset;
    relationship:       string;
}

export interface RequestGroupActionArrayRelatedActionArrayOffset {
    Duration?:          Duration;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Range?:             Range;
}

export interface RequestGroupActionArrayTiming {
    Age?:               Age;
    dateTime?:          string;
    Duration?:          Duration;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
    Range?:             Range;
    Timing?:            Timing;
}

export interface Provenance {
    activity?:          CodeableConcept;
    agent:              ProvenanceAgentArray[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    entity?:            ProvenanceEntityArray[];
    extension?:         Extension[];
    id?:                string;
    implicitRules?:     string;
    language?:          string;
    location?:          Reference;
    meta?:              Meta;
    modifierExtension?: Extension[];
    occurred?:          ProvenanceOccurred;
    policy?:            string[];
    reason?:            CodeableConcept[];
    recorded:           Date;
    resourceType?:      "Provenance";
    signature?:         Signature[];
    target:             Reference[];
    text?:              Narrative;
}

export interface ProvenanceAgentArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    onBehalfOf?:        Reference;
    role?:              CodeableConcept[];
    type?:              CodeableConcept;
    who:                Reference;
}

export interface ProvenanceEntityArray {
    agent?:             ProvenanceAgentArray[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    role:               string;
    what:               Reference;
}

export interface ProvenanceOccurred {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface MedicinalProduct {
    additionalMonitoringIndicator?:  CodeableConcept;
    attachedDocument?:               Reference[];
    clinicalTrial?:                  Reference[];
    combinedPharmaceuticalDoseForm?: CodeableConcept;
    contact?:                        Reference[];
    contained?:                      Array<any[] | boolean | number | number | null | ResourceObject | string>;
    crossReference?:                 Identifier[];
    domain?:                         Coding;
    extension?:                      Extension[];
    id?:                             string;
    identifier?:                     Identifier[];
    implicitRules?:                  string;
    language?:                       string;
    legalStatusOfSupply?:            CodeableConcept;
    manufacturingBusinessOperation?: MedicinalProductManufacturingBusinessOperationArray[];
    marketingStatus?:                MarketingStatus[];
    masterFile?:                     Reference[];
    meta?:                           Meta;
    modifierExtension?:              Extension[];
    name:                            MedicinalProductNameArray[];
    packagedMedicinalProduct?:       Reference[];
    paediatricUseIndicator?:         CodeableConcept;
    pharmaceuticalProduct?:          Reference[];
    productClassification?:          CodeableConcept[];
    resourceType?:                   "MedicinalProduct";
    specialDesignation?:             MedicinalProductSpecialDesignationArray[];
    specialMeasures?:                string[];
    text?:                           Narrative;
    type?:                           CodeableConcept;
}

export interface MedicinalProductManufacturingBusinessOperationArray {
    authorisationReferenceNumber?: Identifier;
    confidentialityIndicator?:     CodeableConcept;
    effectiveDate?:                string;
    extension?:                    Extension[];
    id?:                           string;
    manufacturer?:                 Reference[];
    modifierExtension?:            Extension[];
    operationType?:                CodeableConcept;
    regulator?:                    Reference;
}

export interface MarketingStatus {
    country:            CodeableConcept;
    dateRange:          Period;
    extension?:         Extension[];
    id?:                string;
    jurisdiction?:      CodeableConcept;
    meta?:              Meta;
    modifierExtension?: Extension[];
    restoreDate?:       string;
    status:             CodeableConcept;
}

export interface MedicinalProductNameArray {
    countryLanguage?:   MedicinalProductNameArrayCountryLanguageArray[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    namePart?:          MedicinalProductNameArrayNamePartArray[];
    productName:        string;
}

export interface MedicinalProductNameArrayCountryLanguageArray {
    country:            CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    jurisdiction?:      CodeableConcept;
    language:           CodeableConcept;
    modifierExtension?: Extension[];
}

export interface MedicinalProductNameArrayNamePartArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    part:               string;
    type:               Coding;
}

export interface MedicinalProductSpecialDesignationArray {
    date?:              string;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    indication?:        MedicinalProductSpecialDesignationArrayIndication;
    intendedUse?:       CodeableConcept;
    modifierExtension?: Extension[];
    species?:           CodeableConcept;
    status?:            CodeableConcept;
    type?:              CodeableConcept;
}

export interface MedicinalProductSpecialDesignationArrayIndication {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface Mapping {
    body:               MappingBody;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    resourceType?:      "Mapping";
    scopeSchema?:       MappingScopeSchema;
    text?:              MappingText;
}

export interface MappingBody {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface MappingScopeSchema {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface MappingText {
    div?:               string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    status?:            string;
}

export interface ChargeItemDefinition {
    applicability?:     ChargeItemDefinitionApplicabilityArray[];
    approvalDate?:      string;
    code?:              CodeableConcept;
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    copyright?:         string;
    date?:              string;
    derivedFromUri?:    string[];
    description?:       string;
    effectivePeriod?:   Period;
    experimental?:      boolean;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    instance?:          Reference[];
    jurisdiction?:      CodeableConcept[];
    language?:          string;
    lastReviewDate?:    string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    partOf?:            string[];
    propertyGroup?:     ChargeItemDefinitionPropertyGroupArray[];
    publisher?:         string;
    replaces?:          string[];
    resourceType?:      "ChargeItemDefinition";
    status:             string;
    text?:              Narrative;
    title?:             string;
    url:                string;
    useContext?:        UsageContext[];
    version?:           string;
}

export const isChargeItemDefinition = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is ChargeItemDefinition =>
    isResourceObject(resource) && resource.resourceType ==="ChargeItemDefinition"

export interface ChargeItemDefinitionApplicabilityArray {
    description?:       string;
    expression?:        string;
    extension?:         Extension[];
    id?:                string;
    language?:          string;
    modifierExtension?: Extension[];
}

export interface ChargeItemDefinitionPropertyGroupArray {
    applicability?:     ChargeItemDefinitionApplicabilityArray[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    priceComponent?:    ChargeItemDefinitionPropertyGroupArrayPriceComponentArray[];
}

export interface ChargeItemDefinitionPropertyGroupArrayPriceComponentArray {
    amount?:            Money;
    code?:              CodeableConcept;
    extension?:         Extension[];
    factor?:            number;
    id?:                string;
    modifierExtension?: Extension[];
    type:               string;
}

export interface Practitioner {
    active?:            boolean;
    address?:           Address[];
    birthDate?:         string;
    communication?:     CodeableConcept[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    extension?:         Extension[];
    gender?:            string;
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              HumanName[];
    photo?:             Attachment[];
    qualification?:     PractitionerQualificationArray[];
    resourceType?:      "Practitioner";
    telecom?:           ContactPoint[];
    text?:              Narrative;
}

export const isPractitioner = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is Practitioner =>
    isResourceObject(resource) && resource.resourceType ==="Practitioner"

export interface PractitionerQualificationArray {
    code:               CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    issuer?:            Reference;
    modifierExtension?: Extension[];
    period?:            Period;
}

export interface MedicinalProductPackaged {
    batchIdentifier?:        MedicinalProductPackagedBatchIdentifierArray[];
    contained?:              Array<any[] | boolean | number | number | null | ResourceObject | string>;
    description?:            string;
    extension?:              Extension[];
    id?:                     string;
    identifier?:             Identifier[];
    implicitRules?:          string;
    language?:               string;
    legalStatusOfSupply?:    CodeableConcept;
    manufacturer?:           Reference[];
    marketingAuthorization?: Reference;
    marketingStatus?:        MarketingStatus[];
    meta?:                   Meta;
    modifierExtension?:      Extension[];
    packageItem:             MedicinalProductPackagedPackageItemArrayPackageItemArray[];
    resourceType?:           "MedicinalProductPackaged";
    subject?:                Reference[];
    text?:                   Narrative;
}

export interface MedicinalProductPackagedBatchIdentifierArray {
    extension?:          Extension[];
    id?:                 string;
    immediatePackaging?: Identifier;
    modifierExtension?:  Extension[];
    outerPackaging:      Identifier;
}

export interface MedicinalProductPackagedPackageItemArrayPackageItemArray {
    alternateMaterial?:       CodeableConcept[];
    device?:                  Reference[];
    extension?:               Extension[];
    id?:                      string;
    identifier?:              Identifier[];
    manufacturedItem?:        Reference[];
    manufacturer?:            Reference[];
    material?:                CodeableConcept[];
    modifierExtension?:       Extension[];
    otherCharacteristics?:    CodeableConcept[];
    packageItem?:             MedicinalProductPackagedPackageItemArrayPackageItemArray[];
    physicalCharacteristics?: ProdCharacteristic;
    quantity:                 Quantity;
    shelfLifeStorage?:        ProductShelfLife[];
    type:                     CodeableConcept;
}

export interface Flag {
    author?:            Reference;
    category?:          CodeableConcept[];
    code:               CodeableConcept;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    encounter?:         Reference;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    period?:            Period;
    resourceType?:      "Flag";
    status:             string;
    subject:            Reference;
    text?:              Narrative;
}

export interface ExplanationOfBenefit {
    accident?:              ExplanationOfBenefitAccident;
    addItem?:               ExplanationOfBenefitAddItemArray[];
    adjudication?:          ExplanationOfBenefitAdjudicationArray[];
    benefitBalance?:        ExplanationOfBenefitBenefitBalanceArray[];
    benefitPeriod?:         Period;
    billablePeriod?:        Period;
    careTeam?:              ExplanationOfBenefitCareTeamArray[];
    claim?:                 Reference;
    claimResponse?:         Reference;
    contained?:             Array<any[] | boolean | number | number | null | ResourceObject | string>;
    created:                string;
    diagnosis?:             ExplanationOfBenefitDiagnosisArray[];
    disposition?:           string;
    enterer?:               Reference;
    extension?:             Extension[];
    facility?:              Reference;
    form?:                  Attachment;
    formCode?:              CodeableConcept;
    fundsReserve?:          CodeableConcept;
    fundsReserveRequested?: CodeableConcept;
    id?:                    string;
    identifier?:            Identifier[];
    implicitRules?:         string;
    insurance:              ExplanationOfBenefitInsuranceArray[];
    insurer:                Reference;
    item?:                  ExplanationOfBenefitItemArray[];
    language?:              string;
    meta?:                  Meta;
    modifierExtension?:     Extension[];
    originalPrescription?:  Reference;
    outcome:                string;
    patient:                Reference;
    payee?:                 ExplanationOfBenefitPayee;
    payment?:               ExplanationOfBenefitPayment;
    preAuthRef?:            string[];
    preAuthRefPeriod?:      Period[];
    precedence?:            number;
    prescription?:          Reference;
    priority?:              CodeableConcept;
    procedure?:             ExplanationOfBenefitProcedureArray[];
    processNote?:           ExplanationOfBenefitProcessNoteArray[];
    provider:               Reference;
    referral?:              Reference;
    related?:               ExplanationOfBenefitRelatedArray[];
    resourceType?:          "ExplanationOfBenefit";
    status:                 string;
    subType?:               CodeableConcept;
    supportingInfo?:        ExplanationOfBenefitSupportingInfoArray[];
    text?:                  Narrative;
    total?:                 ExplanationOfBenefitTotalArray[];
    type:                   CodeableConcept;
    use:                    string;
}

export interface ExplanationOfBenefitAccident {
    date?:              string;
    extension?:         Extension[];
    id?:                string;
    location?:          ExplanationOfBenefitAccidentLocation;
    modifierExtension?: Extension[];
    type?:              CodeableConcept;
}

export interface ExplanationOfBenefitAccidentLocation {
    Address?:           Address;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface ExplanationOfBenefitAddItemArray {
    adjudication?:      ExplanationOfBenefitAdjudicationArray[];
    bodySite?:          CodeableConcept;
    detail?:            ExplanationOfBenefitAddItemArrayDetailArray[];
    detailSequence?:    number[];
    extension?:         Extension[];
    factor?:            number;
    id?:                string;
    itemSequence?:      number[];
    location?:          ExplanationOfBenefitAddItemArrayLocation;
    modifier?:          CodeableConcept[];
    modifierExtension?: Extension[];
    net?:               Money;
    noteNumber?:        number[];
    productOrService:   CodeableConcept;
    programCode?:       CodeableConcept[];
    provider?:          Reference[];
    quantity?:          Quantity;
    serviced?:          ExplanationOfBenefitAddItemArrayServiced;
    subDetailSequence?: number[];
    subSite?:           CodeableConcept[];
    unitPrice?:         Money;
}

export interface ExplanationOfBenefitAdjudicationArray {
    amount?:            Money;
    category:           CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    reason?:            CodeableConcept;
    value?:             number;
}

export interface ExplanationOfBenefitAddItemArrayDetailArray {
    adjudication?:      ExplanationOfBenefitAdjudicationArray[];
    extension?:         Extension[];
    factor?:            number;
    id?:                string;
    modifier?:          CodeableConcept[];
    modifierExtension?: Extension[];
    net?:               Money;
    noteNumber?:        number[];
    productOrService:   CodeableConcept;
    quantity?:          Quantity;
    subDetail?:         ExplanationOfBenefitAddItemArrayDetailArraySubDetailArray[];
    unitPrice?:         Money;
}

export interface ExplanationOfBenefitAddItemArrayDetailArraySubDetailArray {
    adjudication?:      ExplanationOfBenefitAdjudicationArray[];
    extension?:         Extension[];
    factor?:            number;
    id?:                string;
    modifier?:          CodeableConcept[];
    modifierExtension?: Extension[];
    net?:               Money;
    noteNumber?:        number[];
    productOrService:   CodeableConcept;
    quantity?:          Quantity;
    unitPrice?:         Money;
}

export interface ExplanationOfBenefitAddItemArrayLocation {
    Address?:           Address;
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface ExplanationOfBenefitAddItemArrayServiced {
    date?:              string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface ExplanationOfBenefitBenefitBalanceArray {
    category:           CodeableConcept;
    description?:       string;
    excluded?:          boolean;
    extension?:         Extension[];
    financial?:         ExplanationOfBenefitBenefitBalanceArrayFinancialArray[];
    id?:                string;
    modifierExtension?: Extension[];
    name?:              string;
    network?:           CodeableConcept;
    term?:              CodeableConcept;
    unit?:              CodeableConcept;
}

export interface ExplanationOfBenefitBenefitBalanceArrayFinancialArray {
    allowed?:           ExplanationOfBenefitBenefitBalanceArrayFinancialArrayAllowed;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    type:               CodeableConcept;
    used?:              ExplanationOfBenefitBenefitBalanceArrayFinancialArrayUsed;
}

export interface ExplanationOfBenefitBenefitBalanceArrayFinancialArrayAllowed {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Money?:             Money;
    string?:            string;
    unsignedInt?:       number;
}

export interface ExplanationOfBenefitBenefitBalanceArrayFinancialArrayUsed {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Money?:             Money;
    unsignedInt?:       number;
}

export interface ExplanationOfBenefitCareTeamArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    provider:           Reference;
    qualification?:     CodeableConcept;
    responsible?:       boolean;
    role?:              CodeableConcept;
    sequence:           number;
}

export interface ExplanationOfBenefitDiagnosisArray {
    diagnosis?:         ExplanationOfBenefitDiagnosisArrayDiagnosis;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    onAdmission?:       CodeableConcept;
    packageCode?:       CodeableConcept;
    sequence:           number;
    type?:              CodeableConcept[];
}

export interface ExplanationOfBenefitDiagnosisArrayDiagnosis {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface ExplanationOfBenefitInsuranceArray {
    coverage:           Reference;
    extension?:         Extension[];
    focal:              boolean;
    id?:                string;
    modifierExtension?: Extension[];
    preAuthRef?:        string[];
}

export interface ExplanationOfBenefitItemArray {
    adjudication?:        ExplanationOfBenefitAdjudicationArray[];
    bodySite?:            CodeableConcept;
    careTeamSequence?:    number[];
    category?:            CodeableConcept;
    detail?:              ExplanationOfBenefitItemArrayDetailArray[];
    diagnosisSequence?:   number[];
    encounter?:           Reference[];
    extension?:           Extension[];
    factor?:              number;
    id?:                  string;
    informationSequence?: number[];
    location?:            ExplanationOfBenefitItemArrayLocation;
    modifier?:            CodeableConcept[];
    modifierExtension?:   Extension[];
    net?:                 Money;
    noteNumber?:          number[];
    procedureSequence?:   number[];
    productOrService:     CodeableConcept;
    programCode?:         CodeableConcept[];
    quantity?:            Quantity;
    revenue?:             CodeableConcept;
    sequence:             number;
    serviced?:            ExplanationOfBenefitItemArrayServiced;
    subSite?:             CodeableConcept[];
    udi?:                 Reference[];
    unitPrice?:           Money;
}

export interface ExplanationOfBenefitItemArrayDetailArray {
    adjudication?:      ExplanationOfBenefitAdjudicationArray[];
    category?:          CodeableConcept;
    extension?:         Extension[];
    factor?:            number;
    id?:                string;
    modifier?:          CodeableConcept[];
    modifierExtension?: Extension[];
    net?:               Money;
    noteNumber?:        number[];
    productOrService:   CodeableConcept;
    programCode?:       CodeableConcept[];
    quantity?:          Quantity;
    revenue?:           CodeableConcept;
    sequence:           number;
    subDetail?:         ExplanationOfBenefitItemArrayDetailArraySubDetailArray[];
    udi?:               Reference[];
    unitPrice?:         Money;
}

export interface ExplanationOfBenefitItemArrayDetailArraySubDetailArray {
    adjudication?:      ExplanationOfBenefitAdjudicationArray[];
    category?:          CodeableConcept;
    extension?:         Extension[];
    factor?:            number;
    id?:                string;
    modifier?:          CodeableConcept[];
    modifierExtension?: Extension[];
    net?:               Money;
    noteNumber?:        number[];
    productOrService:   CodeableConcept;
    programCode?:       CodeableConcept[];
    quantity?:          Quantity;
    revenue?:           CodeableConcept;
    sequence:           number;
    udi?:               Reference[];
    unitPrice?:         Money;
}

export interface ExplanationOfBenefitItemArrayLocation {
    Address?:           Address;
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface ExplanationOfBenefitItemArrayServiced {
    date?:              string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface ExplanationOfBenefitPayee {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    party?:             Reference;
    type?:              CodeableConcept;
}

export interface ExplanationOfBenefitPayment {
    adjustment?:        Money;
    adjustmentReason?:  CodeableConcept;
    amount?:            Money;
    date?:              string;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier;
    modifierExtension?: Extension[];
    type?:              CodeableConcept;
}

export interface ExplanationOfBenefitProcedureArray {
    date?:              string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    procedure?:         ExplanationOfBenefitProcedureArrayProcedure;
    sequence:           number;
    type?:              CodeableConcept[];
    udi?:               Reference[];
}

export interface ExplanationOfBenefitProcedureArrayProcedure {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface ExplanationOfBenefitProcessNoteArray {
    extension?:         Extension[];
    id?:                string;
    language?:          CodeableConcept;
    modifierExtension?: Extension[];
    number?:            number;
    text?:              string;
    type?:              string;
}

export interface ExplanationOfBenefitRelatedArray {
    claim?:             Reference;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    reference?:         Identifier;
    relationship?:      CodeableConcept;
}

export interface ExplanationOfBenefitSupportingInfoArray {
    category:           CodeableConcept;
    code?:              CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    reason?:            Coding;
    sequence:           number;
    timing?:            ExplanationOfBenefitSupportingInfoArrayTiming;
    value?:             ExplanationOfBenefitSupportingInfoArrayValue;
}

export interface ExplanationOfBenefitSupportingInfoArrayTiming {
    date?:              string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface ExplanationOfBenefitSupportingInfoArrayValue {
    Attachment?:        Attachment;
    boolean?:           boolean;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    Reference?:         Reference;
    string?:            string;
}

export interface ExplanationOfBenefitTotalArray {
    amount:             Money;
    category:           CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface Linkage {
    active?:            boolean;
    author?:            Reference;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    extension?:         Extension[];
    id?:                string;
    implicitRules?:     string;
    item:               LinkageItemArray[];
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    resourceType?:      "Linkage";
    text?:              Narrative;
}

export interface LinkageItemArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    resource:           Reference;
    type:               string;
}

export interface OperationOutcome {
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    extension?:         Extension[];
    id?:                string;
    implicitRules?:     string;
    issue:              OperationOutcomeIssueArray[];
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    resourceType?:      "OperationOutcome";
    text?:              Narrative;
}

export const isOperationOutcome = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is OperationOutcome =>
    isResourceObject(resource) && resource.resourceType ==="OperationOutcome"

export interface OperationOutcomeIssueArray {
    code:               string;
    details?:           CodeableConcept;
    diagnostics?:       string;
    expression?:        string[];
    extension?:         Extension[];
    id?:                string;
    location?:          string[];
    modifierExtension?: Extension[];
    severity:           string;
}

export interface MedicinalProductPharmaceutical {
    administrableDoseForm: CodeableConcept;
    characteristics?:      MedicinalProductPharmaceuticalCharacteristicsArray[];
    contained?:            Array<any[] | boolean | number | number | null | ResourceObject | string>;
    device?:               Reference[];
    extension?:            Extension[];
    id?:                   string;
    identifier?:           Identifier[];
    implicitRules?:        string;
    ingredient?:           Reference[];
    language?:             string;
    meta?:                 Meta;
    modifierExtension?:    Extension[];
    resourceType?:         "MedicinalProductPharmaceutical";
    routeOfAdministration: MedicinalProductPharmaceuticalRouteOfAdministrationArray[];
    text?:                 Narrative;
    unitOfPresentation?:   CodeableConcept;
}

export interface MedicinalProductPharmaceuticalCharacteristicsArray {
    code:               CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    status?:            CodeableConcept;
}

export interface MedicinalProductPharmaceuticalRouteOfAdministrationArray {
    code:                       CodeableConcept;
    extension?:                 Extension[];
    firstDose?:                 Quantity;
    id?:                        string;
    maxDosePerDay?:             Quantity;
    maxDosePerTreatmentPeriod?: Ratio;
    maxSingleDose?:             Quantity;
    maxTreatmentPeriod?:        Duration;
    modifierExtension?:         Extension[];
    targetSpecies?:             MedicinalProductPharmaceuticalRouteOfAdministrationArrayTargetSpeciesArray[];
}

export interface MedicinalProductPharmaceuticalRouteOfAdministrationArrayTargetSpeciesArray {
    code:               CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    withdrawalPeriod?:  MedicinalProductPharmaceuticalRouteOfAdministrationArrayTargetSpeciesArrayWithdrawalPeriodArray[];
}

export interface MedicinalProductPharmaceuticalRouteOfAdministrationArrayTargetSpeciesArrayWithdrawalPeriodArray {
    extension?:             Extension[];
    id?:                    string;
    modifierExtension?:     Extension[];
    supportingInformation?: string;
    tissue:                 CodeableConcept;
    value:                  Quantity;
}

export interface Immunization {
    contained?:          Array<any[] | boolean | number | number | null | ResourceObject | string>;
    doseQuantity?:       Quantity;
    education?:          ImmunizationEducationArray[];
    encounter?:          Reference;
    expirationDate?:     string;
    extension?:          Extension[];
    fundingSource?:      CodeableConcept;
    id?:                 string;
    identifier?:         Identifier[];
    implicitRules?:      string;
    isSubpotent?:        boolean;
    language?:           string;
    location?:           Reference;
    lotNumber?:          string;
    manufacturer?:       Reference;
    meta?:               Meta;
    modifierExtension?:  Extension[];
    note?:               Annotation[];
    occurrence?:         ImmunizationOccurrence;
    patient:             Reference;
    performer?:          ImmunizationPerformerArray[];
    primarySource?:      boolean;
    programEligibility?: CodeableConcept[];
    protocolApplied?:    ImmunizationProtocolAppliedArray[];
    reaction?:           ImmunizationReactionArray[];
    reasonCode?:         CodeableConcept[];
    reasonReference?:    Reference[];
    recorded?:           string;
    reportOrigin?:       CodeableConcept;
    resourceType?:       "Immunization";
    route?:              CodeableConcept;
    site?:               CodeableConcept;
    status:              string;
    statusReason?:       CodeableConcept;
    subpotentReason?:    CodeableConcept[];
    text?:               Narrative;
    vaccineCode:         CodeableConcept;
}

export interface ImmunizationEducationArray {
    documentType?:      string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    presentationDate?:  string;
    publicationDate?:   string;
    reference?:         string;
}

export interface ImmunizationOccurrence {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    string?:            string;
}

export interface ImmunizationPerformerArray {
    actor:              Reference;
    extension?:         Extension[];
    function?:          CodeableConcept;
    id?:                string;
    modifierExtension?: Extension[];
}

export interface ImmunizationProtocolAppliedArray {
    authority?:         Reference;
    doseNumber?:        ImmunizationProtocolAppliedArrayDoseNumber;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    series?:            string;
    seriesDoses?:       ImmunizationProtocolAppliedArraySeriesDoses;
    targetDisease?:     CodeableConcept[];
}

export interface ImmunizationProtocolAppliedArrayDoseNumber {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    positiveInt?:       number;
    string?:            string;
}

export interface ImmunizationProtocolAppliedArraySeriesDoses {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    positiveInt?:       number;
    string?:            string;
}

export interface ImmunizationReactionArray {
    date?:              string;
    detail?:            Reference;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    reported?:          boolean;
}

export interface UISnippet {
    command?:           string;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    title?:             string;
    type?:              UIHistoryType;
    user?:              Reference;
}

export interface MedicationKnowledge {
    administrationGuidelines?:   MedicationKnowledgeAdministrationGuidelinesArray[];
    amount?:                     Quantity;
    associatedMedication?:       Reference[];
    BETA_StrengthCombinations?:  MedicationKnowledgeStrengthCombinations[];
    catalogHeader?:              Reference[];
    code?:                       CodeableConcept;
    contained?:                  Array<any[] | boolean | number | number | null | ResourceObject | string>;
    contraindication?:           Reference[];
    cost?:                       MedicationKnowledgeCostArray[];
    doseForm?:                   CodeableConcept;
    drugCharacteristic?:         MedicationKnowledgeDrugCharacteristicArray[];
    extension?:                  Extension[];
    id?:                         string;
    implicitRules?:              string;
    ingredient?:                 MedicationKnowledgeIngredientArray[];
    intendedRoute?:              CodeableConcept[];
    kinetics?:                   MedicationKnowledgeKineticsArray[];
    language?:                   string;
    manufacturer?:               Reference;
    medicineClassification?:     MedicationKnowledgeMedicineClassificationArray[];
    meta?:                       Meta;
    modifierExtension?:          Extension[];
    monitoringProgram?:          MedicationKnowledgeMonitoringProgramArray[];
    monograph?:                  MedicationKnowledgeMonographArray[];
    packaging?:                  MedicationKnowledgePackaging;
    preparationInstruction?:     string;
    productType?:                CodeableConcept[];
    regulatory?:                 MedicationKnowledgeRegulatoryArray[];
    relatedMedicationKnowledge?: MedicationKnowledgeRelatedMedicationKnowledgeArray[];
    resourceType?:               "MedicationKnowledge";
    status?:                     string;
    synonym?:                    string[];
    text?:                       Narrative;
}

export const isMedicationKnowledge = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is MedicationKnowledge =>
    isResourceObject(resource) && resource.resourceType === "MedicationKnowledge"

export interface MedicationKnowledgeAdministrationGuidelinesArray {
    dosage?:                 MedicationKnowledgeAdministrationGuidelinesArrayDosageArray[];
    extension?:              Extension[];
    id?:                     string;
    indication?:             MedicationKnowledgeAdministrationGuidelinesArrayIndication;
    modifierExtension?:      Extension[];
    patientCharacteristics?: MedicationKnowledgeAdministrationGuidelinesArrayPatientCharacteristicsArray[];
}

export interface MedicationKnowledgeAdministrationGuidelinesArrayDosageArray {
    dosage:             Dosage[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    type:               CodeableConcept;
}

export interface MedicationKnowledgeAdministrationGuidelinesArrayIndication {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface MedicationKnowledgeAdministrationGuidelinesArrayPatientCharacteristicsArray {
    characteristic?:    MedicationKnowledgeAdministrationGuidelinesArrayPatientCharacteristicsArrayCharacteristic;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    value?:             string[];
}

export interface MedicationKnowledgeAdministrationGuidelinesArrayPatientCharacteristicsArrayCharacteristic {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
}

export interface MedicationKnowledgeStrengthCombinations {
    ingredients?:       StrengthCombinationsIngredients[];
}

export interface StrengthCombinationsIngredients {
    name?:               string;
    value?:              Ratio;
}

export interface MedicationKnowledgeCostArray {
    cost:               Money;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    source?:            string;
    type:               CodeableConcept;
}

export interface MedicationKnowledgeDrugCharacteristicArray {
    valueAttachment?:   any;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    type?:              CodeableConcept;
    value?:             MedicationKnowledgeDrugCharacteristicArrayValue;
}

export interface MedicationKnowledgeDrugCharacteristicArrayValue {
    base64Binary?:      string;
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    string?:            string;
}

export interface MedicationKnowledgeIngredientArray {
    extension?:         Extension[];
    id?:                string;
    isActive?:          boolean;
    item?:              MedicationKnowledgeIngredientArrayItem;
    modifierExtension?: Extension[];
    strength?:          Ratio;
}

export interface MedicationKnowledgeIngredientArrayItem {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface MedicationKnowledgeKineticsArray {
    areaUnderCurve?:    Quantity[];
    extension?:         Extension[];
    halfLifePeriod?:    Duration;
    id?:                string;
    lethalDose50?:      Quantity[];
    modifierExtension?: Extension[];
}

export interface MedicationKnowledgeMedicineClassificationArray {
    classification?:    CodeableConcept[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    type:               CodeableConcept;
}

export interface MedicationKnowledgeMonitoringProgramArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name?:              string;
    type?:              CodeableConcept;
}

export interface MedicationKnowledgeMonographArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    source?:            Reference;
    type?:              CodeableConcept;
}

export interface MedicationKnowledgePackaging {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    quantity?:          Quantity;
    type?:              CodeableConcept;
}

export interface MedicationKnowledgeRegulatoryArray {
    code?:               CodeableConcept;
    extension?:          Extension[];
    id?:                 string;
    maxDispense?:        MedicationKnowledgeRegulatoryArrayMaxDispense;
    modifierExtension?:  Extension[];
    regulatoryAuthority: Reference;
    schedule?:           MedicationKnowledgeRegulatoryArrayScheduleArray[];
    substitution?:       MedicationKnowledgeRegulatoryArraySubstitutionArray[];
}

export interface MedicationKnowledgeRegulatoryArrayMaxDispense {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    period?:            Duration;
    quantity:           Quantity;
}

export interface MedicationKnowledgeRegulatoryArrayScheduleArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    schedule:           CodeableConcept;
}

export interface MedicationKnowledgeRegulatoryArraySubstitutionArray {
    allowed:            boolean;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    type:               CodeableConcept;
}

export interface MedicationKnowledgeRelatedMedicationKnowledgeArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    reference:          Reference[];
    type:               CodeableConcept;
}

export interface AidboxSubscription {
    action?:            any[] | { [key: string]: any } | string;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    module?:            string;
    resources?:         string[];
    resourceType?:      "AidboxSubscription";
    type?:              AidboxSubscriptionType;
}

export enum AidboxSubscriptionType {
    Async = "async",
    Sync = "sync",
}

export interface User {
    active?:            boolean;
    addresses?:         UserAddressesArray[];
    costCenter?:        string;
    data?:              UserData;
    department?:        string;
    division?:          string;
    email?:             string;
    emails?:            UserEmailsArray[];
    employeeNumber?:    string;
    entitlements?:      UserEntitlementsArray[];
    extension?:         Extension[];
    gender?:            string;
    id?:                string;
    identifier?:        Identifier[];
    ims?:               UserImsArray[];
    link?:              UserLinkArray[];
    locale?:            string;
    manager?:           Reference;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              UserName;
    organization?:      Reference;
    password?:          string;
    phoneNumber?:       string;
    phoneNumbers?:      UserPhoneNumbersArray[];
    photo?:             string;
    photos?:            UserPhotosArray[];
    preferredLanguage?: string;
    profileUrl?:        string;
    resourceType?:      "User";
    roles?:             UserRolesArray[];
    timezone?:          string;
    title?:             string;
    userName?:          string;
    userType?:          string;
    x509Certificates?:  UserX509CertificatesArray[];
}

export interface UserAddressesArray {
    country?:           string;
    extension?:         Extension[];
    formatted?:         string;
    locality?:          string;
    modifierExtension?: Extension[];
    postalCode?:        string;
    region?:            string;
    streetAddress?:     string;
    type?:              string;
}

export interface UserData {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface UserEmailsArray {
    display?:           string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    primary?:           boolean;
    type?:              string;
    value?:             string;
}

export interface UserEntitlementsArray {
    display?:           string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    primary?:           boolean;
    type?:              string;
    value?:             string;
}

export interface UserImsArray {
    display?:           string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    primary?:           boolean;
    type?:              string;
    value?:             string;
}

export interface UserLinkArray {
    extension?:         Extension[];
    link?:              Reference;
    modifierExtension?: Extension[];
    type?:              string;
}

export interface UserName {
    extension?:         Extension[];
    familyName?:        string;
    formatted?:         string;
    givenName?:         string;
    honorificPrefix?:   string;
    honorificSuffix?:   string;
    middleName?:        string;
    modifierExtension?: Extension[];
}

export interface UserPhoneNumbersArray {
    display?:           string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    primary?:           boolean;
    type?:              string;
    value?:             string;
}

export interface UserPhotosArray {
    display?:           string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    primary?:           boolean;
    type?:              string;
    value?:             string;
}

export interface UserRolesArray {
    display?:           string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    primary?:           boolean;
    type?:              string;
    value?:             string;
}

export interface UserX509CertificatesArray {
    display?:           string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    primary?:           boolean;
    type?:              string;
    value?:             string;
}

export interface ResearchSubject {
    actualArm?:         string;
    assignedArm?:       string;
    consent?:           Reference;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    individual:         Reference;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    period?:            Period;
    resourceType?:      "ResearchSubject";
    status:             string;
    study:              Reference;
    text?:              Narrative;
}

export interface MedicinalProductIndication {
    comorbidity?:             CodeableConcept[];
    contained?:               Array<any[] | boolean | number | number | null | ResourceObject | string>;
    diseaseStatus?:           CodeableConcept;
    diseaseSymptomProcedure?: CodeableConcept;
    duration?:                Quantity;
    extension?:               Extension[];
    id?:                      string;
    implicitRules?:           string;
    intendedEffect?:          CodeableConcept;
    language?:                string;
    meta?:                    Meta;
    modifierExtension?:       Extension[];
    otherTherapy?:            MedicinalProductIndicationOtherTherapyArray[];
    population?:              Population[];
    resourceType?:            "MedicinalProductIndication";
    subject?:                 Reference[];
    text?:                    Narrative;
    undesirableEffect?:       Reference[];
}

export interface MedicinalProductIndicationOtherTherapyArray {
    extension?:              Extension[];
    id?:                     string;
    medication?:             MedicinalProductIndicationOtherTherapyArrayMedication;
    modifierExtension?:      Extension[];
    therapyRelationshipType: CodeableConcept;
}

export interface MedicinalProductIndicationOtherTherapyArrayMedication {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface BulkImportStatus {
    contentEncoding?:   ContentEncoding;
    extension?:         Extension[];
    id?:                string;
    inputFormat?:       InputFormat;
    inputs?:            BulkImportStatusInputsArray[];
    meta?:              Meta;
    mode?:              Mode;
    modifierExtension?: Extension[];
    resourceType?:      "BulkImportStatus";
    source?:            string;
    status?:            BulkImportStatusStatus;
    storageDetail?:     BulkImportStatusStorageDetail;
    time?:              BulkImportStatusTime;
    type?:              BulkImportStatusType;
}

export enum ContentEncoding {
    Gzip = "gzip",
}

export enum InputFormat {
    ApplicationFhirNdjson = "application/fhir+ndjson",
}

export interface BulkImportStatusInputsArray {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    status?:            string;
    time?:              number;
    url:                string;
}

export enum Mode {
    Bulk = "bulk",
    Transaction = "transaction",
}

export enum BulkImportStatusStatus {
    Active = "active",
    Cancelled = "cancelled",
    Failed = "failed",
    Finished = "finished",
}

export interface BulkImportStatusStorageDetail {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    type?:              StorageDetailType;
}

export enum StorageDetailType {
    File = "file",
    HTTPS = "https",
}

export interface BulkImportStatusTime {
    end?:               string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    start?:             string;
}

export enum BulkImportStatusType {
    Aidbox = "aidbox",
    Fhir = "fhir",
}

export interface PaymentNotice {
    amount:             Money;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    created:            string;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    payee?:             Reference;
    payment:            Reference;
    paymentDate?:       string;
    paymentStatus?:     CodeableConcept;
    provider?:          Reference;
    recipient:          Reference;
    request?:           Reference;
    resourceType?:      "PaymentNotice";
    response?:          Reference;
    status:             string;
    text?:              Narrative;
}

export interface NamingSystem {
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    date:               string;
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    implicitRules?:     string;
    jurisdiction?:      CodeableConcept[];
    kind:               string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name:               string;
    publisher?:         string;
    resourceType?:      "NamingSystem";
    responsible?:       string;
    status:             string;
    text?:              Narrative;
    type?:              CodeableConcept;
    uniqueId:           NamingSystemUniqueIDArray[];
    usage?:             string;
    useContext?:        UsageContext[];
}

export interface NamingSystemUniqueIDArray {
    comment?:           string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    period?:            Period;
    preferred?:         boolean;
    type:               string;
    value:              string;
}

export interface MedicationStatement {
    basedOn?:           Reference[];
    category?:          CodeableConcept;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    context?:           Reference;
    dateAsserted?:      string;
    derivedFrom?:       Reference[];
    dosage?:            Dosage[];
    effective?:         MedicationStatementEffective;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    informationSource?: Reference;
    language?:          string;
    medication?:        MedicationStatementMedication;
    meta?:              Meta;
    modifierExtension?: Extension[];
    note?:              Annotation[];
    partOf?:            Reference[];
    reasonCode?:        CodeableConcept[];
    reasonReference?:   Reference[];
    resourceType?:      "MedicationStatement";
    status:             string;
    statusReason?:      CodeableConcept[];
    subject:            Reference;
    text?:              Narrative;
}

export interface MedicationStatementEffective {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface MedicationStatementMedication {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface EnrollmentResponse {
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    created?:           string;
    disposition?:       string;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    organization?:      Reference;
    outcome?:           string;
    request?:           Reference;
    requestProvider?:   Reference;
    resourceType?:      "EnrollmentResponse";
    status?:            string;
    text?:              Narrative;
}

export interface NutritionOrder {
    allergyIntolerance?:     Reference[];
    contained?:              Array<any[] | boolean | number | number | null | ResourceObject | string>;
    dateTime:                string;
    encounter?:              Reference;
    enteralFormula?:         NutritionOrderEnteralFormula;
    excludeFoodModifier?:    CodeableConcept[];
    extension?:              Extension[];
    foodPreferenceModifier?: CodeableConcept[];
    id?:                     string;
    identifier?:             Identifier[];
    implicitRules?:          string;
    instantiates?:           string[];
    instantiatesCanonical?:  string[];
    instantiatesUri?:        string[];
    intent:                  string;
    language?:               string;
    meta?:                   Meta;
    modifierExtension?:      Extension[];
    note?:                   Annotation[];
    oralDiet?:               NutritionOrderOralDiet;
    orderer?:                Reference;
    patient:                 Reference;
    resourceType?:           "NutritionOrder";
    status:                  string;
    supplement?:             NutritionOrderSupplementArray[];
    text?:                   Narrative;
}

export interface NutritionOrderEnteralFormula {
    additiveProductName?:       string;
    additiveType?:              CodeableConcept;
    administration?:            NutritionOrderEnteralFormulaAdministrationArray[];
    administrationInstruction?: string;
    baseFormulaProductName?:    string;
    baseFormulaType?:           CodeableConcept;
    caloricDensity?:            Quantity;
    extension?:                 Extension[];
    id?:                        string;
    maxVolumeToDeliver?:        Quantity;
    modifierExtension?:         Extension[];
    routeofAdministration?:     CodeableConcept;
}

export interface NutritionOrderEnteralFormulaAdministrationArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    quantity?:          Quantity;
    rate?:              NutritionOrderEnteralFormulaAdministrationArrayRate;
    schedule?:          Timing;
}

export interface NutritionOrderEnteralFormulaAdministrationArrayRate {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    Ratio?:             Ratio;
}

export interface NutritionOrderOralDiet {
    extension?:            Extension[];
    fluidConsistencyType?: CodeableConcept[];
    id?:                   string;
    instruction?:          string;
    modifierExtension?:    Extension[];
    nutrient?:             NutritionOrderOralDietNutrientArray[];
    schedule?:             Timing[];
    texture?:              NutritionOrderOralDietTextureArray[];
    type?:                 CodeableConcept[];
}

export interface NutritionOrderOralDietNutrientArray {
    amount?:            Quantity;
    extension?:         Extension[];
    id?:                string;
    modifier?:          CodeableConcept;
    modifierExtension?: Extension[];
}

export interface NutritionOrderOralDietTextureArray {
    extension?:         Extension[];
    foodType?:          CodeableConcept;
    id?:                string;
    modifier?:          CodeableConcept;
    modifierExtension?: Extension[];
}

export interface NutritionOrderSupplementArray {
    extension?:         Extension[];
    id?:                string;
    instruction?:       string;
    modifierExtension?: Extension[];
    productName?:       string;
    quantity?:          Quantity;
    schedule?:          Timing[];
    type?:              CodeableConcept;
}

export interface Questionnaire {
    approvalDate?:      string;
    code?:              Coding[];
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    copyright?:         string;
    date?:              string;
    derivedFrom?:       string[];
    description?:       string;
    effectivePeriod?:   Period;
    experimental?:      boolean;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    item?:              QuestionnaireItemArrayItemArray[];
    jurisdiction?:      CodeableConcept[];
    language?:          string;
    lastReviewDate?:    string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    publisher?:         string;
    purpose?:           string;
    resourceType?:      "Questionnaire";
    status:             string;
    subjectType?:       string[];
    text?:              Narrative;
    title?:             string;
    url?:               string;
    useContext?:        UsageContext[];
    version?:           string;
}

export interface QuestionnaireItemArrayItemArray {
    answerOption?:      QuestionnaireItemArrayAnswerOptionArray[];
    answerValueSet?:    string;
    code?:              Coding[];
    definition?:        string;
    enableBehavior?:    string;
    enableWhen?:        QuestionnaireItemArrayEnableWhenArray[];
    extension?:         Extension[];
    id?:                string;
    initial?:           QuestionnaireItemArrayInitialArray[];
    item?:              QuestionnaireItemArrayItemArray[];
    linkId?:            string;
    maxLength?:         number;
    modifierExtension?: Extension[];
    prefix?:            string;
    readOnly?:          boolean;
    repeats?:           boolean;
    required?:          boolean;
    text?:              string;
    type:               string;
}

export interface QuestionnaireItemArrayAnswerOptionArray {
    extension?:         Extension[];
    id?:                string;
    initialSelected?:   boolean;
    modifierExtension?: Extension[];
    value?:             QuestionnaireItemArrayAnswerOptionArrayValue;
}

export interface QuestionnaireItemArrayAnswerOptionArrayValue {
    Coding?:            Coding;
    date?:              string;
    extension?:         Extension[];
    integer?:           number;
    modifierExtension?: Extension[];
    Reference?:         Reference;
    string?:            string;
    time?:              string;
}

export interface QuestionnaireItemArrayEnableWhenArray {
    answer?:            QuestionnaireItemArrayEnableWhenArrayAnswer;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    operator:           string;
    question:           string;
}

export interface QuestionnaireItemArrayEnableWhenArrayAnswer {
    boolean?:           boolean;
    Coding?:            Coding;
    date?:              string;
    dateTime?:          string;
    decimal?:           number;
    extension?:         Extension[];
    integer?:           number;
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    Reference?:         Reference;
    string?:            string;
    time?:              string;
}

export interface QuestionnaireItemArrayInitialArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    value?:             QuestionnaireItemArrayInitialArrayValue;
}

export interface QuestionnaireItemArrayInitialArrayValue {
    Attachment?:        Attachment;
    boolean?:           boolean;
    Coding?:            Coding;
    date?:              string;
    dateTime?:          string;
    decimal?:           number;
    extension?:         Extension[];
    integer?:           number;
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    Reference?:         Reference;
    string?:            string;
    time?:              string;
    uri?:               string;
}

export interface Account {
    BETA_CreditCard?:           AccountBETACreditCardArray[];
    BETA_defaultCreditCard?:    string;
    contained?:                 Array<any[] | boolean | number | number | null | ResourceObject | string>;
    coverage?:                  AccountCoverageArray[];
    description?:               string;
    extension?:                 Extension[];
    guarantor?:                 AccountGuarantorArray[];
    id?:                        string;
    identifier?:                Identifier[];
    implicitRules?:             string;
    language?:                  string;
    meta?:                      Meta;
    modifierExtension?:         Extension[];
    name?:                      string;
    owner?:                     Reference;
    partOf?:                    Reference;
    paymentMethods?:            Reference[];
    resourceType?:              "Account";
    servicePeriod?:             Period;
    status:                     string;
    subject?:                   Reference[];
    text?:                      Narrative;
    type?:                      CodeableConcept;
}

export interface AccountBETACreditCardArray {
    billingAddress?:    Address;
    cardHolderName?:    string;
    cvv?:               string;
    expirationMonth?:   string;
    expirationYear?:    string;
    last4Digits?:       string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    number?:            string;
    text?:              string;
    token?:             Identifier[];
    type?:              string;
}

export interface AccountCoverageArray {
    coverage:           Reference;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    priority?:          number;
}

export interface AccountGuarantorArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    onHold?:            boolean;
    party:              Reference;
    period?:            Period;
}

export interface EventDefinition {
    approvalDate?:      string;
    author?:            ContactDetail[];
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    copyright?:         string;
    date?:              string;
    description?:       string;
    editor?:            ContactDetail[];
    effectivePeriod?:   Period;
    endorser?:          ContactDetail[];
    experimental?:      boolean;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    jurisdiction?:      CodeableConcept[];
    language?:          string;
    lastReviewDate?:    string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    publisher?:         string;
    purpose?:           string;
    relatedArtifact?:   RelatedArtifact[];
    resourceType?:      "EventDefinition";
    reviewer?:          ContactDetail[];
    status:             string;
    subject?:           EventDefinitionSubject;
    subtitle?:          string;
    text?:              Narrative;
    title?:             string;
    topic?:             CodeableConcept[];
    trigger:            TriggerDefinition[];
    url?:               string;
    usage?:             string;
    useContext?:        UsageContext[];
    version?:           string;
}

export interface EventDefinitionSubject {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface MedicinalProductUndesirableEffect {
    classification?:         CodeableConcept;
    contained?:              Array<any[] | boolean | number | number | null | ResourceObject | string>;
    extension?:              Extension[];
    frequencyOfOccurrence?:  CodeableConcept;
    id?:                     string;
    implicitRules?:          string;
    language?:               string;
    meta?:                   Meta;
    modifierExtension?:      Extension[];
    population?:             Population[];
    resourceType?:           "MedicinalProductUndesirableEffect";
    subject?:                Reference[];
    symptomConditionEffect?: CodeableConcept;
    text?:                   Narrative;
}

export interface SubstanceSpecification {
    code?:                 SubstanceSpecificationCodeArray[];
    comment?:              string;
    contained?:            Array<any[] | boolean | number | number | null | ResourceObject | string>;
    description?:          string;
    domain?:               CodeableConcept;
    extension?:            Extension[];
    id?:                   string;
    identifier?:           Identifier;
    implicitRules?:        string;
    language?:             string;
    meta?:                 Meta;
    modifierExtension?:    Extension[];
    moiety?:               SubstanceSpecificationMoietyArray[];
    molecularWeight?:      SubstanceSpecificationStructureIsotopeArrayMolecularWeight[];
    name?:                 SubstanceSpecificationNameArrayArray[];
    nucleicAcid?:          Reference;
    polymer?:              Reference;
    property?:             SubstanceSpecificationPropertyArray[];
    protein?:              Reference;
    referenceInformation?: Reference;
    relationship?:         SubstanceSpecificationRelationshipArray[];
    resourceType?:         "SubstanceSpecification";
    source?:               Reference[];
    sourceMaterial?:       Reference;
    status?:               CodeableConcept;
    structure?:            SubstanceSpecificationStructure;
    text?:                 Narrative;
    type?:                 CodeableConcept;
}

export interface SubstanceSpecificationCodeArray {
    code?:              CodeableConcept;
    comment?:           string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    source?:            Reference[];
    status?:            CodeableConcept;
    statusDate?:        string;
}

export interface SubstanceSpecificationMoietyArray {
    amount?:            SubstanceSpecificationMoietyArrayAmount;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier;
    modifierExtension?: Extension[];
    molecularFormula?:  string;
    name?:              string;
    opticalActivity?:   CodeableConcept;
    role?:              CodeableConcept;
    stereochemistry?:   CodeableConcept;
}

export interface SubstanceSpecificationMoietyArrayAmount {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    string?:            string;
}

export interface SubstanceSpecificationStructureIsotopeArrayMolecularWeight {
    amount?:            Quantity;
    extension?:         Extension[];
    id?:                string;
    method?:            CodeableConcept;
    modifierExtension?: Extension[];
    type?:              CodeableConcept;
}

export interface SubstanceSpecificationNameArrayArray {
    domain?:            CodeableConcept[];
    extension?:         Extension[];
    id?:                string;
    jurisdiction?:      CodeableConcept[];
    language?:          CodeableConcept[];
    modifierExtension?: Extension[];
    name:               string;
    official?:          SubstanceSpecificationNameArrayOfficialArray[];
    preferred?:         boolean;
    source?:            Reference[];
    status?:            CodeableConcept;
    synonym?:           SubstanceSpecificationNameArrayArray[];
    translation?:       SubstanceSpecificationNameArrayArray[];
    type?:              CodeableConcept;
}

export interface SubstanceSpecificationNameArrayOfficialArray {
    authority?:         CodeableConcept;
    date?:              string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    status?:            CodeableConcept;
}

export interface SubstanceSpecificationPropertyArray {
    amount?:            SubstanceSpecificationPropertyArrayAmount;
    category?:          CodeableConcept;
    code?:              CodeableConcept;
    definingSubstance?: SubstanceSpecificationPropertyArrayDefiningSubstance;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    parameters?:        string;
}

export interface SubstanceSpecificationPropertyArrayAmount {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    string?:            string;
}

export interface SubstanceSpecificationPropertyArrayDefiningSubstance {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface SubstanceSpecificationRelationshipArray {
    amount?:              SubstanceSpecificationRelationshipArrayAmount;
    amountRatioLowLimit?: Ratio;
    amountType?:          CodeableConcept;
    extension?:           Extension[];
    id?:                  string;
    isDefining?:          boolean;
    modifierExtension?:   Extension[];
    relationship?:        CodeableConcept;
    source?:              Reference[];
    substance?:           SubstanceSpecificationRelationshipArraySubstance;
}

export interface SubstanceSpecificationRelationshipArrayAmount {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    Range?:             Range;
    Ratio?:             Ratio;
    string?:            string;
}

export interface SubstanceSpecificationRelationshipArraySubstance {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface SubstanceSpecificationStructure {
    extension?:                Extension[];
    id?:                       string;
    isotope?:                  SubstanceSpecificationStructureIsotopeArray[];
    modifierExtension?:        Extension[];
    molecularFormula?:         string;
    molecularFormulaByMoiety?: string;
    molecularWeight?:          SubstanceSpecificationStructureIsotopeArrayMolecularWeight;
    opticalActivity?:          CodeableConcept;
    representation?:           SubstanceSpecificationStructureRepresentationArray[];
    source?:                   Reference[];
    stereochemistry?:          CodeableConcept;
}

export interface SubstanceSpecificationStructureIsotopeArray {
    extension?:         Extension[];
    halfLife?:          Quantity;
    id?:                string;
    identifier?:        Identifier;
    modifierExtension?: Extension[];
    molecularWeight?:   SubstanceSpecificationStructureIsotopeArrayMolecularWeight;
    name?:              CodeableConcept;
    substitution?:      CodeableConcept;
}

export interface SubstanceSpecificationStructureRepresentationArray {
    attachment?:        Attachment;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    representation?:    string;
    type?:              CodeableConcept;
}

export interface CommunicationRequest {
    about?:             Reference[];
    authoredOn?:        string;
    basedOn?:           Reference[];
    category?:          CodeableConcept[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    doNotPerform?:      boolean;
    encounter?:         Reference;
    extension?:         Extension[];
    groupIdentifier?:   Identifier;
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    medium?:            CodeableConcept[];
    meta?:              Meta;
    modifierExtension?: Extension[];
    note?:              Annotation[];
    occurrence?:        CommunicationRequestOccurrence;
    payload?:           CommunicationRequestPayloadArray[];
    priority?:          string;
    reasonCode?:        CodeableConcept[];
    reasonReference?:   Reference[];
    recipient?:         Reference[];
    replaces?:          Reference[];
    requester?:         Reference;
    resourceType?:      "CommunicationRequest";
    sender?:            Reference;
    status:             string;
    statusReason?:      CodeableConcept;
    subject?:           Reference;
    text?:              Narrative;
    templateId:         Coding
    templateData:       Coding[]
}

export const isCommunicationRequest = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is CommunicationRequest =>
    isResourceObject(resource) && resource.resourceType ==="CommunicationRequest"

export interface CommunicationRequestOccurrence {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface CommunicationRequestPayloadArray {
    content?:           CommunicationRequestPayloadArrayContent;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface CommunicationRequestPayloadArrayContent {
    Attachment?:        Attachment;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
    string?:            string;
}

export interface SpecimenDefinition {
    collection?:         CodeableConcept[];
    contained?:          Array<any[] | boolean | number | number | null | ResourceObject | string>;
    extension?:          Extension[];
    id?:                 string;
    identifier?:         Identifier;
    implicitRules?:      string;
    language?:           string;
    meta?:               Meta;
    modifierExtension?:  Extension[];
    patientPreparation?: CodeableConcept[];
    resourceType?:       "SpecimenDefinition";
    text?:               Narrative;
    timeAspect?:         string;
    typeCollected?:      CodeableConcept;
    typeTested?:         SpecimenDefinitionTypeTestedArray[];
}

export interface SpecimenDefinitionTypeTestedArray {
    container?:          SpecimenDefinitionTypeTestedArrayContainer;
    extension?:          Extension[];
    handling?:           SpecimenDefinitionTypeTestedArrayHandlingArray[];
    id?:                 string;
    isDerived?:          boolean;
    modifierExtension?:  Extension[];
    preference:          string;
    rejectionCriterion?: CodeableConcept[];
    requirement?:        string;
    retentionTime?:      Duration;
    type?:               CodeableConcept;
}

export interface SpecimenDefinitionTypeTestedArrayContainer {
    additive?:          SpecimenDefinitionTypeTestedArrayContainerAdditiveArray[];
    cap?:               CodeableConcept;
    capacity?:          Quantity;
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    material?:          CodeableConcept;
    minimumVolume?:     SpecimenDefinitionTypeTestedArrayContainerMinimumVolume;
    modifierExtension?: Extension[];
    preparation?:       string;
    type?:              CodeableConcept;
}

export interface SpecimenDefinitionTypeTestedArrayContainerAdditiveArray {
    additive?:          SpecimenDefinitionTypeTestedArrayContainerAdditiveArrayAdditive;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface SpecimenDefinitionTypeTestedArrayContainerAdditiveArrayAdditive {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface SpecimenDefinitionTypeTestedArrayContainerMinimumVolume {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    string?:            string;
}

export interface SpecimenDefinitionTypeTestedArrayHandlingArray {
    extension?:            Extension[];
    id?:                   string;
    instruction?:          string;
    maxDuration?:          Duration;
    modifierExtension?:    Extension[];
    temperatureQualifier?: CodeableConcept;
    temperatureRange?:     Range;
}

export interface VerificationResult {
    attestation?:       VerificationResultAttestation;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    extension?:         Extension[];
    failureAction?:     CodeableConcept;
    frequency?:         Timing;
    id?:                string;
    implicitRules?:     string;
    language?:          string;
    lastPerformed?:     string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    need?:              CodeableConcept;
    nextScheduled?:     string;
    primarySource?:     VerificationResultPrimarySourceArray[];
    resourceType?:      "VerificationResult";
    status:             string;
    statusDate?:        string;
    target?:            Reference[];
    targetLocation?:    string[];
    text?:              Narrative;
    validationProcess?: CodeableConcept[];
    validationType?:    CodeableConcept;
    validator?:         VerificationResultValidatorArray[];
}

export interface VerificationResultAttestation {
    communicationMethod?:       CodeableConcept;
    date?:                      string;
    extension?:                 Extension[];
    id?:                        string;
    modifierExtension?:         Extension[];
    onBehalfOf?:                Reference;
    proxyIdentityCertificate?:  string;
    proxySignature?:            Signature;
    sourceIdentityCertificate?: string;
    sourceSignature?:           Signature;
    who?:                       Reference;
}

export interface VerificationResultPrimarySourceArray {
    canPushUpdates?:      CodeableConcept;
    communicationMethod?: CodeableConcept[];
    extension?:           Extension[];
    id?:                  string;
    modifierExtension?:   Extension[];
    pushTypeAvailable?:   CodeableConcept[];
    type?:                CodeableConcept[];
    validationDate?:      string;
    validationStatus?:    CodeableConcept;
    who?:                 Reference;
}

export interface VerificationResultValidatorArray {
    attestationSignature?: Signature;
    extension?:            Extension[];
    id?:                   string;
    identityCertificate?:  string;
    modifierExtension?:    Extension[];
    organization:          Reference;
}

export interface DocumentManifest {
    author?:            Reference[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    content:            Reference[];
    created?:           string;
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    masterIdentifier?:  Identifier;
    meta?:              Meta;
    modifierExtension?: Extension[];
    recipient?:         Reference[];
    related?:           DocumentManifestRelatedArray[];
    resourceType?:      "DocumentManifest";
    source?:            string;
    status:             string;
    subject?:           Reference;
    text?:              Narrative;
    type?:              CodeableConcept;
}

export interface DocumentManifestRelatedArray {
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier;
    modifierExtension?: Extension[];
    ref?:               Reference;
}

export interface Task {
    authoredOn?:            string;
    basedOn?:               Reference[];
    businessStatus?:        CodeableConcept;
    code?:                  CodeableConcept;
    contained?:             Array<any[] | boolean | number | number | null | ResourceObject | string>;
    dependsOn?:             Reference[];
    description?:           string;
    encounter?:             Reference;
    executionPeriod?:       Period;
    extension?:             Extension[];
    focus?:                 Reference;
    for?:                   Reference;
    groupIdentifier?:       Identifier;
    id?:                    string;
    identifier?:            Identifier[];
    implicitRules?:         string;
    input?:                 TaskInputArray[];
    instantiatesCanonical?: string;
    instantiatesUri?:       string;
    insurance?:             Reference[];
    intent:                 string;
    language?:              string;
    lastModified?:          string;
    location?:              Reference;
    meta?:                  Meta;
    modifierExtension?:     Extension[];
    note?:                  Annotation[];
    output?:                TaskOutputArray[];
    owner?:                 Reference;
    partOf?:                Reference[];
    performerType?:         CodeableConcept[];
    priority?:              string;
    reasonCode?:            CodeableConcept;
    reasonReference?:       Reference;
    relevantHistory?:       Reference[];
    requester?:             Reference;
    resourceType?:          "Task";
    restriction?:           TaskRestriction;
    status:                 string;
    statusReason?:          CodeableConcept;
    text?:                  Narrative;
}

export const isTask = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is Task =>
    isResourceObject(resource) && resource.resourceType === "Task"

export interface TaskInputArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    type:               CodeableConcept;
    value?:             TaskInputArrayValue;
}

export interface TaskInputArrayValue {
    Address?:             Address;
    Age?:                 Age;
    Annotation?:          Annotation;
    Attachment?:          Attachment;
    base64Binary?:        string;
    boolean?:             boolean;
    canonical?:           string;
    code?:                string;
    CodeableConcept?:     CodeableConcept;
    Coding?:              Coding;
    ContactDetail?:       ContactDetail;
    ContactPoint?:        ContactPoint;
    Contributor?:         Contributor;
    Count?:               Count;
    DataRequirement?:     DataRequirement;
    date?:                string;
    dateTime?:            string;
    decimal?:             number;
    Distance?:            Distance;
    Dosage?:              Dosage;
    Duration?:            Duration;
    Expression?:          Expression;
    extension?:           Extension[];
    HumanName?:           HumanName;
    id?:                  string;
    Identifier?:          Identifier;
    instant?:             Date;
    integer?:             number;
    markdown?:            string;
    modifierExtension?:   Extension[];
    Money?:               Money;
    oid?:                 string;
    ParameterDefinition?: ParameterDefinition;
    Period?:              Period;
    positiveInt?:         number;
    Quantity?:            Quantity;
    Range?:               Range;
    Ratio?:               Ratio;
    Reference?:           Reference;
    RelatedArtifact?:     RelatedArtifact;
    SampledData?:         SampledData;
    Signature?:           Signature;
    string?:              string;
    time?:                string;
    Timing?:              Timing;
    TriggerDefinition?:   TriggerDefinition;
    unsignedInt?:         number;
    uri?:                 string;
    url?:                 string;
    UsageContext?:        UsageContext;
    uuid?:                string;
}

export interface TaskOutputArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    type:               CodeableConcept;
    value?:             TaskOutputArrayValue;
}

export interface TaskOutputArrayValue {
    Address?:             Address;
    Age?:                 Age;
    Annotation?:          Annotation;
    Attachment?:          Attachment;
    base64Binary?:        string;
    boolean?:             boolean;
    canonical?:           string;
    code?:                string;
    CodeableConcept?:     CodeableConcept;
    Coding?:              Coding;
    ContactDetail?:       ContactDetail;
    ContactPoint?:        ContactPoint;
    Contributor?:         Contributor;
    Count?:               Count;
    DataRequirement?:     DataRequirement;
    date?:                string;
    dateTime?:            string;
    decimal?:             number;
    Distance?:            Distance;
    Dosage?:              Dosage;
    Duration?:            Duration;
    Expression?:          Expression;
    extension?:           Extension[];
    HumanName?:           HumanName;
    id?:                  string;
    Identifier?:          Identifier;
    instant?:             Date;
    integer?:             number;
    markdown?:            string;
    modifierExtension?:   Extension[];
    Money?:               Money;
    oid?:                 string;
    ParameterDefinition?: ParameterDefinition;
    Period?:              Period;
    positiveInt?:         number;
    Quantity?:            Quantity;
    Range?:               Range;
    Ratio?:               Ratio;
    Reference?:           Reference;
    RelatedArtifact?:     RelatedArtifact;
    SampledData?:         SampledData;
    Signature?:           Signature;
    string?:              string;
    time?:                string;
    Timing?:              Timing;
    TriggerDefinition?:   TriggerDefinition;
    unsignedInt?:         number;
    uri?:                 string;
    url?:                 string;
    UsageContext?:        UsageContext;
    uuid?:                string;
}

export interface TaskRestriction {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    period?:            Period;
    recipient?:         Reference[];
    repetitions?:       number;
}

export interface RiskEvidenceSynthesis {
    approvalDate?:      string;
    author?:            ContactDetail[];
    certainty?:         RiskEvidenceSynthesisCertaintyArray[];
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    copyright?:         string;
    date?:              string;
    description?:       string;
    editor?:            ContactDetail[];
    effectivePeriod?:   Period;
    endorser?:          ContactDetail[];
    exposure?:          Reference;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    jurisdiction?:      CodeableConcept[];
    language?:          string;
    lastReviewDate?:    string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    note?:              Annotation[];
    outcome:            Reference;
    population:         Reference;
    publisher?:         string;
    relatedArtifact?:   RelatedArtifact[];
    resourceType?:      "RiskEvidenceSynthesis";
    reviewer?:          ContactDetail[];
    riskEstimate?:      RiskEvidenceSynthesisRiskEstimate;
    sampleSize?:        RiskEvidenceSynthesisSampleSize;
    status:             string;
    studyType?:         CodeableConcept;
    synthesisType?:     CodeableConcept;
    text?:              Narrative;
    title?:             string;
    topic?:             CodeableConcept[];
    url?:               string;
    useContext?:        UsageContext[];
    version?:           string;
}

export interface RiskEvidenceSynthesisCertaintyArray {
    certaintySubcomponent?: RiskEvidenceSynthesisCertaintyArrayCertaintySubcomponentArray[];
    extension?:             Extension[];
    id?:                    string;
    modifierExtension?:     Extension[];
    note?:                  Annotation[];
    rating?:                CodeableConcept[];
}

export interface RiskEvidenceSynthesisCertaintyArrayCertaintySubcomponentArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    note?:              Annotation[];
    rating?:            CodeableConcept[];
    type?:              CodeableConcept;
}

export interface RiskEvidenceSynthesisRiskEstimate {
    denominatorCount?:  number;
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    numeratorCount?:    number;
    precisionEstimate?: RiskEvidenceSynthesisRiskEstimatePrecisionEstimateArray[];
    type?:              CodeableConcept;
    unitOfMeasure?:     CodeableConcept;
    value?:             number;
}

export interface RiskEvidenceSynthesisRiskEstimatePrecisionEstimateArray {
    extension?:         Extension[];
    from?:              number;
    id?:                string;
    level?:             number;
    modifierExtension?: Extension[];
    to?:                number;
    type?:              CodeableConcept;
}

export interface RiskEvidenceSynthesisSampleSize {
    description?:          string;
    extension?:            Extension[];
    id?:                   string;
    modifierExtension?:    Extension[];
    numberOfParticipants?: number;
    numberOfStudies?:      number;
}

export interface DomainResource {
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    extension?:         Extension[];
    id?:                string;
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    resourceType?:      string;
    text?:              Narrative;
}

export interface ValueSet {
    compose?:           ValueSetCompose;
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    copyright?:         string;
    date?:              string;
    description?:       string;
    expansion?:         ValueSetExpansion;
    experimental?:      boolean;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    immutable?:         boolean;
    implicitRules?:     string;
    jurisdiction?:      CodeableConcept[];
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    publisher?:         string;
    purpose?:           string;
    resourceType?:      "ValueSet";
    status:             string;
    text?:              Narrative;
    title?:             string;
    url?:               string;
    useContext?:        UsageContext[];
    version?:           string;
}

export interface ValueSetCompose {
    exclude?:           ValueSetComposeCludeArray[];
    extension?:         Extension[];
    id?:                string;
    inactive?:          boolean;
    include:            ValueSetComposeCludeArray[];
    lockedDate?:        string;
    modifierExtension?: Extension[];
}

export interface ValueSetComposeCludeArray {
    concept?:           ValueSetComposeIncludeArrayConceptArray[];
    extension?:         Extension[];
    filter?:            ValueSetComposeIncludeArrayFilterArray[];
    id?:                string;
    modifierExtension?: Extension[];
    system?:            string;
    valueSet?:          string[];
    version?:           string;
}

export interface ValueSetComposeIncludeArrayConceptArray {
    code:               string;
    designation?:       ValueSetArrayDesignationArray[];
    display?:           string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface ValueSetArrayDesignationArray {
    extension?:         Extension[];
    id?:                string;
    language?:          string;
    modifierExtension?: Extension[];
    use?:               Coding;
    value:              string;
}

export interface ValueSetComposeIncludeArrayFilterArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    op:                 string;
    property:           string;
    value:              string;
}

export interface ValueSetExpansion {
    contains?:          ValueSetExpansionContainsArrayContainsArray[];
    extension?:         Extension[];
    id?:                string;
    identifier?:        string;
    modifierExtension?: Extension[];
    offset?:            number;
    parameter?:         ValueSetExpansionParameterArray[];
    timestamp:          string;
    total?:             number;
}

export interface ValueSetExpansionContainsArrayContainsArray {
    abstract?:          boolean;
    code?:              string;
    contains?:          ValueSetExpansionContainsArrayContainsArray[];
    designation?:       ValueSetArrayDesignationArray[];
    display?:           string;
    extension?:         Extension[];
    id?:                string;
    inactive?:          boolean;
    modifierExtension?: Extension[];
    system?:            string;
    version?:           string;
}

export interface ValueSetExpansionParameterArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name:               string;
    value?:             ValueSetExpansionParameterArrayValue;
}

export interface ValueSetExpansionParameterArrayValue {
    boolean?:           boolean;
    code?:              string;
    dateTime?:          string;
    decimal?:           number;
    extension?:         Extension[];
    integer?:           number;
    modifierExtension?: Extension[];
    string?:            string;
    uri?:               string;
}

export interface Claim {
    accident?:             ClaimAccident;
    billablePeriod?:       Period;
    careTeam?:             ClaimCareTeamArray[];
    contained?:            Array<any[] | boolean | number | number | null | ResourceObject | string>;
    created:               string;
    diagnosis?:            ClaimDiagnosisArray[];
    enterer?:              Reference;
    extension?:            Extension[];
    facility?:             Reference;
    fundsReserve?:         CodeableConcept;
    id?:                   string;
    identifier?:           Identifier[];
    implicitRules?:        string;
    insurance:             ClaimInsuranceArray[];
    insurer?:              Reference;
    item?:                 ClaimItemArray[];
    language?:             string;
    meta?:                 Meta;
    modifierExtension?:    Extension[];
    originalPrescription?: Reference;
    patient:               Reference;
    payee?:                ClaimPayee;
    prescription?:         Reference;
    priority:              CodeableConcept;
    procedure?:            ClaimProcedureArray[];
    provider:              Reference;
    referral?:             Reference;
    related?:              ClaimRelatedArray[];
    resourceType?:         "Claim";
    status:                string;
    subType?:              CodeableConcept;
    supportingInfo?:       ClaimSupportingInfoArray[];
    text?:                 Narrative;
    total?:                Money;
    type:                  CodeableConcept;
    use:                   string;
}

export interface ClaimAccident {
    date:               string;
    extension?:         Extension[];
    id?:                string;
    location?:          ClaimAccidentLocation;
    modifierExtension?: Extension[];
    type?:              CodeableConcept;
}

export interface ClaimAccidentLocation {
    Address?:           Address;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface ClaimCareTeamArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    provider:           Reference;
    qualification?:     CodeableConcept;
    responsible?:       boolean;
    role?:              CodeableConcept;
    sequence:           number;
}

export interface ClaimDiagnosisArray {
    diagnosis?:         ClaimDiagnosisArrayDiagnosis;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    onAdmission?:       CodeableConcept;
    packageCode?:       CodeableConcept;
    sequence:           number;
    type?:              CodeableConcept[];
}

export interface ClaimDiagnosisArrayDiagnosis {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface ClaimInsuranceArray {
    businessArrangement?: string;
    claimResponse?:       Reference;
    coverage:             Reference;
    extension?:           Extension[];
    focal:                boolean;
    id?:                  string;
    identifier?:          Identifier;
    modifierExtension?:   Extension[];
    preAuthRef?:          string[];
    sequence:             number;
}

export interface ClaimItemArray {
    bodySite?:            CodeableConcept;
    careTeamSequence?:    number[];
    category?:            CodeableConcept;
    detail?:              ClaimItemArrayDetailArray[];
    diagnosisSequence?:   number[];
    encounter?:           Reference[];
    extension?:           Extension[];
    factor?:              number;
    id?:                  string;
    informationSequence?: number[];
    location?:            ClaimItemArrayLocation;
    modifier?:            CodeableConcept[];
    modifierExtension?:   Extension[];
    net?:                 Money;
    procedureSequence?:   number[];
    productOrService:     CodeableConcept;
    programCode?:         CodeableConcept[];
    quantity?:            Quantity;
    revenue?:             CodeableConcept;
    sequence:             number;
    serviced?:            ClaimItemArrayServiced;
    subSite?:             CodeableConcept[];
    udi?:                 Reference[];
    unitPrice?:           Money;
}

export interface ClaimItemArrayDetailArray {
    category?:          CodeableConcept;
    extension?:         Extension[];
    factor?:            number;
    id?:                string;
    modifier?:          CodeableConcept[];
    modifierExtension?: Extension[];
    net?:               Money;
    productOrService:   CodeableConcept;
    programCode?:       CodeableConcept[];
    quantity?:          Quantity;
    revenue?:           CodeableConcept;
    sequence:           number;
    subDetail?:         ClaimItemArrayDetailArraySubDetailArray[];
    udi?:               Reference[];
    unitPrice?:         Money;
}

export interface ClaimItemArrayDetailArraySubDetailArray {
    category?:          CodeableConcept;
    extension?:         Extension[];
    factor?:            number;
    id?:                string;
    modifier?:          CodeableConcept[];
    modifierExtension?: Extension[];
    net?:               Money;
    productOrService:   CodeableConcept;
    programCode?:       CodeableConcept[];
    quantity?:          Quantity;
    revenue?:           CodeableConcept;
    sequence:           number;
    udi?:               Reference[];
    unitPrice?:         Money;
}

export interface ClaimItemArrayLocation {
    Address?:           Address;
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface ClaimItemArrayServiced {
    date?:              string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface ClaimPayee {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    party?:             Reference;
    type:               CodeableConcept;
}

export interface ClaimProcedureArray {
    date?:              string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    procedure?:         ClaimProcedureArrayProcedure;
    sequence:           number;
    type?:              CodeableConcept[];
    udi?:               Reference[];
}

export interface ClaimProcedureArrayProcedure {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface ClaimRelatedArray {
    claim?:             Reference;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    reference?:         Identifier;
    relationship?:      CodeableConcept;
}

export interface ClaimSupportingInfoArray {
    category:           CodeableConcept;
    code?:              CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    reason?:            CodeableConcept;
    sequence:           number;
    timing?:            ClaimSupportingInfoArrayTiming;
    value?:             ClaimSupportingInfoArrayValue;
}

export interface ClaimSupportingInfoArrayTiming {
    date?:              string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface ClaimSupportingInfoArrayValue {
    Attachment?:        Attachment;
    boolean?:           boolean;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    Reference?:         Reference;
    string?:            string;
}

export interface Client {
    active?:            boolean;
    allowed_origins?:   string[];
    auth?:              ClientAuth;
    description?:       string;
    extension?:         Extension[];
    first_party?:       boolean;
    grant_types?:       ClientGrantTypesArray[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    resourceType?:      "Client";
    scopes?:            ClientScopesArray[];
    secret?:            string;
    smart?:             ClientSmart;
}

export interface ClientAuth {
    authorization_code?: ClientAuthAuthorizationCode;
    client_credentials?: ClientAuthClientCredentials;
    extension?:          Extension[];
    implicit?:           ClientAuthImplicit;
    modifierExtension?:  Extension[];
    password?:           ClientAuthPassword;
}

export interface ClientAuthAuthorizationCode {
    access_token_expiration?: number;
    audience?:                string[];
    extension?:               Extension[];
    modifierExtension?:       Extension[];
    pkce?:                    boolean;
    redirect_uri:             string;
    refresh_token?:           boolean;
    secret_required?:         boolean;
    token_format?:            TokenFormat;
}

export enum TokenFormat {
    Jwt = "jwt",
}

export interface ClientAuthClientCredentials {
    access_token_expiration?: number;
    audience?:                string[];
    extension?:               Extension[];
    modifierExtension?:       Extension[];
    refresh_token?:           boolean;
    token_format?:            TokenFormat;
}

export interface ClientAuthImplicit {
    access_token_expiration?: number;
    audience?:                string[];
    extension?:               Extension[];
    modifierExtension?:       Extension[];
    redirect_uri:             string;
    token_format?:            TokenFormat;
}

export interface ClientAuthPassword {
    access_token_expiration?: number;
    audience?:                string[];
    extension?:               Extension[];
    modifierExtension?:       Extension[];
    redirect_uri?:            string;
    refresh_token?:           boolean;
    secret_required?:         boolean;
    token_format?:            TokenFormat;
}

export enum ClientGrantTypesArray {
    AuthorizationCode = "authorization_code",
    Basic = "basic",
    ClientCredentials = "client_credentials",
    Code = "code",
    Implicit = "implicit",
    Password = "password",
    RefreshToken = "refresh_token",
}

export interface ClientScopesArray {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    parameters?:        ClientScopesArrayParameters;
    policy?:            Reference;
}

export interface ClientScopesArrayParameters {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface ClientSmart {
    extension?:         Extension[];
    launch_uri?:        string;
    modifierExtension?: Extension[];
}

export interface InsurancePlan {
    administeredBy?:    Reference;
    alias?:             string[];
    contact?:           InsurancePlanContactArray[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    coverage?:          InsurancePlanCoverageArray[];
    coverageArea?:      Reference[];
    endpoint?:          Reference[];
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    network?:           Reference[];
    ownedBy?:           Reference;
    period?:            Period;
    plan?:              InsurancePlanPlanArray[];
    resourceType?:      "InsurancePlan";
    status?:            string;
    text?:              Narrative;
    type?:              CodeableConcept[];
}

export interface InsurancePlanContactArray {
    address?:           Address;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name?:              HumanName;
    purpose?:           CodeableConcept;
    telecom?:           ContactPoint[];
}

export interface InsurancePlanCoverageArray {
    benefit:            InsurancePlanCoverageArrayBenefitArray[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    network?:           Reference[];
    type:               CodeableConcept;
}

export interface InsurancePlanCoverageArrayBenefitArray {
    extension?:         Extension[];
    id?:                string;
    limit?:             InsurancePlanCoverageArrayBenefitArrayLimitArray[];
    modifierExtension?: Extension[];
    requirement?:       string;
    type:               CodeableConcept;
}

export interface InsurancePlanCoverageArrayBenefitArrayLimitArray {
    code?:              CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    value?:             Quantity;
}

export interface InsurancePlanPlanArray {
    coverageArea?:      Reference[];
    extension?:         Extension[];
    generalCost?:       InsurancePlanPlanArrayGeneralCostArray[];
    id?:                string;
    identifier?:        Identifier[];
    modifierExtension?: Extension[];
    network?:           Reference[];
    specificCost?:      InsurancePlanPlanArraySpecificCostArray[];
    type?:              CodeableConcept;
}

export interface InsurancePlanPlanArrayGeneralCostArray {
    comment?:           string;
    cost?:              Money;
    extension?:         Extension[];
    groupSize?:         number;
    id?:                string;
    modifierExtension?: Extension[];
    type?:              CodeableConcept;
}

export interface InsurancePlanPlanArraySpecificCostArray {
    benefit?:           InsurancePlanPlanArraySpecificCostArrayBenefitArray[];
    category:           CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface InsurancePlanPlanArraySpecificCostArrayBenefitArray {
    cost?:              InsurancePlanPlanArraySpecificCostArrayBenefitArrayCostArray[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    type:               CodeableConcept;
}

export interface InsurancePlanPlanArraySpecificCostArrayBenefitArrayCostArray {
    applicability?:     CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    qualifiers?:        CodeableConcept[];
    type:               CodeableConcept;
    value?:             Quantity;
}

export interface NotificationTemplate {
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    resourceType?:      "NotificationTemplate";
    template?:          string;
}

export interface ExampleScenario {
    actor?:             ExampleScenarioActorArray[];
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    copyright?:         string;
    date?:              string;
    experimental?:      boolean;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    instance?:          ExampleScenarioInstanceArray[];
    jurisdiction?:      CodeableConcept[];
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    process?:           ExampleScenarioProcessArrayProcessArray[];
    publisher?:         string;
    purpose?:           string;
    resourceType?:      "ExampleScenario";
    status:             string;
    text?:              Narrative;
    url?:               string;
    useContext?:        UsageContext[];
    version?:           string;
    workflow?:          string[];
}

export interface ExampleScenarioActorArray {
    actorId:            string;
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name?:              string;
    type:               string;
}

export interface ExampleScenarioInstanceArray {
    containedInstance?: ExampleScenarioInstanceArrayContainedInstanceArray[];
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name?:              string;
    resourceId:         string;
    version?:           ExampleScenarioInstanceArrayVersionArray[];
}

export interface ExampleScenarioInstanceArrayContainedInstanceArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    resourceId:         string;
    versionId?:         string;
}

export interface ExampleScenarioInstanceArrayVersionArray {
    description:        string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    versionId:          string;
}

export interface ExampleScenarioProcessArrayStepArrayAlternativeArray {
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    step?:              ExampleScenarioProcessArrayStepArrayArrayStepArray[];
    title:              string;
}

export interface ExampleScenarioProcessArrayStepArrayArrayStepArray {
    alternative?:       ExampleScenarioProcessArrayStepArrayAlternativeArray[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    operation?:         ExampleScenarioProcessArrayStepArrayOperation;
    pause?:             boolean;
    process?:           ExampleScenarioProcessArrayProcessArray[];
}

export interface ExampleScenarioProcessArrayProcessArray {
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    postConditions?:    string;
    preConditions?:     string;
    step?:              ExampleScenarioProcessArrayStepArrayArrayStepArray[];
    title:              string;
}

export interface ExampleScenarioProcessArrayStepArrayOperation {
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    initiator?:         string;
    initiatorActive?:   boolean;
    modifierExtension?: Extension[];
    name?:              string;
    number:             string;
    receiver?:          string;
    receiverActive?:    boolean;
    request?:           ExampleScenarioInstanceArrayContainedInstanceArray;
    response?:          ExampleScenarioInstanceArrayContainedInstanceArray;
    type?:              string;
}

export interface ResearchStudy {
    arm?:                   ResearchStudyArmArray[];
    category?:              CodeableConcept[];
    condition?:             CodeableConcept[];
    contact?:               ContactDetail[];
    contained?:             Array<any[] | boolean | number | number | null | ResourceObject | string>;
    description?:           string;
    enrollment?:            Reference[];
    extension?:             Extension[];
    focus?:                 CodeableConcept[];
    id?:                    string;
    identifier?:            Identifier[];
    implicitRules?:         string;
    keyword?:               CodeableConcept[];
    language?:              string;
    location?:              CodeableConcept[];
    meta?:                  Meta;
    modifierExtension?:     Extension[];
    note?:                  Annotation[];
    objective?:             ResearchStudyObjectiveArray[];
    partOf?:                Reference[];
    period?:                Period;
    phase?:                 CodeableConcept;
    primaryPurposeType?:    CodeableConcept;
    principalInvestigator?: Reference;
    protocol?:              Reference[];
    reasonStopped?:         CodeableConcept;
    relatedArtifact?:       RelatedArtifact[];
    resourceType?:          "ResearchStudy";
    site?:                  Reference[];
    sponsor?:               Reference;
    status:                 string;
    text?:                  Narrative;
    title?:                 string;
}

export interface ResearchStudyArmArray {
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name:               string;
    type?:              CodeableConcept;
}

export interface ResearchStudyObjectiveArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name?:              string;
    type?:              CodeableConcept;
}

export interface MedicationRequest {
    authoredOn?:                string;
    basedOn?:                   Reference[];
    BETA_PelletInsertionDate?:  string;
    category?:                  CodeableConcept[];
    contained?:                 Array<any[] | boolean | number | number | null | ResourceObject | string>;
    courseOfTherapyType?:       CodeableConcept;
    detectedIssue?:             Reference[];
    dispenseRequest?:           MedicationRequestDispenseRequest;
    doNotPerform?:              boolean;
    dosageInstruction?:         Dosage[];
    encounter?:                 Reference;
    eventHistory?:              Reference[];
    extension?:                 Extension[];
    groupIdentifier?:           Identifier;
    id?:                        string;
    identifier?:                Identifier[];
    implicitRules?:             string;
    instantiatesCanonical?:     string[];
    instantiatesUri?:           string[];
    insurance?:                 Reference[];
    intent:                     string;
    language?:                  string;
    medication?:                MedicationRequestMedication;
    meta?:                      Meta;
    modifierExtension?:         Extension[];
    note?:                      Annotation[];
    performer?:                 Reference;
    performerType?:             CodeableConcept;
    priority?:                  string;
    priorPrescription?:         Reference;
    reasonCode?:                CodeableConcept[];
    reasonReference?:           Reference[];
    recorder?:                  Reference;
    reported?:                  MedicationRequestReported;
    requester?:                 Reference;
    resourceType?:              "MedicationRequest";
    status:                     string;
    statusReason?:              CodeableConcept;
    subject:                    Reference;
    substitution?:              MedicationRequestSubstitution;
    supportingInformation?:     Reference[];
    text?:                      Narrative;
}

export const isMedicationRequest = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is MedicationRequest =>
  isResourceObject(resource) && resource.resourceType === "MedicationRequest"

export interface MedicationRequestDispenseRequest {
    dispenseInterval?:       Duration;
    expectedSupplyDuration?: Duration;
    extension?:              Extension[];
    id?:                     string;
    initialFill?:            MedicationRequestDispenseRequestInitialFill;
    modifierExtension?:      Extension[];
    numberOfRepeatsAllowed?: number;
    performer?:              Reference;
    quantity?:               Quantity;
    validityPeriod?:         Period;
}

export interface MedicationRequestDispenseRequestInitialFill {
    BETA_DFO?:          boolean;
    duration?:          Duration;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    quantity?:          Quantity;
}

export interface MedicationRequestMedication {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface MedicationRequestReported {
    boolean?:           boolean;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface MedicationRequestSubstitution {
    allowed?:           MedicationRequestSubstitutionAllowed;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    reason?:            CodeableConcept;
}

export interface MedicationRequestSubstitutionAllowed {
    boolean?:           boolean;
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface Measure {
    approvalDate?:                    string;
    author?:                          ContactDetail[];
    clinicalRecommendationStatement?: string;
    compositeScoring?:                CodeableConcept;
    contact?:                         ContactDetail[];
    contained?:                       Array<any[] | boolean | number | number | null | ResourceObject | string>;
    copyright?:                       string;
    date?:                            string;
    definition?:                      string[];
    description?:                     string;
    disclaimer?:                      string;
    editor?:                          ContactDetail[];
    effectivePeriod?:                 Period;
    endorser?:                        ContactDetail[];
    experimental?:                    boolean;
    extension?:                       Extension[];
    group?:                           MeasureGroupArray[];
    guidance?:                        string;
    id?:                              string;
    identifier?:                      Identifier[];
    implicitRules?:                   string;
    improvementNotation?:             CodeableConcept;
    jurisdiction?:                    CodeableConcept[];
    language?:                        string;
    lastReviewDate?:                  string;
    library?:                         string[];
    meta?:                            Meta;
    modifierExtension?:               Extension[];
    name?:                            string;
    publisher?:                       string;
    purpose?:                         string;
    rateAggregation?:                 string;
    rationale?:                       string;
    relatedArtifact?:                 RelatedArtifact[];
    resourceType?:                    "Measure";
    reviewer?:                        ContactDetail[];
    riskAdjustment?:                  string;
    scoring?:                         CodeableConcept;
    status:                           string;
    subject?:                         MeasureSubject;
    subtitle?:                        string;
    supplementalData?:                MeasureSupplementalDataArray[];
    text?:                            Narrative;
    title?:                           string;
    topic?:                           CodeableConcept[];
    type?:                            CodeableConcept[];
    url?:                             string;
    usage?:                           string;
    useContext?:                      UsageContext[];
    version?:                         string;
}

export interface MeasureGroupArray {
    code?:              CodeableConcept;
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    population?:        MeasureGroupArrayPopulationArray[];
    stratifier?:        MeasureGroupArrayStratifierArray[];
}

export interface MeasureGroupArrayPopulationArray {
    code?:              CodeableConcept;
    criteria:           Expression;
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface MeasureGroupArrayStratifierArray {
    code?:              CodeableConcept;
    component?:         MeasureGroupArrayStratifierArrayComponentArray[];
    criteria?:          Expression;
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface MeasureGroupArrayStratifierArrayComponentArray {
    code?:              CodeableConcept;
    criteria:           Expression;
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface MeasureSubject {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface MeasureSupplementalDataArray {
    code?:              CodeableConcept;
    criteria:           Expression;
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    usage?:             CodeableConcept[];
}

export interface List {
    code?:              CodeableConcept;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    date?:              string;
    emptyReason?:       CodeableConcept;
    encounter?:         Reference;
    entry?:             ListEntryArray[];
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    mode:               string;
    modifierExtension?: Extension[];
    note?:              Annotation[];
    orderedBy?:         CodeableConcept;
    resourceType?:      "List";
    source?:            Reference;
    status:             string;
    subject?:           Reference;
    text?:              Narrative;
    title?:             string;
}

export interface ListEntryArray {
    date?:              string;
    deleted?:           boolean;
    extension?:         Extension[];
    flag?:              CodeableConcept;
    id?:                string;
    item:               Reference;
    modifierExtension?: Extension[];
}

export interface Encounter {
    account?:           Reference[];
    appointment?:       Reference[];
    basedOn?:           Reference[];
    class:              Coding;
    classHistory?:      EncounterClassHistoryArray[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    diagnosis?:         EncounterDiagnosisArray[];
    episodeOfCare?:     Reference[];
    extension?:         Extension[];
    hospitalization?:   EncounterHospitalization;
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    length?:            Duration;
    location?:          EncounterLocationArray[];
    meta?:              Meta;
    modifierExtension?: Extension[];
    participant?:       EncounterParticipantArray[];
    partOf?:            Reference;
    period?:            Period;
    priority?:          CodeableConcept;
    reasonCode?:        CodeableConcept[];
    reasonReference?:   Reference[];
    resourceType?:      "Encounter";
    serviceProvider?:   Reference;
    serviceType?:       CodeableConcept;
    status:             string;
    statusHistory?:     EncounterStatusHistoryArray[];
    subject?:           Reference;
    text?:              Narrative;
    type?:              CodeableConcept[];
}

export const isEncounter = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is Encounter =>
    isResourceObject(resource) && resource.resourceType ==="Encounter"

export interface EncounterClassHistoryArray {
    class:              Coding;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    period:             Period;
}

export interface EncounterDiagnosisArray {
    condition:          Reference;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    rank?:              number;
    use?:               CodeableConcept;
}

export interface EncounterHospitalization {
    admitSource?:            CodeableConcept;
    destination?:            Reference;
    dietPreference?:         CodeableConcept[];
    dischargeDisposition?:   CodeableConcept;
    extension?:              Extension[];
    id?:                     string;
    modifierExtension?:      Extension[];
    origin?:                 Reference;
    preAdmissionIdentifier?: Identifier;
    reAdmission?:            CodeableConcept;
    specialArrangement?:     CodeableConcept[];
    specialCourtesy?:        CodeableConcept[];
}

export interface EncounterLocationArray {
    extension?:         Extension[];
    id?:                string;
    location:           Reference;
    modifierExtension?: Extension[];
    period?:            Period;
    physicalType?:      CodeableConcept;
    status?:            string;
}

export interface EncounterParticipantArray {
    extension?:         Extension[];
    id?:                string;
    individual?:        Reference;
    modifierExtension?: Extension[];
    period?:            Period;
    type?:              CodeableConcept[];
}

export interface EncounterStatusHistoryArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    period:             Period;
    status:             string;
}

export interface CapabilityStatement {
    contact?:             ContactDetail[];
    contained?:           Array<any[] | boolean | number | number | null | ResourceObject | string>;
    copyright?:           string;
    date:                 string;
    description?:         string;
    document?:            CapabilityStatementDocumentArray[];
    experimental?:        boolean;
    extension?:           Extension[];
    fhirVersion:          string;
    format:               string[];
    id?:                  string;
    implementation?:      CapabilityStatementImplementation;
    implementationGuide?: string[];
    implicitRules?:       string;
    imports?:             string[];
    instantiates?:        string[];
    jurisdiction?:        CodeableConcept[];
    kind:                 string;
    language?:            string;
    messaging?:           CapabilityStatementMessagingArray[];
    meta?:                Meta;
    modifierExtension?:   Extension[];
    name?:                string;
    patchFormat?:         string[];
    publisher?:           string;
    purpose?:             string;
    rest?:                CapabilityStatementRESTArray[];
    software?:            CapabilityStatementSoftware;
    status:               string;
    text?:                Narrative;
    title?:               string;
    url?:                 string;
    useContext?:          UsageContext[];
    version?:             string;
}

export interface CapabilityStatementDocumentArray {
    documentation?:     string;
    extension?:         Extension[];
    id?:                string;
    mode:               string;
    modifierExtension?: Extension[];
    profile:            string;
}

export interface CapabilityStatementImplementation {
    custodian?:         Reference;
    description:        string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    url?:               string;
}

export interface CapabilityStatementMessagingArray {
    documentation?:     string;
    endpoint?:          CapabilityStatementMessagingArrayEndpointArray[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    reliableCache?:     number;
    supportedMessage?:  CapabilityStatementMessagingArraySupportedMessageArray[];
}

export interface CapabilityStatementMessagingArrayEndpointArray {
    address:            string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    protocol:           Coding;
}

export interface CapabilityStatementMessagingArraySupportedMessageArray {
    definition:         string;
    extension?:         Extension[];
    id?:                string;
    mode:               string;
    modifierExtension?: Extension[];
}

export interface CapabilityStatementRESTArray {
    compartment?:       string[];
    documentation?:     string;
    extension?:         Extension[];
    id?:                string;
    interaction?:       CapabilityStatementRESTArrayInteractionArray[];
    mode:               string;
    modifierExtension?: Extension[];
    operation?:         CapabilityStatementRESTArrayArrayOperationArray[];
    resource?:          CapabilityStatementRESTArrayResourceArray[];
    searchParam?:       CapabilityStatementRESTArrayArraySearchParamArray[];
    security?:          CapabilityStatementRESTArraySecurity;
}

export interface CapabilityStatementRESTArrayInteractionArray {
    code:               string;
    documentation?:     string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface CapabilityStatementRESTArrayArrayOperationArray {
    definition:         string;
    documentation?:     string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name:               string;
}

export interface CapabilityStatementRESTArrayResourceArray {
    conditionalCreate?: boolean;
    conditionalDelete?: string;
    conditionalRead?:   string;
    conditionalUpdate?: boolean;
    documentation?:     string;
    extension?:         Extension[];
    id?:                string;
    interaction?:       CapabilityStatementRESTArrayResourceArrayInteractionArray[];
    modifierExtension?: Extension[];
    operation?:         CapabilityStatementRESTArrayArrayOperationArray[];
    profile?:           string;
    readHistory?:       boolean;
    referencePolicy?:   string[];
    searchInclude?:     string[];
    searchParam?:       CapabilityStatementRESTArrayArraySearchParamArray[];
    searchRevInclude?:  string[];
    supportedProfile?:  string[];
    type:               string;
    updateCreate?:      boolean;
    versioning?:        string;
}

export interface CapabilityStatementRESTArrayResourceArrayInteractionArray {
    code:               string;
    documentation?:     string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface CapabilityStatementRESTArrayArraySearchParamArray {
    definition?:        string;
    documentation?:     string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name:               string;
    type:               string;
}

export interface CapabilityStatementRESTArraySecurity {
    cors?:              boolean;
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    service?:           CodeableConcept[];
}

export interface CapabilityStatementSoftware {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name:               string;
    releaseDate?:       string;
    version?:           string;
}

export interface VisionPrescription {
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    created:            string;
    dateWritten:        string;
    encounter?:         Reference;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    lensSpecification:  VisionPrescriptionLensSpecificationArray[];
    meta?:              Meta;
    modifierExtension?: Extension[];
    patient:            Reference;
    prescriber:         Reference;
    resourceType?:      "VisionPrescription";
    status:             string;
    text?:              Narrative;
}

export interface VisionPrescriptionLensSpecificationArray {
    add?:               number;
    axis?:              number;
    backCurve?:         number;
    brand?:             string;
    color?:             string;
    cylinder?:          number;
    diameter?:          number;
    duration?:          Quantity;
    extension?:         Extension[];
    eye:                string;
    id?:                string;
    modifierExtension?: Extension[];
    note?:              Annotation[];
    power?:             number;
    prism?:             VisionPrescriptionLensSpecificationArrayPrismArray[];
    product:            CodeableConcept;
    sphere?:            number;
}

export interface VisionPrescriptionLensSpecificationArrayPrismArray {
    amount:             number;
    base:               string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface RiskAssessment {
    basedOn?:           Reference;
    basis?:             Reference[];
    code?:              CodeableConcept;
    condition?:         Reference;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    encounter?:         Reference;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    method?:            CodeableConcept;
    mitigation?:        string;
    modifierExtension?: Extension[];
    note?:              Annotation[];
    occurrence?:        RiskAssessmentOccurrence;
    parent?:            Reference;
    performer?:         Reference;
    prediction?:        RiskAssessmentPredictionArray[];
    reasonCode?:        CodeableConcept[];
    reasonReference?:   Reference[];
    resourceType?:      "RiskAssessment";
    status:             string;
    subject:            Reference;
    text?:              Narrative;
}

export interface RiskAssessmentOccurrence {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface RiskAssessmentPredictionArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    outcome?:           CodeableConcept;
    probability?:       RiskAssessmentPredictionArrayProbability;
    qualitativeRisk?:   CodeableConcept;
    rationale?:         string;
    relativeRisk?:      number;
    when?:              RiskAssessmentPredictionArrayWhen;
}

export interface RiskAssessmentPredictionArrayProbability {
    decimal?:           number;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Range?:             Range;
}

export interface RiskAssessmentPredictionArrayWhen {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
    Range?:             Range;
}

export interface SubstanceProtein {
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    disulfideLinkage?:  string[];
    extension?:         Extension[];
    id?:                string;
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    numberOfSubunits?:  number;
    resourceType?:      "SubstanceProtein";
    sequenceType?:      CodeableConcept;
    subunit?:           SubstanceProteinSubunitArray[];
    text?:              Narrative;
}

export interface SubstanceProteinSubunitArray {
    cTerminalModification?:   string;
    cTerminalModificationId?: Identifier;
    extension?:               Extension[];
    id?:                      string;
    length?:                  number;
    modifierExtension?:       Extension[];
    nTerminalModification?:   string;
    nTerminalModificationId?: Identifier;
    sequence?:                string;
    sequenceAttachment?:      Attachment;
    subunit?:                 number;
}

export interface ImmunizationRecommendation {
    authority?:         Reference;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    date:               string;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    patient:            Reference;
    recommendation:     ImmunizationRecommendationRecommendationArray[];
    resourceType?:      "ImmunizationRecommendation";
    text?:              Narrative;
}

export interface ImmunizationRecommendationRecommendationArray {
    contraindicatedVaccineCode?:   CodeableConcept[];
    dateCriterion?:                ImmunizationRecommendationRecommendationArrayDateCriterionArray[];
    description?:                  string;
    doseNumber?:                   ImmunizationRecommendationRecommendationArrayDoseNumber;
    extension?:                    Extension[];
    forecastReason?:               CodeableConcept[];
    forecastStatus:                CodeableConcept;
    id?:                           string;
    modifierExtension?:            Extension[];
    series?:                       string;
    seriesDoses?:                  ImmunizationRecommendationRecommendationArraySeriesDoses;
    supportingImmunization?:       Reference[];
    supportingPatientInformation?: Reference[];
    targetDisease?:                CodeableConcept;
    vaccineCode?:                  CodeableConcept[];
}

export interface ImmunizationRecommendationRecommendationArrayDateCriterionArray {
    code:               CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    value:              string;
}

export interface ImmunizationRecommendationRecommendationArrayDoseNumber {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    positiveInt?:       number;
    string?:            string;
}

export interface ImmunizationRecommendationRecommendationArraySeriesDoses {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    positiveInt?:       number;
    string?:            string;
}

export interface RelatedPerson {
    active?:            boolean;
    address?:           Address[];
    birthDate?:         string;
    communication?:     RelatedPersonCommunicationArray[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    extension?:         Extension[];
    gender?:            string;
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              HumanName[];
    patient:            Reference;
    period?:            Period;
    photo?:             Attachment[];
    relationship?:      CodeableConcept[];
    resourceType?:      "RelatedPerson";
    telecom?:           ContactPoint[];
    text?:              Narrative;
}

export interface RelatedPersonCommunicationArray {
    extension?:         Extension[];
    id?:                string;
    language:           CodeableConcept;
    modifierExtension?: Extension[];
    preferred?:         boolean;
}

export interface Medication {
    amount?:            Ratio;
    batch?:             MedicationBatch;
    code?:              CodeableConcept;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    extension?:         Extension[];
    form?:              CodeableConcept;
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    ingredient?:        MedicationIngredientArray[];
    language?:          string;
    manufacturer?:      Reference;
    meta?:              Meta;
    modifierExtension?: Extension[];
    resourceType?:      "Medication";
    status?:            string;
    text?:              Narrative;
}

export const isMedication = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is Medication =>
    isResourceObject(resource) && resource.resourceType === "Medication"

export interface MedicationBatch {
    expirationDate?:    string;
    extension?:         Extension[];
    id?:                string;
    lotNumber?:         string;
    modifierExtension?: Extension[];
}

export interface MedicationIngredientArray {
    extension?:         Extension[];
    id?:                string;
    isActive?:          boolean;
    item?:              MedicationIngredientArrayItem;
    modifierExtension?: Extension[];
    strength?:          Ratio;
}

export interface MedicationIngredientArrayItem {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface AppointmentResponse {
    actor?:             Reference;
    appointment:        Reference;
    comment?:           string;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    end?:               Date;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    participantStatus:  string;
    participantType?:   CodeableConcept[];
    resourceType?:      "AppointmentResponse";
    start?:             Date;
    text?:              Narrative;
}

export interface ResearchElementDefinition {
    approvalDate?:      string;
    author?:            ContactDetail[];
    characteristic:     ResearchElementDefinitionCharacteristicArray[];
    comment?:           string[];
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    copyright?:         string;
    date?:              string;
    description?:       string;
    editor?:            ContactDetail[];
    effectivePeriod?:   Period;
    endorser?:          ContactDetail[];
    experimental?:      boolean;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    jurisdiction?:      CodeableConcept[];
    language?:          string;
    lastReviewDate?:    string;
    library?:           string[];
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    publisher?:         string;
    purpose?:           string;
    relatedArtifact?:   RelatedArtifact[];
    resourceType?:      "ResearchElementDefinition";
    reviewer?:          ContactDetail[];
    shortTitle?:        string;
    status:             string;
    subject?:           ResearchElementDefinitionSubject;
    subtitle?:          string;
    text?:              Narrative;
    title?:             string;
    topic?:             CodeableConcept[];
    type:               string;
    url?:               string;
    usage?:             string;
    useContext?:        UsageContext[];
    variableType?:      string;
    version?:           string;
}

export interface ResearchElementDefinitionCharacteristicArray {
    definition?:                        ResearchElementDefinitionCharacteristicArrayDefinition;
    exclude?:                           boolean;
    extension?:                         Extension[];
    id?:                                string;
    modifierExtension?:                 Extension[];
    participantEffective?:              ResearchElementDefinitionCharacteristicArrayParticipantEffective;
    participantEffectiveDescription?:   string;
    participantEffectiveGroupMeasure?:  string;
    participantEffectiveTimeFromStart?: Duration;
    studyEffective?:                    ResearchElementDefinitionCharacteristicArrayStudyEffective;
    studyEffectiveDescription?:         string;
    studyEffectiveGroupMeasure?:        string;
    studyEffectiveTimeFromStart?:       Duration;
    unitOfMeasure?:                     CodeableConcept;
    usageContext?:                      UsageContext[];
}

export interface ResearchElementDefinitionCharacteristicArrayDefinition {
    canonical?:         string;
    CodeableConcept?:   CodeableConcept;
    DataRequirement?:   DataRequirement;
    Expression?:        Expression;
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface ResearchElementDefinitionCharacteristicArrayParticipantEffective {
    dateTime?:          string;
    Duration?:          Duration;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
    Timing?:            Timing;
}

export interface ResearchElementDefinitionCharacteristicArrayStudyEffective {
    dateTime?:          string;
    Duration?:          Duration;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
    Timing?:            Timing;
}

export interface ResearchElementDefinitionSubject {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface Substance {
    category?:          CodeableConcept[];
    code:               CodeableConcept;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    ingredient?:        SubstanceIngredientArray[];
    instance?:          SubstanceInstanceArray[];
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    resourceType?:      "Substance";
    status?:            string;
    text?:              Narrative;
}

export interface SubstanceIngredientArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    quantity?:          Ratio;
    substance?:         SubstanceIngredientArraySubstance;
}

export interface SubstanceIngredientArraySubstance {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface SubstanceInstanceArray {
    expiry?:            string;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier;
    modifierExtension?: Extension[];
    quantity?:          Quantity;
}

export interface PaymentReconciliation {
    BETA_CreditCard?:   AccountBETACreditCardArray;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    created:            string;
    detail?:            PaymentReconciliationDetailArray[];
    disposition?:       string;
    extension?:         Extension[];
    formCode?:          CodeableConcept;
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    outcome?:           string;
    paymentAmount:      Money;
    paymentDate:        string;
    paymentIdentifier?: Identifier;
    paymentIssuer?:     Reference;
    period?:            Period;
    processNote?:       PaymentReconciliationProcessNoteArray[];
    R5_request:         Reference;
    request?:           Reference;
    requestor?:         Reference;
    resourceType?:      "PaymentReconciliation";
    status:             string;
    text?:              Narrative;
    BETA_paymentStatus: string;
}

export const isPaymentReconciliation = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is PaymentReconciliation =>
    isResourceObject(resource) && resource.resourceType === "PaymentReconciliation"

export interface PaymentReconciliationDetailArray {
    amount?:            Money;
    date?:              string;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier;
    modifierExtension?: Extension[];
    payee?:             Reference;
    predecessor?:       Identifier;
    request?:           Reference;
    response?:          Reference;
    responsible?:       Reference;
    submitter?:         Reference;
    type:               CodeableConcept;
}

export interface PaymentReconciliationProcessNoteArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    text?:              string;
    type?:              string;
}

export interface ConceptMap {
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    copyright?:         string;
    date?:              string;
    description?:       string;
    experimental?:      boolean;
    extension?:         Extension[];
    group?:             ConceptMapGroupArray[];
    id?:                string;
    identifier?:        Identifier;
    implicitRules?:     string;
    jurisdiction?:      CodeableConcept[];
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    publisher?:         string;
    purpose?:           string;
    resourceType?:      "ConceptMap";
    source?:            ConceptMapSource;
    status:             string;
    target?:            ConceptMapTarget;
    text?:              Narrative;
    title?:             string;
    url?:               string;
    useContext?:        UsageContext[];
    version?:           string;
}

export interface ConceptMapGroupArray {
    element:            ConceptMapGroupArrayElementArray[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    source?:            string;
    sourceVersion?:     string;
    target?:            string;
    targetVersion?:     string;
    unmapped?:          ConceptMapGroupArrayUnmapped;
}

export interface ConceptMapGroupArrayElementArray {
    code?:              string;
    display?:           string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    target?:            ConceptMapGroupArrayElementArrayTargetArray[];
}

export interface ConceptMapGroupArrayElementArrayTargetArray {
    code?:              string;
    comment?:           string;
    dependsOn?:         ConceptMapGroupArrayElementArrayTargetArrayArray[];
    display?:           string;
    equivalence:        string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    product?:           ConceptMapGroupArrayElementArrayTargetArrayArray[];
}

export interface ConceptMapGroupArrayElementArrayTargetArrayArray {
    display?:           string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    property:           string;
    system?:            string;
    value:              string;
}

export interface ConceptMapGroupArrayUnmapped {
    code?:              string;
    display?:           string;
    extension?:         Extension[];
    id?:                string;
    mode:               string;
    modifierExtension?: Extension[];
    url?:               string;
}

export interface ConceptMapSource {
    canonical?:         string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    uri?:               string;
}

export interface ConceptMapTarget {
    canonical?:         string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    uri?:               string;
}

export interface Person {
    active?:               boolean;
    address?:              Address[];
    birthDate?:            string;
    contained?:            Array<any[] | boolean | number | number | null | ResourceObject | string>;
    extension?:            Extension[];
    gender?:               string;
    id?:                   string;
    identifier?:           Identifier[];
    implicitRules?:        string;
    language?:             string;
    link?:                 PersonLinkArray[];
    managingOrganization?: Reference;
    meta?:                 Meta;
    modifierExtension?:    Extension[];
    name?:                 HumanName[];
    photo?:                Attachment;
    resourceType?:         "Person";
    telecom?:              ContactPoint[];
    text?:                 Narrative;
}

export interface PersonLinkArray {
    assurance?:         string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    target:             Reference;
}

export interface Condition {
    abatement?:          ConditionAbatement;
    asserter?:           Reference;
    bodySite?:           CodeableConcept[];
    category?:           CodeableConcept[];
    clinicalStatus?:     CodeableConcept;
    code?:               CodeableConcept;
    contained?:          Array<any[] | boolean | number | number | null | ResourceObject | string>;
    encounter?:          Reference;
    evidence?:           ConditionEvidenceArray[];
    extension?:          Extension[];
    id?:                 string;
    identifier?:         Identifier[];
    implicitRules?:      string;
    language?:           string;
    meta?:               Meta;
    modifierExtension?:  Extension[];
    note?:               Annotation[];
    onset?:              ConditionOnset;
    recordedDate?:       string;
    recorder?:           Reference;
    resourceType?:       "Condition";
    severity?:           CodeableConcept;
    stage?:              ConditionStageArray[];
    subject:             Reference;
    text?:               Narrative;
    verificationStatus?: CodeableConcept;
}

export interface ConditionAbatement {
    Age?:               Age;
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
    Range?:             Range;
    string?:            string;
}

export interface ConditionEvidenceArray {
    code?:              CodeableConcept[];
    detail?:            Reference[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface ConditionOnset {
    Age?:               Age;
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
    Range?:             Range;
    string?:            string;
}

export interface ConditionStageArray {
    assessment?:        Reference[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    summary?:           CodeableConcept;
    type?:              CodeableConcept;
}

export interface CareTeam {
    category?:             CodeableConcept[];
    contained?:            Array<any[] | boolean | number | number | null | ResourceObject | string>;
    encounter?:            Reference;
    extension?:            Extension[];
    id?:                   string;
    identifier?:           Identifier[];
    implicitRules?:        string;
    language?:             string;
    managingOrganization?: Reference[];
    meta?:                 Meta;
    modifierExtension?:    Extension[];
    name?:                 string;
    note?:                 Annotation[];
    participant?:          CareTeamParticipantArray[];
    period?:               Period;
    reasonCode?:           CodeableConcept[];
    reasonReference?:      Reference[];
    resourceType?:         "CareTeam";
    status?:               string;
    subject?:              Reference;
    telecom?:              ContactPoint[];
    text?:                 Narrative;
}

export interface CareTeamParticipantArray {
    extension?:         Extension[];
    id?:                string;
    member?:            Reference;
    modifierExtension?: Extension[];
    onBehalfOf?:        Reference;
    period?:            Period;
    role?:              CodeableConcept[];
}

export interface CatalogEntry {
    additionalCharacteristic?: CodeableConcept[];
    additionalClassification?: CodeableConcept[];
    additionalIdentifier?:     Identifier[];
    classification?:           CodeableConcept[];
    contained?:                Array<any[] | boolean | number | number | null | ResourceObject | string>;
    extension?:                Extension[];
    id?:                       string;
    identifier?:               Identifier[];
    implicitRules?:            string;
    language?:                 string;
    lastUpdated?:              string;
    meta?:                     Meta;
    modifierExtension?:        Extension[];
    orderable:                 boolean;
    referencedItem:            Reference;
    relatedEntry?:             CatalogEntryRelatedEntryArray[];
    resourceType?:             "CatalogEntry";
    status?:                   string;
    text?:                     Narrative;
    type?:                     CodeableConcept;
    validityPeriod?:           Period;
    validTo?:                  string;
}

export interface CatalogEntryRelatedEntryArray {
    extension?:         Extension[];
    id?:                string;
    item:               Reference;
    modifierExtension?: Extension[];
    relationtype:       string;
}

export interface StructureDefinition {
    abstract:           boolean;
    baseDefinition?:    string;
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    context?:           StructureDefinitionContextArray[];
    contextInvariant?:  string[];
    copyright?:         string;
    date?:              string;
    derivation?:        string;
    description?:       string;
    differential?:      StructureDefinitionDifferential;
    experimental?:      boolean;
    extension?:         Extension[];
    fhirVersion?:       string;
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    jurisdiction?:      CodeableConcept[];
    keyword?:           Coding[];
    kind:               string;
    language?:          string;
    mapping?:           StructureDefinitionMappingArray[];
    meta?:              Meta;
    modifierExtension?: Extension[];
    name:               string;
    publisher?:         string;
    purpose?:           string;
    snapshot?:          StructureDefinitionSnapshot;
    status:             string;
    text?:              Narrative;
    title?:             string;
    type:               string;
    url:                string;
    useContext?:        UsageContext[];
    version?:           string;
}

export interface StructureDefinitionContextArray {
    expression:         string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    type:               string;
}

export interface StructureDefinitionDifferential {
    element:            ElementDefinition[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface ElementDefinition {
    alias?:               string[];
    base?:                ElementDefinitionBase;
    binding?:             ElementDefinitionBinding;
    code?:                Coding[];
    comment?:             string;
    condition?:           string[];
    constraint?:          ElementDefinitionConstraintArray[];
    contentReference?:    string;
    defaultValue?:        ElementDefinitionDefaultValue;
    definition?:          string;
    example?:             ElementDefinitionExampleArray[];
    extension?:           Extension[];
    fixed?:               ElementDefinitionFixed;
    id?:                  string;
    isModifier?:          boolean;
    isModifierReason?:    string;
    isSummary?:           boolean;
    label?:               string;
    mapping?:             ElementDefinitionMappingArray[];
    max?:                 string;
    maxLength?:           number;
    maxValue?:            ElementDefinitionMaxValue;
    meaningWhenMissing?:  string;
    meta?:                Meta;
    min?:                 number;
    minValue?:            ElementDefinitionMinValue;
    modifierExtension?:   Extension[];
    mustSupport?:         boolean;
    orderMeaning?:        string;
    path:                 string;
    pattern?:             ElementDefinitionPattern;
    representation?:      string[];
    requirements?:        string;
    short?:               string;
    sliceIsConstraining?: boolean;
    sliceName?:           string;
    slicing?:             ElementDefinitionSlicing;
    type?:                ElementDefinitionTypeArray[];
}

export interface ElementDefinitionBase {
    extension?:         Extension[];
    id?:                string;
    max:                string;
    min:                number;
    modifierExtension?: Extension[];
    path:               string;
}

export interface ElementDefinitionBinding {
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    strength:           string;
    valueSet?:          string;
}

export interface ElementDefinitionConstraintArray {
    expression?:        string;
    extension?:         Extension[];
    human:              string;
    id?:                string;
    key:                string;
    modifierExtension?: Extension[];
    requirements?:      string;
    severity:           string;
    source?:            string;
    xpath?:             string;
}

export interface ElementDefinitionDefaultValue {
    Address?:             Address;
    Age?:                 Age;
    Annotation?:          Annotation;
    Attachment?:          Attachment;
    base64Binary?:        string;
    boolean?:             boolean;
    canonical?:           string;
    code?:                string;
    CodeableConcept?:     CodeableConcept;
    Coding?:              Coding;
    ContactDetail?:       ContactDetail;
    ContactPoint?:        ContactPoint;
    Contributor?:         Contributor;
    Count?:               Count;
    DataRequirement?:     DataRequirement;
    date?:                string;
    dateTime?:            string;
    decimal?:             number;
    Distance?:            Distance;
    Dosage?:              Dosage;
    Duration?:            Duration;
    Expression?:          Expression;
    extension?:           Extension[];
    HumanName?:           HumanName;
    id?:                  string;
    Identifier?:          Identifier;
    instant?:             Date;
    integer?:             number;
    markdown?:            string;
    modifierExtension?:   Extension[];
    Money?:               Money;
    oid?:                 string;
    ParameterDefinition?: ParameterDefinition;
    Period?:              Period;
    positiveInt?:         number;
    Quantity?:            Quantity;
    Range?:               Range;
    Ratio?:               Ratio;
    Reference?:           Reference;
    RelatedArtifact?:     RelatedArtifact;
    SampledData?:         SampledData;
    Signature?:           Signature;
    string?:              string;
    time?:                string;
    Timing?:              Timing;
    TriggerDefinition?:   TriggerDefinition;
    unsignedInt?:         number;
    uri?:                 string;
    url?:                 string;
    UsageContext?:        UsageContext;
    uuid?:                string;
}

export interface ElementDefinitionExampleArray {
    extension?:         Extension[];
    id?:                string;
    label:              string;
    modifierExtension?: Extension[];
    value?:             ElementDefinitionExampleArrayValue;
}

export interface ElementDefinitionExampleArrayValue {
    Address?:             Address;
    Age?:                 Age;
    Annotation?:          Annotation;
    Attachment?:          Attachment;
    base64Binary?:        string;
    boolean?:             boolean;
    canonical?:           string;
    code?:                string;
    CodeableConcept?:     CodeableConcept;
    Coding?:              Coding;
    ContactDetail?:       ContactDetail;
    ContactPoint?:        ContactPoint;
    Contributor?:         Contributor;
    Count?:               Count;
    DataRequirement?:     DataRequirement;
    date?:                string;
    dateTime?:            string;
    decimal?:             number;
    Distance?:            Distance;
    Dosage?:              Dosage;
    Duration?:            Duration;
    Expression?:          Expression;
    extension?:           Extension[];
    HumanName?:           HumanName;
    id?:                  string;
    Identifier?:          Identifier;
    instant?:             Date;
    integer?:             number;
    markdown?:            string;
    modifierExtension?:   Extension[];
    Money?:               Money;
    oid?:                 string;
    ParameterDefinition?: ParameterDefinition;
    Period?:              Period;
    positiveInt?:         number;
    Quantity?:            Quantity;
    Range?:               Range;
    Ratio?:               Ratio;
    Reference?:           Reference;
    RelatedArtifact?:     RelatedArtifact;
    SampledData?:         SampledData;
    Signature?:           Signature;
    string?:              string;
    time?:                string;
    Timing?:              Timing;
    TriggerDefinition?:   TriggerDefinition;
    unsignedInt?:         number;
    uri?:                 string;
    url?:                 string;
    UsageContext?:        UsageContext;
    uuid?:                string;
}

export interface ElementDefinitionFixed {
    Address?:             Address;
    Age?:                 Age;
    Annotation?:          Annotation;
    Attachment?:          Attachment;
    base64Binary?:        string;
    boolean?:             boolean;
    canonical?:           string;
    code?:                string;
    CodeableConcept?:     CodeableConcept;
    Coding?:              Coding;
    ContactDetail?:       ContactDetail;
    ContactPoint?:        ContactPoint;
    Contributor?:         Contributor;
    Count?:               Count;
    DataRequirement?:     DataRequirement;
    date?:                string;
    dateTime?:            string;
    decimal?:             number;
    Distance?:            Distance;
    Dosage?:              Dosage;
    Duration?:            Duration;
    Expression?:          Expression;
    extension?:           Extension[];
    HumanName?:           HumanName;
    id?:                  string;
    Identifier?:          Identifier;
    instant?:             Date;
    integer?:             number;
    markdown?:            string;
    modifierExtension?:   Extension[];
    Money?:               Money;
    oid?:                 string;
    ParameterDefinition?: ParameterDefinition;
    Period?:              Period;
    positiveInt?:         number;
    Quantity?:            Quantity;
    Range?:               Range;
    Ratio?:               Ratio;
    Reference?:           Reference;
    RelatedArtifact?:     RelatedArtifact;
    SampledData?:         SampledData;
    Signature?:           Signature;
    string?:              string;
    time?:                string;
    Timing?:              Timing;
    TriggerDefinition?:   TriggerDefinition;
    unsignedInt?:         number;
    uri?:                 string;
    url?:                 string;
    UsageContext?:        UsageContext;
    uuid?:                string;
}

export interface ElementDefinitionMappingArray {
    comment?:           string;
    extension?:         Extension[];
    id?:                string;
    identity:           string;
    language?:          string;
    map:                string;
    modifierExtension?: Extension[];
}

export interface ElementDefinitionMaxValue {
    date?:              string;
    dateTime?:          string;
    decimal?:           number;
    extension?:         Extension[];
    instant?:           Date;
    integer?:           number;
    modifierExtension?: Extension[];
    positiveInt?:       number;
    Quantity?:          Quantity;
    time?:              string;
    unsignedInt?:       number;
}

export interface ElementDefinitionMinValue {
    date?:              string;
    dateTime?:          string;
    decimal?:           number;
    extension?:         Extension[];
    instant?:           Date;
    integer?:           number;
    modifierExtension?: Extension[];
    positiveInt?:       number;
    Quantity?:          Quantity;
    time?:              string;
    unsignedInt?:       number;
}

export interface ElementDefinitionPattern {
    Address?:             Address;
    Age?:                 Age;
    Annotation?:          Annotation;
    Attachment?:          Attachment;
    base64Binary?:        string;
    boolean?:             boolean;
    canonical?:           string;
    code?:                string;
    CodeableConcept?:     CodeableConcept;
    Coding?:              Coding;
    ContactDetail?:       ContactDetail;
    ContactPoint?:        ContactPoint;
    Contributor?:         Contributor;
    Count?:               Count;
    DataRequirement?:     DataRequirement;
    date?:                string;
    dateTime?:            string;
    decimal?:             number;
    Distance?:            Distance;
    Dosage?:              Dosage;
    Duration?:            Duration;
    Expression?:          Expression;
    extension?:           Extension[];
    HumanName?:           HumanName;
    id?:                  string;
    Identifier?:          Identifier;
    instant?:             Date;
    integer?:             number;
    markdown?:            string;
    modifierExtension?:   Extension[];
    Money?:               Money;
    oid?:                 string;
    ParameterDefinition?: ParameterDefinition;
    Period?:              Period;
    positiveInt?:         number;
    Quantity?:            Quantity;
    Range?:               Range;
    Ratio?:               Ratio;
    Reference?:           Reference;
    RelatedArtifact?:     RelatedArtifact;
    SampledData?:         SampledData;
    Signature?:           Signature;
    string?:              string;
    time?:                string;
    Timing?:              Timing;
    TriggerDefinition?:   TriggerDefinition;
    unsignedInt?:         number;
    uri?:                 string;
    url?:                 string;
    UsageContext?:        UsageContext;
    uuid?:                string;
}

export interface ElementDefinitionSlicing {
    description?:       string;
    discriminator?:     ElementDefinitionSlicingDiscriminatorArray[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    ordered?:           boolean;
    rules:              string;
}

export interface ElementDefinitionSlicingDiscriminatorArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    path:               string;
    type:               string;
}

export interface ElementDefinitionTypeArray {
    aggregation?:       string[];
    code?:              string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    profile?:           string[];
    targetProfile?:     string[];
    versioning?:        string;
}

export interface StructureDefinitionMappingArray {
    comment?:           string;
    extension?:         Extension[];
    id?:                string;
    identity:           string;
    modifierExtension?: Extension[];
    name?:              string;
    uri?:               string;
}

export interface StructureDefinitionSnapshot {
    element:            ElementDefinition[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface Procedure {
    asserter?:              Reference;
    basedOn?:               Reference[];
    bodySite?:              CodeableConcept[];
    category?:              CodeableConcept;
    code?:                  CodeableConcept;
    complication?:          CodeableConcept[];
    complicationDetail?:    Reference[];
    contained?:             Array<any[] | boolean | number | number | null | ResourceObject | string>;
    encounter?:             Reference;
    extension?:             Extension[];
    focalDevice?:           ProcedureFocalDeviceArray[];
    followUp?:              CodeableConcept[];
    id?:                    string;
    identifier?:            Identifier[];
    implicitRules?:         string;
    instantiatesCanonical?: string[];
    instantiatesUri?:       string[];
    language?:              string;
    location?:              Reference;
    meta?:                  Meta;
    modifierExtension?:     Extension[];
    note?:                  Annotation[];
    outcome?:               CodeableConcept;
    partOf?:                Reference[];
    performed?:             ProcedurePerformed;
    performer?:             ProcedurePerformerArray[];
    reasonCode?:            CodeableConcept[];
    reasonReference?:       Reference[];
    recorder?:              Reference;
    report?:                Reference[];
    resourceType?:          "Procedure";
    status:                 string;
    statusReason?:          CodeableConcept;
    subject:                Reference;
    text?:                  Narrative;
    usedCode?:              CodeableConcept[];
    usedReference?:         Reference[];
}

export interface ProcedureFocalDeviceArray {
    action?:            CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    manipulated:        Reference;
    modifierExtension?: Extension[];
}

export interface ProcedurePerformed {
    Age?:               Age;
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
    Range?:             Range;
    string?:            string;
}

export interface ProcedurePerformerArray {
    actor:              Reference;
    extension?:         Extension[];
    function?:          CodeableConcept;
    id?:                string;
    modifierExtension?: Extension[];
    onBehalfOf?:        Reference;
}

export interface Consent {
    category:           CodeableConcept[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    dateTime?:          string;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    organization?:      Reference[];
    patient?:           Reference;
    performer?:         Reference[];
    policy?:            ConsentPolicyArray[];
    policyRule?:        CodeableConcept;
    provision?:         ConsentProvision;
    resourceType?:      "Consent";
    scope:              CodeableConcept;
    source?:            ConsentSource;
    status:             string;
    text?:              Narrative;
    verification?:      ConsentVerificationArray[];
}

export const isConsent = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is Consent =>
    isResourceObject(resource) && resource.resourceType ==="Consent"

export interface ConsentPolicyArray {
    authority?:         string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    uri?:               string;
}

export interface ConsentProvision {
    action?:            CodeableConcept[];
    actor?:             ConsentProvisionActorArray[];
    class?:             Coding[];
    code?:              CodeableConcept[];
    data?:              ConsentProvisionDataArray[];
    dataPeriod?:        Period;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    period?:            Period;
    provision?:         ConsentProvision[];
    purpose?:           Coding[];
    securityLabel?:     Coding[];
    type?:              string;
}

export interface ConsentProvisionActorArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    reference:          Reference;
    role:               CodeableConcept;
}

export interface ConsentProvisionDataArray {
    extension?:         Extension[];
    id?:                string;
    meaning:            string;
    modifierExtension?: Extension[];
    reference:          Reference;
}

export interface ConsentSource {
    Attachment?:        Attachment;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface ConsentVerificationArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    verificationDate?:  string;
    verified:           boolean;
    verifiedWith?:      Reference;
}

export interface ObservationDefinition {
    abnormalCodedValueSet?:  Reference;
    category?:               CodeableConcept[];
    code:                    CodeableConcept;
    contained?:              Array<any[] | boolean | number | number | null | ResourceObject | string>;
    criticalCodedValueSet?:  Reference;
    extension?:              Extension[];
    id?:                     string;
    identifier?:             Identifier[];
    implicitRules?:          string;
    language?:               string;
    meta?:                   Meta;
    method?:                 CodeableConcept;
    modifierExtension?:      Extension[];
    multipleResultsAllowed?: boolean;
    normalCodedValueSet?:    Reference;
    permittedDataType?:      string[];
    preferredReportName?:    string;
    qualifiedInterval?:      ObservationDefinitionQualifiedIntervalArray[];
    quantitativeDetails?:    ObservationDefinitionQuantitativeDetails;
    resourceType?:           "ObservationDefinition";
    text?:                   Narrative;
    validCodedValueSet?:     Reference;
}

export const isObservationDefinition = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is ObservationDefinition =>
    isResourceObject(resource) && resource.resourceType ==="ObservationDefinition"

export interface ObservationDefinitionQualifiedIntervalArray {
    age?:               Range;
    appliesTo?:         CodeableConcept[];
    category?:          string;
    condition?:         string;
    context?:           CodeableConcept;
    extension?:         Extension[];
    gender?:            string;
    gestationalAge?:    Range;
    id?:                string;
    modifierExtension?: Extension[];
    range?:             Range;
}

export interface ObservationDefinitionQuantitativeDetails {
    conversionFactor?:  number;
    customaryUnit?:     CodeableConcept;
    decimalPrecision?:  number;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    unit?:              CodeableConcept;
}

export interface Attribute {
    description?:       string;
    enum?:              string[];
    extension?:         Extension[];
    extensionUrl?:      string;
    id?:                string;
    isCollection?:      boolean;
    isModifier?:        boolean;
    isOpen?:            boolean;
    isRequired?:        boolean;
    isSummary?:         boolean;
    isUnique?:          boolean;
    meta?:              Meta;
    modifierExtension?: Extension[];
    module?:            string;
    order?:             number;
    path:               string[];
    refers?:            string[];
    resource:           Reference;
    resourceType?:      "Attribute";
    schema?:            AttributeSchema;
    text?:              string;
    type?:              Reference;
    union?:             Reference[];
    valueSet?:          AttributeValueSet;
}

export interface AttributeSchema {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface AttributeValueSet {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    uri?:               string;
}

export interface Location {
    address?:                Address;
    alias?:                  string[];
    availabilityExceptions?: string;
    contained?:              Array<any[] | boolean | number | number | null | ResourceObject | string>;
    description?:            string;
    endpoint?:               Reference[];
    extension?:              Extension[];
    hoursOfOperation?:       LocationHoursOfOperationArray[];
    id?:                     string;
    identifier?:             Identifier[];
    implicitRules?:          string;
    language?:               string;
    managingOrganization?:   Reference;
    meta?:                   Meta;
    mode?:                   string;
    modifierExtension?:      Extension[];
    name?:                   string;
    operationalStatus?:      Coding;
    partOf?:                 Reference;
    physicalType?:           CodeableConcept;
    position?:               LocationPosition;
    resourceType?:           "Location";
    status?:                 string;
    telecom?:                ContactPoint[];
    text?:                   Narrative;
    timezone?:               CodeableConcept;
    type?:                   CodeableConcept[];
}

export const isLocation = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is Location =>
    isResourceObject(resource) && resource.resourceType ==="Location"

export interface LocationHoursOfOperationArray {
    allDay?:            boolean;
    closingTime?:       string;
    daysOfWeek?:        string[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    openingTime?:       string;
}

export interface LocationPosition {
    altitude?:          number;
    extension?:         Extension[];
    id?:                string;
    latitude:           number;
    longitude:          number;
    modifierExtension?: Extension[];
}

export interface Organization {
    active?:            boolean;
    address?:           Address[];
    alias?:             string[];
    contact?:           OrganizationContactArray[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    endpoint?:          Reference[];
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    language?:          string;
    logo?:              Attachment[];
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    partOf?:            Reference;
    resourceType?:      "Organization";
    telecom?:           ContactPoint[];
    text?:              Narrative;
    type?:              CodeableConcept[];
}

export const isOrganization = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is Organization =>
    isResourceObject(resource) && resource.resourceType === "Organization"

export interface OrganizationContactArray {
    address?:           Address;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name?:              HumanName;
    purpose?:           CodeableConcept;
    telecom?:           ContactPoint[];
}

export interface Device {
    contact?:            ContactPoint[];
    contained?:          Array<any[] | boolean | number | number | null | ResourceObject | string>;
    definition?:         Reference;
    deviceName?:         DeviceDeviceNameArray[];
    distinctIdentifier?: string;
    expirationDate?:     string;
    extension?:          Extension[];
    id?:                 string;
    identifier?:         Identifier[];
    implicitRules?:      string;
    language?:           string;
    location?:           Reference;
    lotNumber?:          string;
    manufactureDate?:    string;
    manufacturer?:       string;
    meta?:               Meta;
    modelNumber?:        string;
    modifierExtension?:  Extension[];
    note?:               Annotation[];
    owner?:              Reference;
    parent?:             Reference;
    partNumber?:         string;
    patient?:            Reference;
    property?:           DevicePropertyArray[];
    resourceType?:       "Device";
    safety?:             CodeableConcept[];
    serialNumber?:       string;
    specialization?:     DeviceSpecializationArray[];
    status?:             string;
    statusReason?:       CodeableConcept[];
    text?:               Narrative;
    type?:               CodeableConcept;
    udiCarrier?:         DeviceUdiCarrierArray[];
    url?:                string;
    version?:            DeviceVersionArray[];
}

export interface DeviceDeviceNameArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name:               string;
    type:               string;
}

export interface DevicePropertyArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    type:               CodeableConcept;
    valueCode?:         CodeableConcept[];
    valueQuantity?:     Quantity[];
}

export interface DeviceSpecializationArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    systemType:         CodeableConcept;
    version?:           string;
}

export interface DeviceUdiCarrierArray {
    carrierAIDC?:       string;
    carrierHRF?:        string;
    deviceIdentifier?:  string;
    entryType?:         string;
    extension?:         Extension[];
    id?:                string;
    issuer?:            string;
    jurisdiction?:      string;
    modifierExtension?: Extension[];
}

export interface DeviceVersionArray {
    component?:         Identifier;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    type?:              CodeableConcept;
    value:              string;
}

export interface Session {
    access_token?:       string;
    active?:             boolean;
    audience?:           string;
    authorization_code?: string;
    client?:             Reference;
    ctx?:                SessionCtx;
    end?:                string;
    extension?:          Extension[];
    id?:                 string;
    meta?:               Meta;
    modifierExtension?:  Extension[];
    parent?:             Reference;
    refresh_token?:      string;
    resourceType?:       "Session";
    start?:              string;
    type?:               string;
    user?:               Reference;
}

export interface SessionCtx {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface App {
    apiVersion:         number;
    endpoint?:          AppEndpoint;
    entities?:          { [key: string]: AppEntity };
    extension?:         Extension[];
    hooks?:             { [key: string]: any };
    id?:                string;
    meta?:              Meta;
    migrations?:        AppMigrationsArray[];
    modifierExtension?: Extension[];
    operations?:        { [key: string]: AppOperation };
    resourceType?:      "App";
    subscriptions?:     { [key: string]: AppSubscription };
    type:               AppType;
}

export interface AppEndpoint {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    secret?:            string;
    type?:              EndpointType;
    url?:               string;
}

export enum EndpointType {
    HTTPRPC = "http-rpc",
    Native = "native",
    WsRPC = "ws-rpc",
}

export interface AppEntity {
    attrs?:             { [key: string]: Attr };
    extension?:         Extension[];
    modifierExtension?: Extension[];
    search?:            { [key: string]: SearchValue };
}

export interface Attr {
    attrs?:             { [key: string]: AidBo };
    enum?:              string[];
    extension?:         Extension[];
    extensionUrl?:      string;
    isCollection?:      boolean;
    isOpen?:            boolean;
    isRequired?:        boolean;
    modifierExtension?: Extension[];
    refers?:            string[];
    search?:            FluffySearch;
    type?:              string;
    value?:             FluffyValue;
}

export interface AidBo {
    attrs?:             { [key: string]: AidBo };
    enum?:              string[];
    extension?:         Extension[];
    extensionUrl?:      string;
    isCollection?:      boolean;
    isOpen?:            boolean;
    isRequired?:        boolean;
    modifierExtension?: Extension[];
    refers?:            string[];
    search?:            PurpleSearch;
    type?:              string;
    value?:             PurpleValue;
}

export interface PurpleSearch {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    name?:              string;
    type?:              SearchType;
}

export enum SearchType {
    Date = "date",
    Datetime = "datetime",
    Number = "number",
    Quantity = "quantity",
    Reference = "reference",
    String = "string",
    Token = "token",
}

export interface PurpleValue {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface FluffySearch {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    name?:              string;
    type?:              SearchType;
}

export interface FluffyValue {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface SearchValue {
    expression?:        any[];
    extension?:         Extension[];
    modifierExtension?: Extension[];
    type?:              SearchType;
}

export interface AppMigrationsArray {
    action:   string;
    dateTime: string;
    id:       string;
}

export interface AppOperation {
    action?:            string;
    extension?:         Extension[];
    method?:            Method;
    modifierExtension?: Extension[];
    path?:              Array<PathObject | string>;
    policies?:          { [key: string]: AccessPolicy };
}

export enum Method {
    Delete = "DELETE",
    Get = "GET",
    MethodDelete = "delete",
    MethodGet = "get",
    MethodOption = "option",
    MethodPatch = "patch",
    MethodPost = "post",
    MethodPut = "put",
    Option = "OPTION",
    Patch = "PATCH",
    Post = "POST",
    Put = "PUT",
}

export interface PathObject {
    name: any;
}

export interface AccessPolicy {
    and?:               AccessPolicyAndArray[];
    description?:       string;
    engine?:            Engine;
    extension?:         Extension[];
    id?:                string;
    link?:              Reference[];
    meta?:              Meta;
    modifierExtension?: Extension[];
    module?:            string;
    or?:                AccessPolicyOrArray[];
    resourceType?:      "AccessPolicy";
    schema?:            AccessPolicySchema;
    source?:            string;
    sql?:               AccessPolicySQL;
    type?:              AccessPolicyType;
}

export interface AccessPolicyAndArray {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export enum Engine {
    Allow = "allow",
    Complex = "complex",
    JSONSchema = "json-schema",
    SQL = "sql",
}

export interface AccessPolicyOrArray {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface AccessPolicySchema {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export interface AccessPolicySQL {
    extension?:         Extension[];
    modifierExtension?: Extension[];
    query?:             string;
}

export enum AccessPolicyType {
    Scope = "scope",
}

export interface AppSubscription {
    extension?:         Extension[];
    handler?:           string;
    modifierExtension?: Extension[];
}

export enum AppType {
    Addon = "addon",
    App = "app",
}

export interface SupplyRequest {
    authoredOn?:        string;
    category?:          CodeableConcept;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    deliverFrom?:       Reference;
    deliverTo?:         Reference;
    extension?:         Extension[];
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    item?:              SupplyRequestItem;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    occurrence?:        SupplyRequestOccurrence;
    parameter?:         SupplyRequestParameterArray[];
    priority?:          string;
    quantity:           Quantity;
    reasonCode?:        CodeableConcept[];
    reasonReference?:   Reference[];
    requester?:         Reference;
    resourceType?:      "SupplyRequest";
    status?:            string;
    supplier?:          Reference[];
    text?:              Narrative;
}

export interface SupplyRequestItem {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface SupplyRequestOccurrence {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
    Timing?:            Timing;
}

export interface SupplyRequestParameterArray {
    code?:              CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    value?:             SupplyRequestParameterArrayValue;
}

export interface SupplyRequestParameterArrayValue {
    boolean?:           boolean;
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Quantity?:          Quantity;
    Range?:             Range;
}

export interface AllergyIntolerance {
    asserter?:           Reference;
    category?:           string[];
    clinicalStatus?:     CodeableConcept;
    code?:               CodeableConcept;
    contained?:          Array<any[] | boolean | number | number | null | ResourceObject | string>;
    criticality?:        string;
    encounter?:          Reference;
    extension?:          Extension[];
    id?:                 string;
    identifier?:         Identifier[];
    implicitRules?:      string;
    language?:           string;
    lastOccurrence?:     string;
    meta?:               Meta;
    modifierExtension?:  Extension[];
    note?:               Annotation[];
    onset?:              AllergyIntoleranceOnset;
    patient:             Reference;
    reaction?:           AllergyIntoleranceReactionArray[];
    recordedDate?:       string;
    recorder?:           Reference;
    resourceType?:       "AllergyIntolerance";
    text?:               Narrative;
    type?:               string;
    verificationStatus?: CodeableConcept;
}

export const isAllergyIntolerance = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is AllergyIntolerance =>
    isResourceObject(resource) && resource.resourceType ==="AllergyIntolerance"

export interface AllergyIntoleranceOnset {
    Age?:               Age;
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
    Range?:             Range;
    string?:            string;
}

export interface AllergyIntoleranceReactionArray {
    description?:       string;
    exposureRoute?:     CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    manifestation:      CodeableConcept[];
    modifierExtension?: Extension[];
    note?:              Annotation[];
    onset?:             string;
    severity?:          string;
    substance?:         CodeableConcept;
}

export interface Setting {
    accessPolicy?:      CodeableConcept;
    category?:          CodeableConcept;
    code:               Coding;
    date?:              string;
    definition?:        Reference;
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    item?:              SettingItemArray[];
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    owner:              Reference;
    resourceType?:      "Setting";
    scope?:             CodeableConcept;
    status:             string;
}

export const isSetting = (resource?: any[] | boolean | number | number | null | ResourceObject | string): resource is Setting =>
    isResourceObject(resource) && resource.resourceType ==="Setting"

export interface SettingItemArray {
    code?:              Coding;
    description?:       string;
    extension?:         Extension[];
    label?:             string;
    modifierExtension?: Extension[];
    required?:          boolean;
    secure?:            boolean;
    value?:             SettingItemArrayValue;
}

export interface SettingItemArrayValue {
    Attachment?:        Attachment;
    boolean?:           boolean;
    Coding?:            Coding;
    decimal?:           number;
    extension?:         Extension[];
    integer?:           number;
    modifierExtension?: Extension[];
    Reference?:         Reference;
    string?:            string;
    time?:              string;
    uri?:               string;
}

export interface SearchParameter {
    expression:         Array<Array<Array<{ [key: string]: any } | string> | boolean | number | { [key: string]: any } | null | string>>;
    extension?:         Extension[];
    id?:                string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    module?:            string;
    name:               string;
    resource:           Reference;
    resourceType?:      "SearchParameter";
    type:               SearchParameterType;
}

export enum SearchParameterType {
    Composite = "composite",
    Date = "date",
    Number = "number",
    Quantity = "quantity",
    Reference = "reference",
    String = "string",
    Token = "token",
    URI = "uri",
}

export interface TokenIntrospector {
    extension?:              Extension[];
    id?:                     string;
    introspection_endpoint?: TokenIntrospectorIntrospectionEndpoint;
    jwks_uri?:               string;
    jwt?:                    TokenIntrospectorJwt;
    meta?:                   Meta;
    modifierExtension?:      Extension[];
    resourceType?:           "TokenIntrospector";
    type:                    TokenIntrospectorType;
}

export interface TokenIntrospectorIntrospectionEndpoint {
    authorization?:     string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    url?:               string;
}

export interface TokenIntrospectorJwt {
    extension?:         Extension[];
    iss?:               string;
    modifierExtension?: Extension[];
    secret?:            string;
}

export enum TokenIntrospectorType {
    Jwt = "jwt",
    Opaque = "opaque",
}

export interface ResearchDefinition {
    approvalDate?:        string;
    author?:              ContactDetail[];
    comment?:             string[];
    contact?:             ContactDetail[];
    contained?:           Array<any[] | boolean | number | number | null | ResourceObject | string>;
    copyright?:           string;
    date?:                string;
    description?:         string;
    editor?:              ContactDetail[];
    effectivePeriod?:     Period;
    endorser?:            ContactDetail[];
    experimental?:        boolean;
    exposure?:            Reference;
    exposureAlternative?: Reference;
    extension?:           Extension[];
    id?:                  string;
    identifier?:          Identifier[];
    implicitRules?:       string;
    jurisdiction?:        CodeableConcept[];
    language?:            string;
    lastReviewDate?:      string;
    library?:             string[];
    meta?:                Meta;
    modifierExtension?:   Extension[];
    name?:                string;
    outcome?:             Reference;
    population:           Reference;
    publisher?:           string;
    purpose?:             string;
    relatedArtifact?:     RelatedArtifact[];
    resourceType?:        "ResearchDefinition";
    reviewer?:            ContactDetail[];
    shortTitle?:          string;
    status:               string;
    subject?:             ResearchDefinitionSubject;
    subtitle?:            string;
    text?:                Narrative;
    title?:               string;
    topic?:               CodeableConcept[];
    url?:                 string;
    usage?:               string;
    useContext?:          UsageContext[];
    version?:             string;
}

export interface ResearchDefinitionSubject {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface OperationDefinition {
    affectsState?:      boolean;
    base?:              string;
    code:               string;
    comment?:           string;
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    date?:              string;
    description?:       string;
    experimental?:      boolean;
    extension?:         Extension[];
    id?:                string;
    implicitRules?:     string;
    inputProfile?:      string;
    instance:           boolean;
    jurisdiction?:      CodeableConcept[];
    kind:               string;
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name:               string;
    outputProfile?:     string;
    overload?:          OperationDefinitionOverloadArray[];
    parameter?:         OperationDefinitionParameterArrayArray[];
    publisher?:         string;
    purpose?:           string;
    resource?:          string[];
    resourceType?:      "OperationDefinition";
    status:             string;
    system:             boolean;
    text?:              Narrative;
    title?:             string;
    type:               boolean;
    url?:               string;
    useContext?:        UsageContext[];
    version?:           string;
}

export interface OperationDefinitionOverloadArray {
    comment?:           string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    parameterName?:     string[];
}

export interface OperationDefinitionParameterArrayArray {
    binding?:           OperationDefinitionParameterArrayBinding;
    documentation?:     string;
    extension?:         Extension[];
    id?:                string;
    max:                string;
    min:                number;
    modifierExtension?: Extension[];
    name:               string;
    part?:              OperationDefinitionParameterArrayArray[];
    referencedFrom?:    OperationDefinitionParameterArrayReferencedFromArray[];
    searchType?:        string;
    targetProfile?:     string[];
    type?:              string;
    use:                string;
}

export interface OperationDefinitionParameterArrayBinding {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    strength:           string;
    valueSet:           string;
}

export interface OperationDefinitionParameterArrayReferencedFromArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    source:             string;
    sourceId?:          string;
}

export interface MedicinalProductManufactured {
    contained?:               Array<any[] | boolean | number | number | null | ResourceObject | string>;
    extension?:               Extension[];
    id?:                      string;
    implicitRules?:           string;
    ingredient?:              Reference[];
    language?:                string;
    manufacturedDoseForm:     CodeableConcept;
    manufacturer?:            Reference[];
    meta?:                    Meta;
    modifierExtension?:       Extension[];
    otherCharacteristics?:    CodeableConcept[];
    physicalCharacteristics?: ProdCharacteristic;
    quantity:                 Quantity;
    resourceType?:            "MedicinalProductManufactured";
    text?:                    Narrative;
    unitOfPresentation?:      CodeableConcept;
}

export interface ImagingStudy {
    basedOn?:            Reference[];
    contained?:          Array<any[] | boolean | number | number | null | ResourceObject | string>;
    description?:        string;
    encounter?:          Reference;
    endpoint?:           Reference[];
    extension?:          Extension[];
    id?:                 string;
    identifier?:         Identifier[];
    implicitRules?:      string;
    interpreter?:        Reference[];
    language?:           string;
    location?:           Reference;
    meta?:               Meta;
    modality?:           Coding[];
    modifierExtension?:  Extension[];
    note?:               Annotation[];
    numberOfInstances?:  number;
    numberOfSeries?:     number;
    procedureCode?:      CodeableConcept[];
    procedureReference?: Reference;
    reasonCode?:         CodeableConcept[];
    reasonReference?:    Reference[];
    referrer?:           Reference;
    resourceType?:       "ImagingStudy";
    series?:             ImagingStudySeriesArray[];
    started?:            string;
    status:              string;
    subject:             Reference;
    text?:               Narrative;
}

export interface ImagingStudySeriesArray {
    bodySite?:          Coding;
    description?:       string;
    endpoint?:          Reference[];
    extension?:         Extension[];
    id?:                string;
    instance?:          ImagingStudySeriesArrayInstanceArray[];
    laterality?:        Coding;
    modality:           Coding;
    modifierExtension?: Extension[];
    number?:            number;
    numberOfInstances?: number;
    performer?:         ImagingStudySeriesArrayPerformerArray[];
    specimen?:          Reference[];
    started?:           string;
    uid:                string;
}

export interface ImagingStudySeriesArrayInstanceArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    number?:            number;
    sopClass:           Coding;
    title?:             string;
    uid:                string;
}

export interface ImagingStudySeriesArrayPerformerArray {
    actor:              Reference;
    extension?:         Extension[];
    function?:          CodeableConcept;
    id?:                string;
    modifierExtension?: Extension[];
}

export interface CoverageEligibilityRequest {
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    created:            string;
    enterer?:           Reference;
    extension?:         Extension[];
    facility?:          Reference;
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    insurance?:         CoverageEligibilityRequestInsuranceArray[];
    insurer:            Reference;
    item?:              CoverageEligibilityRequestItemArray[];
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    patient:            Reference;
    priority?:          CodeableConcept;
    provider?:          Reference;
    purpose:            string[];
    resourceType?:      "CoverageEligibilityRequest";
    serviced?:          CoverageEligibilityRequestServiced;
    status:             string;
    supportingInfo?:    CoverageEligibilityRequestSupportingInfoArray[];
    text?:              Narrative;
}

export interface CoverageEligibilityRequestInsuranceArray {
    businessArrangement?: string;
    coverage:             Reference;
    extension?:           Extension[];
    focal?:               boolean;
    id?:                  string;
    modifierExtension?:   Extension[];
}

export interface CoverageEligibilityRequestItemArray {
    category?:               CodeableConcept;
    detail?:                 Reference[];
    diagnosis?:              CoverageEligibilityRequestItemArrayDiagnosisArray[];
    extension?:              Extension[];
    facility?:               Reference;
    id?:                     string;
    modifier?:               CodeableConcept[];
    modifierExtension?:      Extension[];
    productOrService?:       CodeableConcept;
    provider?:               Reference;
    quantity?:               Quantity;
    supportingInfoSequence?: number[];
    unitPrice?:              Money;
}

export interface CoverageEligibilityRequestItemArrayDiagnosisArray {
    diagnosis?:         CoverageEligibilityRequestItemArrayDiagnosisArrayDiagnosis;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
}

export interface CoverageEligibilityRequestItemArrayDiagnosisArrayDiagnosis {
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Reference?:         Reference;
}

export interface CoverageEligibilityRequestServiced {
    date?:              string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface CoverageEligibilityRequestSupportingInfoArray {
    appliesToAll?:      boolean;
    extension?:         Extension[];
    id?:                string;
    information:        Reference;
    modifierExtension?: Extension[];
    sequence:           number;
}

export interface MedicinalProductIngredient {
    allergenicIndicator?: boolean;
    contained?:           Array<any[] | boolean | number | number | null | ResourceObject | string>;
    extension?:           Extension[];
    id?:                  string;
    identifier?:          Identifier;
    implicitRules?:       string;
    language?:            string;
    manufacturer?:        Reference[];
    meta?:                Meta;
    modifierExtension?:   Extension[];
    resourceType?:        "MedicinalProductIngredient";
    role:                 CodeableConcept;
    specifiedSubstance?:  MedicinalProductIngredientSpecifiedSubstanceArray[];
    substance?:           MedicinalProductIngredientSubstance;
    text?:                Narrative;
}

export interface MedicinalProductIngredientSpecifiedSubstanceArray {
    code:               CodeableConcept;
    confidentiality?:   CodeableConcept;
    extension?:         Extension[];
    group:              CodeableConcept;
    id?:                string;
    modifierExtension?: Extension[];
    strength?:          MedicinalProductIngredientSStrengthArray[];
}

export interface MedicinalProductIngredientSStrengthArray {
    concentration?:         Ratio;
    concentrationLowLimit?: Ratio;
    country?:               CodeableConcept[];
    extension?:             Extension[];
    id?:                    string;
    measurementPoint?:      string;
    modifierExtension?:     Extension[];
    presentation:           Ratio;
    presentationLowLimit?:  Ratio;
    referenceStrength?:     MedicinalProductIngredientSpecifiedSubstanceArrayStrengthArrayReferenceStrengthArray[];
}

export interface MedicinalProductIngredientSpecifiedSubstanceArrayStrengthArrayReferenceStrengthArray {
    country?:           CodeableConcept[];
    extension?:         Extension[];
    id?:                string;
    measurementPoint?:  string;
    modifierExtension?: Extension[];
    strength:           Ratio;
    strengthLowLimit?:  Ratio;
    substance?:         CodeableConcept;
}

export interface MedicinalProductIngredientSubstance {
    code:               CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    strength?:          MedicinalProductIngredientSStrengthArray[];
}

export interface GuidanceResponse {
    contained?:          Array<any[] | boolean | number | number | null | ResourceObject | string>;
    dataRequirement?:    DataRequirement[];
    encounter?:          Reference;
    evaluationMessage?:  Reference[];
    extension?:          Extension[];
    id?:                 string;
    identifier?:         Identifier[];
    implicitRules?:      string;
    language?:           string;
    meta?:               Meta;
    modifierExtension?:  Extension[];
    module?:             GuidanceResponseModule;
    note?:               Annotation[];
    occurrenceDateTime?: string;
    outputParameters?:   Reference;
    performer?:          Reference;
    reasonCode?:         CodeableConcept[];
    reasonReference?:    Reference[];
    requestIdentifier?:  Identifier;
    resourceType?:       "GuidanceResponse";
    result?:             Reference;
    status:              string;
    subject?:            Reference;
    text?:               Narrative;
}

export interface GuidanceResponseModule {
    canonical?:         string;
    CodeableConcept?:   CodeableConcept;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    uri?:               string;
}

export interface Media {
    basedOn?:           Reference[];
    bodySite?:          CodeableConcept;
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    content:            Attachment;
    created?:           MediaCreated;
    device?:            Reference;
    deviceName?:        string;
    duration?:          number;
    encounter?:         Reference;
    extension?:         Extension[];
    frames?:            number;
    height?:            number;
    id?:                string;
    identifier?:        Identifier[];
    implicitRules?:     string;
    issued?:            Date;
    language?:          string;
    meta?:              Meta;
    modality?:          CodeableConcept;
    modifierExtension?: Extension[];
    note?:              Annotation[];
    operator?:          Reference;
    partOf?:            Reference[];
    reasonCode?:        CodeableConcept[];
    resourceType?:      "Media";
    status:             string;
    subject?:           Reference;
    text?:              Narrative;
    type?:              CodeableConcept;
    view?:              CodeableConcept;
    width?:             number;
}

export interface MediaCreated {
    dateTime?:          string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    Period?:            Period;
}

export interface SearchQuery {
    as?:                string;
    extension?:         Extension[];
    id?:                string;
    includes?:          { [key: string]: SearchQueryInclude };
    limit?:             number;
    meta?:              Meta;
    modifierExtension?: Extension[];
    params?:            { [key: string]: SearchQueryParam };
    query?:             SearchQueryQuery;
    resource:           Reference;
    resourceType?:      "SearchQuery";
    total?:             boolean;
}

export interface SearchQueryInclude {
    extension?:         Extension[];
    includes?:          { [key: string]: SearchQueryInclude };
    modifierExtension?: Extension[];
    path?:              Array<Array<{ [key: string]: any } | string> | boolean | number | { [key: string]: any } | null | string>;
    resource?:          Reference;
}

export interface SearchQueryParam {
    /**
     * Anything
     */
    default?:           any;
    extension?:         Extension[];
    format?:            string;
    isRequired?:        boolean;
    join?:              { [key: string]: Join };
    modifierExtension?: Extension[];
    "order-by"?:        string;
    type?:              FluffyType;
    where?:             string;
}

export interface Join {
    by:                 string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    table:              string;
}

export enum FluffyType {
    Boolean = "boolean",
    Date = "date",
    DateTime = "dateTime",
    Integer = "integer",
    Number = "number",
    Object = "object",
    String = "string",
}

export interface SearchQueryQuery {
    extension?:         Extension[];
    join?:              { [key: string]: SearchQueryQueryJoin };
    modifierExtension?: Extension[];
    "order-by"?:        string;
    where?:             string;
}

export interface SearchQueryQueryJoin {
    by:                 string;
    extension?:         Extension[];
    modifierExtension?: Extension[];
    table:              string;
}

export interface MeasureReport {
    contained?:           Array<any[] | boolean | number | number | null | ResourceObject | string>;
    date?:                string;
    evaluatedResource?:   Reference[];
    extension?:           Extension[];
    group?:               MeasureReportGroupArray[];
    id?:                  string;
    identifier?:          Identifier[];
    implicitRules?:       string;
    improvementNotation?: CodeableConcept;
    language?:            string;
    measure:              string;
    meta?:                Meta;
    modifierExtension?:   Extension[];
    period:               Period;
    reporter?:            Reference;
    resourceType?:        "MeasureReport";
    status:               string;
    subject?:             Reference;
    text?:                Narrative;
    type:                 string;
}

export interface MeasureReportGroupArray {
    code?:              CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    measureScore?:      Quantity;
    modifierExtension?: Extension[];
    population?:        MeasureReportGroupArrayPopulationArray[];
    stratifier?:        MeasureReportGroupArrayStratifierArray[];
}

export interface MeasureReportGroupArrayPopulationArray {
    code?:              CodeableConcept;
    count?:             number;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    subjectResults?:    Reference;
}

export interface MeasureReportGroupArrayStratifierArray {
    code?:              CodeableConcept[];
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    stratum?:           MeasureReportGroupArrayStratifierArrayStratumArray[];
}

export interface MeasureReportGroupArrayStratifierArrayStratumArray {
    component?:         MeasureReportGroupArrayStratifierArrayStratumArrayComponentArray[];
    extension?:         Extension[];
    id?:                string;
    measureScore?:      Quantity;
    modifierExtension?: Extension[];
    population?:        MeasureReportGroupArrayStratifierArrayStratumArrayPopulationArray[];
    value?:             CodeableConcept;
}

export interface MeasureReportGroupArrayStratifierArrayStratumArrayComponentArray {
    code:               CodeableConcept;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    value:              CodeableConcept;
}

export interface MeasureReportGroupArrayStratifierArrayStratumArrayPopulationArray {
    code?:              CodeableConcept;
    count?:             number;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    subjectResults?:    Reference;
}

export interface GraphDefinition {
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    date?:              string;
    description?:       string;
    experimental?:      boolean;
    extension?:         Extension[];
    id?:                string;
    implicitRules?:     string;
    jurisdiction?:      CodeableConcept[];
    language?:          string;
    link?:              GraphDefinitionLinkArrayLinkArray[];
    meta?:              Meta;
    modifierExtension?: Extension[];
    name:               string;
    profile?:           string;
    publisher?:         string;
    purpose?:           string;
    resourceType?:      "GraphDefinition";
    start:              string;
    status:             string;
    text?:              Narrative;
    url?:               string;
    useContext?:        UsageContext[];
    version?:           string;
}

export interface GraphDefinitionLinkArrayTargetArray {
    compartment?:       GraphDefinitionLinkArrayTargetArrayCompartmentArray[];
    extension?:         Extension[];
    id?:                string;
    link?:              GraphDefinitionLinkArrayLinkArray[];
    modifierExtension?: Extension[];
    params?:            string;
    profile?:           string;
    type:               string;
}

export interface GraphDefinitionLinkArrayLinkArray {
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    max?:               string;
    min?:               number;
    modifierExtension?: Extension[];
    path?:              string;
    sliceName?:         string;
    target?:            GraphDefinitionLinkArrayTargetArray[];
}

export interface GraphDefinitionLinkArrayTargetArrayCompartmentArray {
    code:               string;
    description?:       string;
    expression?:        string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    rule:               string;
    use:                string;
}

export interface Entity {
    description?:       string;
    extension?:         Extension[];
    id?:                string;
    isMeta?:            boolean;
    isOpen?:            boolean;
    meta?:              Meta;
    modifierExtension?: Extension[];
    module?:            string;
    nonPersistable?:    boolean;
    resourceType?:      "Entity";
    schema?:            EntitySchema;
    text?:              string;
    type:               EntityType;
}

export interface EntitySchema {
    extension?:         Extension[];
    modifierExtension?: Extension[];
}

export enum EntityType {
    Abstract = "abstract",
    Primitive = "primitive",
    Resource = "resource",
    Type = "type",
}

export interface TerminologyCapabilities {
    closure?:           TerminologyCapabilitiesClosure;
    codeSearch?:        string;
    codeSystem?:        TerminologyCapabilitiesCodeSystemArray[];
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    copyright?:         string;
    date:               string;
    description?:       string;
    expansion?:         TerminologyCapabilitiesExpansion;
    experimental?:      boolean;
    extension?:         Extension[];
    id?:                string;
    implementation?:    TerminologyCapabilitiesImplementation;
    implicitRules?:     string;
    jurisdiction?:      CodeableConcept[];
    kind:               string;
    language?:          string;
    lockedDate?:        boolean;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    publisher?:         string;
    purpose?:           string;
    software?:          TerminologyCapabilitiesSoftware;
    status:             string;
    text?:              Narrative;
    title?:             string;
    translation?:       TerminologyCapabilitiesTranslation;
    url?:               string;
    useContext?:        UsageContext[];
    validateCode?:      TerminologyCapabilitiesValidateCode;
    version?:           string;
}

export interface TerminologyCapabilitiesClosure {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    translation?:       boolean;
}

export interface TerminologyCapabilitiesCodeSystemArray {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    subsumption?:       boolean;
    uri?:               string;
    version?:           TerminologyCapabilitiesCodeSystemArrayVersionArray[];
}

export interface TerminologyCapabilitiesCodeSystemArrayVersionArray {
    code?:              string;
    compositional?:     boolean;
    extension?:         Extension[];
    filter?:            TerminologyCapabilitiesCodeSystemArrayVersionArrayFilterArray[];
    id?:                string;
    isDefault?:         boolean;
    language?:          string[];
    modifierExtension?: Extension[];
    property?:          string[];
}

export interface TerminologyCapabilitiesCodeSystemArrayVersionArrayFilterArray {
    code:               string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    op:                 string[];
}

export interface TerminologyCapabilitiesExpansion {
    extension?:         Extension[];
    hierarchical?:      boolean;
    id?:                string;
    incomplete?:        boolean;
    modifierExtension?: Extension[];
    paging?:            boolean;
    parameter?:         TerminologyCapabilitiesExpansionParameterArray[];
    textFilter?:        string;
}

export interface TerminologyCapabilitiesExpansionParameterArray {
    documentation?:     string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name:               string;
}

export interface TerminologyCapabilitiesImplementation {
    description:        string;
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    url?:               string;
}

export interface TerminologyCapabilitiesSoftware {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    name:               string;
    version?:           string;
}

export interface TerminologyCapabilitiesTranslation {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    needsMap:           boolean;
}

export interface TerminologyCapabilitiesValidateCode {
    extension?:         Extension[];
    id?:                string;
    modifierExtension?: Extension[];
    translations:       boolean;
}

export interface MetadataResource {
    contact?:           ContactDetail[];
    contained?:         Array<any[] | boolean | number | number | null | ResourceObject | string>;
    date?:              string;
    description?:       string;
    experimental?:      boolean;
    extension?:         Extension[];
    id?:                string;
    implicitRules?:     string;
    jurisdiction?:      CodeableConcept[];
    language?:          string;
    meta?:              Meta;
    modifierExtension?: Extension[];
    name?:              string;
    publisher?:         string;
    status:             string;
    text?:              Narrative;
    title?:             string;
    url?:               string;
    useContext?:        UsageContext[];
    version?:           string;
}