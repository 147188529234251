import { FC } from "react"
import { useController, useFormContext } from "react-hook-form"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

import { QuestionAnswer } from "./QuestionAnswer"

const StringQuestion: FC<IProps> = ({ name, type = "string", defaultValue, readOnly }) => {
  const { control } = useFormContext()
  const {
    field: { onChange, value },
  } = useController({ name, control, defaultValue })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (type === "url") {
      onChange(e.target.value)
      return
    }
    onChange(e.target.value)
  }

  if (readOnly) {
    return <QuestionAnswer answer={defaultValue} />
  }

  if (type === "string" || type === "url") {
    return <InputText value={value} onChange={handleChange} />
  }
  return <InputTextarea autoResize rows={5} value={value} onChange={handleChange} />
}

type IProps = {
  name: string
  type?: "string" | "text" | "url"
  defaultValue?: string
  readOnly?: boolean
}

export { StringQuestion }
