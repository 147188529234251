import { FC } from "react"

const NotFound: FC<IProps> = ({ message }) => (
  <div className="h-screen w-full flex flex-col items-center justify-center p-4">
    <i className="bg-error-emoji w-20 h-20 mb-4"></i>
    <h4 className="text-4xl mb-4">
      Not found <small className="text-3xl font-light">Error 404</small>
    </h4>
    <p className="text-xl text-center">{message ?? "We are sorry, but the resource you requested was not found."}</p>
  </div>
)

type IProps = {
  message?: string
}

export { NotFound }
