import { FC } from "react"
import { useController, useFormContext } from "react-hook-form"
import { CalendarChangeParams } from "primereact/calendar"
import { format } from "date-fns"

import { DateTime, useMobile } from "commons"
import { QuestionAnswer } from "./QuestionAnswer"

const DateTimeQuestion: FC<Props> = ({ name, type, defaultValue, readOnly }) => {
  const { isMobile } = useMobile()
  const { control } = useFormContext()
  const {
    field: { value, onChange },
  } = useController({ name, control, defaultValue })
  const dateValue = value?.replaceAll("-", "/")

  const handleChange = (e: CalendarChangeParams) => {
    const date = e.value
    onChange(date && !Array.isArray(date) ? format(date, isoFormat[type]) : undefined)
  }

  if (readOnly) {
    return <QuestionAnswer answer={defaultValue} />
  }

  return isMobile ? (
    <input type={nativeInput[type]} className="input" />
  ) : (
    <DateTime
      value={new Date(dateValue)}
      showTime={type === "dateTime"}
      hourFormat="12"
      timeOnly={type === "time"}
      onChange={handleChange}
      showIcon
      placeholder="Select date"
    />
  )
}

const nativeInput: Record<string, string> = {
  date: "date",
  time: "time",
  dateTime: "datetime-local",
}

const isoFormat: Record<string, string> = {
  date: "yyyy-MM-dd",
  time: "HH:mm:ss",
  dateTime: "yyyy-MM-ddTHH:mm:ss",
}

type Props = {
  name: string
  type: DateType
  defaultValue?: string
  readOnly?: boolean
}

type DateType = "date" | "time" | "dateTime"

export { DateTimeQuestion }
